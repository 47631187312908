<template>
    <component :is="addMealDemandx"></component>
</template>
<script>
import { mapGetters } from "vuex";
import addMealDemand_eventcool from '@/template/addMealDemand/eventcool.vue'
import addMealDemand_novartis from '@/template/addMealDemand/novartis.vue'
import addMealDemand_dscl from '@/template/addMealDemand/Dscl.vue'
import addMealDemand_crossevent from '@/template/addMealDemand/crossevent.vue'
import addMealDemand_crossevent2 from '@/template/addMealDemand/crossevent2.vue'
import addMealDemand_pfizer from '@/template/addMealDemand/pfizer.vue'
import addMealDemand_pfizer2 from '@/template/addMealDemand/crossevent2.vue'
import addMealDemand_employee from '@/template/addMealDemand/employee.vue'
export default {
    components: {
        addMealDemand_eventcool,
        addMealDemand_novartis,
        addMealDemand_dscl,
        addMealDemand_pfizer,
        addMealDemand_crossevent,
        addMealDemand_crossevent2,
        addMealDemand_employee,
        addMealDemand_pfizer2
    },
    computed: {
        ...mapGetters(['event','mealRules']),
        addMealDemandx: {
            get: function() {
                var componentId = this.event.tenant_code || 'eventcool';
                if(this.mealRules.newUI){
                    componentId = 'crossevent2'
                }
                if(this.event.typeDictTxt == '拜访用餐'){
                    componentId = 'employee'
                }
                return ('addMealDemand_' + componentId);  
            }    
        }
    },
}
</script>