 <template>
    <div class="addTakeOutDemand2">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="$router.push('/orderList')">
            <span slot="headText" style="font-size:.17rem;">添加外卖需求</span>
        </Header>
        <div class="addMealDemand-container">
            <AttentionTips :tips="tips" :newUI="true" :allShow="true" />
            <div class="normalInputRow">
                <MInput title="用餐人数" :labelBlack="true" type='tel' pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" :labelBlack="true" type='number' pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="备注" :labelBlack="true" pla="请填写备注" v-model="input.BudgetDescr"></MInput>            
            </div>
            <Btn btnClass="submit_btn" v-if="hideshow" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
import { getCookie } from 'tiny-cookie'
export default {
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
                mealKind: '',
                mealSubType:''
            },
            tips: ["需求信息（*为必填项）;","若订单量较大（大于10份），请提前预订，以便商户备餐及配送安排，保证用餐量和配送时间。" ],
            docmHeight: '0', 
            showHeight: 0, 
            hideshow: true, 
            isResize: false,
            optionalType: [],
            orderTypeList:[
                {txt: '外卖用餐'},
                {txt: '外卖茶歇'},
            ],
            mealTypeList:[
                {txt: '午餐'},
                {txt: '晚餐'},
            ],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    async created(){
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
            if(['会引擎-灵北'].includes(this.event.tenant_name)){
                let itemData = JSON.parse(this.orderDetail.itemData)
                this.input.budgetRemark = itemData.budgetRemark
                this.input.budgetNum = itemData.budgetNum
            }
        }
    },
    async mounted(){
        // this.optionalType = await this.getWaimaiType()
        let platForm = navigator.userAgent.toUpperCase();
        let isAndroid = platForm.indexOf('ANDROID') > -1 || platForm.indexOf('LINUX') > -1;
        if(isAndroid){
            window.addEventListener('resize',() => {
                if (!this.isResize) {
                    this.docmHeight = window.document.documentElement.clientHeight || window.innerHeight
                    this.isResize = true
                }
                //实时屏幕高度
                this.showHeight = window.document.documentElement.clientHeight || window.innerHeight
            })
        }
    },
    watch: {
        showHeight: function() {
            if (this.docmHeight !== this.showHeight) {
                this.hideshow = true
            } else {
                this.hideshow = false
            }
        }
    },
    methods: {
        lbMethod(){
            if(this.orderDetail.itemId){
                return
            }
        },
        submit() {
            let params = {
                ...this.input,
            };
            params.proposalId = this.event.proposalId;
            params.itemId = this.orderDetail.itemId;
            let validateResult = this.validate(params, saveTakeOut);
            if (!validateResult) return;
            params.ItemType = '7';
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.push(
                        `/orderList`
                    );
                }
            });
        },
        // 获取外卖类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: getCookie('tenant_code') || this.event.tenant_code || "crossevent",
                    mealType: "外卖",
                    tenant_id: getCookie('tenant_id')
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || { optionalType: [] }).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
};
</script>
<style lang="scss">
.addTakeOutDemand2 {
    width: 100%;
    
    .btnRow {
        margin: 0.15rem 0.25rem 0 0.25rem;
        position: relative;

        .btnRowTitle {
            text-align: left;
            font-size: 0.15rem;
            color: #13161B;
            span {
                color: #d60012;
                margin-left: 0.04rem;
            }
        }
    }
    .btnRowGroup {
        overflow: hidden;
        .btnRdio {
            height: 0.36rem;
            line-height: 0.36rem;
            float: left;
            width: 25%;
            margin: 0.1rem 0.1rem 0.1rem 0;
            border-radius: 0.33rem;
            font-size: 0.12rem;
        }

        .btnRdioDis {
            background: #f5f5f5 !important;
            color: #13161b !important;
        }
    }
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
        .normalInputRow{
            input{
                font-size: .15rem!important;
            }
            input::placeholder{
                color: #909399!important;
            }
        }
    }
    .submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
    }
}
</style>