<template>
    <Pop ref="pop" class="mapSearch" :clickClose="true">
        <div slot="popContent">
            <div class="map-search-head">
                <div class="map-back-icon" @click="back">
                    <span v-if="device">
                        返回
                    </span>
                    <Svgs v-else color="#000" name="iconfanhui"></Svgs>
                </div>
            </div>
            <searchRow placeholder="小区/写字楼/学校等" v-model="keyword"></searchRow>
            <div class="address-list" v-if="poiList.length">
                <div
                    class="address-item"
                    v-for="(item, index) in poiList"
                    :key="index"
                    @click="choosePoi(item)"
                >
                    <Svgs color="#999" name="iconzhiyuandidian4"></Svgs>
                    {{item.name}}
                    <span>{{item.pname}}{{item.cityname}}{{item.adname}}</span>
                </div>
            </div>
        </div>
    </Pop>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { SmartInvoker } from "@/core";
export default {
    data() {
        return {
            keyword: "",
            poiList: []
        };
    },
    watch: {
        keyword: function() {
            this.search()
        }
    },
    computed: {
        ...mapGetters(["event", "orderDetail"]),
        device(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapMutations(["ADDRESS", "FRESH_SHOP","address"]),
        openPop() {
            this.$refs.pop.changePop();
        },
        back() {
            this.$refs.pop.changePop();
        },
        search() {
            let _this = this;
            /* global AMap */
            AMap.plugin("AMap.PlaceSearch", function() {
                var autoOptions = {
                    citylimit: false,
                    extensions: "all", //all，返回基本+详细信息
                };
                var placeSearch = new AMap.PlaceSearch(autoOptions);
                placeSearch.search(_this.keyword, function(status, result) {
                    _this.poiList = _this.keyword
                        ? (result.poiList || {}).pois
                        : [];
                });
            });
        },
        choosePoi(item) {
            if(this.$route.query.other) {
                let shopData = {
                    isExtra: true,
                    fullName: item.name,
                    sourceCode: "MAP",
                    shopAddress: item.address,
                    province: item.pname,
                    city: item.cityname || item.pname,
                    cityRegion: item.adname
                };
                this["SHOP"](shopData);
                if(this.orderDetail.itemId) {
                    this.$router.push('/orderList');
                } else {
                    this.$router.push('/addMealDemand?shopType=平台到餐');
                }
            } else if(this.event.search) {
                 this.resetAddress(item)
            } else {
                if(['dscl'].includes(this.event.tenant_code) && ['区域会'].includes(this.event.typeDictTxt)) {
                    this.resetAddress(item)
                } else {
                    SmartInvoker.call("iShop-Select-Address-Done", this.event.tenant_code, param => {
                        const eventCity = this.event.cityDictVal;
                         //罗氏
                        if (['roche'].includes(this.event.tenant_code)) {
                            if([1, 2].includes(this.event.itsExtData?.vMettingType)){
                                this.resetAddress(item)
                                return
                            }
                        }
                        //灵北卡控
                        if ((item.cityname || item.pname) != eventCity && this.event.tenantId == 'c3861376-4eb6-11ed-9bb4-22cc57c8ec85' && this.event.typeDictTxt != '拜访用餐') {
                            if("否" == this.event.extData.isNetEvent){
                                this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                            }
														
                            if(!["圆桌会"].includes(this.event.typeDictTxt)){
                                this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                            }

                        }
                        if(param && (item.cityname || item.pname) != eventCity && !['bbs','crossevent'].includes(this.event.tenant_code)) {
                            this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                        }
                        this.resetAddress(item)
                    });
                }
            }
        },
        resetAddress(item) {
            let address = {
                name: item.name,
                latitude: item.location.lat,
                longitude: item.location.lng,
                city: item.cityname || item.pname
            }
            this["ADDRESS"](address)
            this["FRESH_SHOP"](true)
            this.$router.go(-1);
        }
    }
};
</script>
<style lang="scss">
.mapSearch {
    .popContent {
        top: 0;
        background: #fff;

        .map-search-head {
            height: 0.4rem;
            display: flex;
            align-items: center;
            height: 0.45rem;

            .map-back-icon {
                width: 20%;
                text-align: center;
            }

            input {
                width: 2.4rem;
            }
        }

        .address-list {
            padding: 0.36rem 0.25rem 0 0.25rem;

            .address-item {
                padding: 10px;
                border-bottom: 1px solid #eee;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: left;

                span {
                    color: #999;
                }

                span:last-child {
                    padding-left: 5px;
                }
            }
        }
    }
}
</style>