import JiaoBiao from "@/components/OrderList/JiaoBiao.vue";
import { miceService } from "@/service/miceService.js";
import { baseUrl } from '@/service/baseUrl.js'
import mealDdianPing from '@/components/Meal/mealDdianPing.js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
import { setCookie, getCookie } from 'tiny-cookie'
import {
    SmartStorage
} from 'smart-core-util'
import { getAllCookies } from 'tiny-cookie'
import { indicator } from 'smart-indicator'
import ItemPop from "@/components/OrderList/ItemPop.vue";
import { Checkbox, CheckboxGroup, Dialog } from 'vant';
export default {
    name: "Item7Detail2",
    props: ["takeoutList", "batchApproval"],
    mixins: [mealDdianPing],
    components: {
        JiaoBiao,
        ItemPop,
        Checkbox,
        CheckboxGroup,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            msg: '',
            optionalType: null,
            itemList: [],
            result: [],
            topayDialog: false,
            topayResult: []
        }
    },
    computed: {
        ...mapGetters([
            'orderDetail',
            'event',
            'address',
            'memsg',
            'mealRules'
        ])
    },
    async created() {
        this.optionalType = await this.getWaimaiType()
    },
    methods: {
        ...mapMutations([
            'ORDER_DETAIL',
            'FRESH_LIST'
        ]),
        checkAll(flage) {
            this.$refs.checkboxGroup.toggleAll(flage)
        },
        checkChange(e) {
            this.$emit('checkChange', { type: 'item7', list: e })
        },
        computer_status(status) {
            if ([1, 4, 5, -1].includes(status)) {
                return "colorStatus_" + `${JSON.stringify(status)}`
            } else {
                return "colorStatus_default"
            }
        },
        show(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "inline-block";
        },
        // 隐藏 remove-icon
        hide(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "none";
        },
        async handleClick(btn, item) {
            if (btn.forbidden) {
                this.toast(btn.msg);
                return;
            }
            this['ORDER_DETAIL'](item);
            let buttonType = btn.processor;
            switch (buttonType) {
                //编辑
                case "edit":
                    this.$router.push({
                        path: '/addTakeOutDemand',
                        query: {
                            isEdit: true,
                            qty1: item.qty1,
                            amount: item.amount,
                            itemData: item.itemData,
                            itemId: item.itemId,
                            BudgetDescr: item.BudgetDescr || ''
                        }
                    })
                    break;
                //删除    
                case "remove":
                    this.$refs.remove.openPop();
                    break;
                //创建                   
                case "createOrder":
                    if (btn.checkurl) {
                        await this.takeOutCheck(item, btn)
                    }
                    if(['会引擎-huarun'].includes(this.event.tenant_name)){// update
                        this.topayDialog = true
                        return
                    }   
                    this.createOrder(item, btn)
                    break;
                //确认订单    
                case "confirmReceipt":
                    this.confirmReceipt()
                    break;
                //上传发票    
                case "uploadInvoice":
                    this.$router.push({
                        path: '/uploadInvoice'
                    })
                    break;
                //补充文件    
                case "addFile":
                    this.$router.push({
                        path: '/addFile'
                    })
                    break;
                //查看详情
                case "viewDetails":
                    if (this.orderDetail.soucreCode == 'ELE') {
                        let elemeData = {}
                        try {
                            elemeData = JSON.parse(this.orderDetail.itemData)
                        } catch (error) {
                            console.log("解析订单itemData JSON 报错====", this.orderDetail.itemData)
                        }
                        //获取ele地址
                        let eleURL = null
                        if (elemeData.standardId && this.orderDetail.extOrderId) {
                            let params = {
                                "Sid": this.$store.state.meal.mealRules.sid,
                                "StandardId": elemeData.standardId,
                                "OrderNo": this.orderDetail.extOrderId
                            }
                            let cancelELEData = await miceService.common('/rpc/pay/Alipay/GenerateAliTakeoutOrderUrl', params)
                            eleURL = cancelELEData.content
                        }
                        if (this.orderDetail.status != 5 && this.orderDetail.status != -1 && this.orderDetail.status != 0) {
                            if (this.isWx()) {
                                this.$router.push(`/common?type=detailEle&aliURL=${encodeURIComponent(eleURL)}&placeOrder=true&sessionId=${SmartStorage.get("sessionId")}`)
                                return;
                            }
                            this.$router.push(`/common?type=detailEle&aliURL=${encodeURIComponent(eleURL)}`)
                            return
                        } else {
                            this.$router.push({ path: `/takeOutEleDetail?aliURL=${encodeURIComponent(eleURL)}`, query: { sourceCode: item.sourceCode, isELE: true } })
                            return
                        }
                    }
                    if (['DianPing'].includes(item.sourceCode)) {
                        if (item.status == 5 || item.status == 0 || item.status == -1) {
                            this.$router.push({
                                path: '/takeOutEleDetail',
                                isDianPing: true,
                                query: { sourceCode: item.sourceCode, isDianPing: true }
                            })
                            return
                        }
                        let params = {
                            sourceCode: 'DianPing',
                            ItemId: item.itemId
                        }
                        if (SmartStorage.get("isH5") && !this.isWx()) {// 协办会在浏览器新开页面打开
                            this.open = window.open("", "_blank")
                        }
                        await this.refreshDianPing(params)
                        var meituanRes = await this.getDianPingUrl(params)
                        if (meituanRes && meituanRes.success) {
                            if(window.__wxjs_environment === "miniprogram"){
                                this.$refs.browserLoader.openPop(meituanRes.content)
                                return
                            }
                            if (SmartStorage.get("isH5") && this.isWx()) {// 协办会在微信通过跳转出去打开
                                window.open(meituanRes.content, "_self")
                                return
                            }
                            this.openNewWeb(meituanRes.content)
                        }
                        // this.openDdianPing()
                        return
                    }
                    // if (['ELE', 'XiaoMiShu'].includes(item.sourceCode)) {
                    this.$router.push({ path: '/takeOutEleDetail', query: { sourceCode: item.sourceCode, isELE: true } })
                    // } else {
                    // this.$router.push({ path: '/takeoutOrderDetail' })
                    // }
                    break;
                //取消    
                case "cancelOrder":
                    this.$refs.cancel.openPop();
                    break;
                //选择商家    
                case "selectMerchant":
                    if (btn.checkurl) {
                        await this.takeOutCheck(item, btn)
                    }
                    if(['会引擎-huarun'].includes(this.event.tenant_name)){// update
                        this.topayDialog = true
                        return
                    }   
                    // this.openDdianPing()
                    this.createOrder(item, btn)
                    break;
                //更新文件
                case "updateFile":
                    this.$router.push({
                        path: '/updateFile',
                        query: {
                            itemType: 7
                        }
                    })
                    break;
                //饿了么再支付
                case "reToPay":
                    this.reToPay()
            }
        },
        topayDialogConfirm(action, done){
            if(action == 'cancel')done()
            if(this.topayResult.length == 2 && action == 'confirm'){
                done()
                this.createOrder()
            }else{
                done(false)
            }
        },
        async handlechannel(itemType,flage) {
            let that = this
            switch (itemType) {
                case 'ELE':
                    if(this.event.tenant_code != 'roche'){
                        await this.execute(this.orderDetail)
                    }
                    var path = "/alipayAuthorize";
                    this.isWx() ? (path = `/alipayAuthorize?authorize=true&sessionId=${SmartStorage.get("sessionId")}&sid=${this.mealRules.sid}&userId=${this.memsg.userId}&authMobile=${SmartStorage.get("authMobile")}&rnd=${new Date().getTime()}`) : "";
                    miceService.CheckUserAuth({ phoneNumber: SmartStorage.get("authMobile") }).then(async (res) => {
                        if (!res.content.isUserAuth || !res.content.isMemberAuth) {
                            this.$router.push({
                                path: path,
                            });
                            if(!flage)this.$refs.itemPop.open();
                        } else {
                            var params = {
                                "ItemId": this.orderDetail.itemId,
                                "ProposalId": this.orderDetail.proposalId,
                                "Amount": this.orderDetail.amount,
                                "Sid": this.$store.state.meal.mealRules.sid,
                                "ItemType": 7,
                                phoneNumber: SmartStorage.get("authMobile")
                            }
                            if (['novartis'].includes(this.event.tenant_code)) {
                                address = JSON.parse(this.orderDetail.itemData).Hospital
                                console.log('-0-0-0-',address);
                                params = {
                                    ...params,
                                    Longitude: address.longitude || '',
                                    Latitude: address.latitude || '',
                                    address: address.name || address.hospitalName || ''
                                }
                            }
                            var elemeURL = await miceService.common('/rpc/pay/Alipay/GenerateAliTakeoutUrl', params)
                            if (this.isWx()) {
                                this.$router.push(`/common?type=flowPath&aliURL=${encodeURIComponent(elemeURL.content)}&placeOrder=true&sessionId=${SmartStorage.get("sessionId")}&itemId=${this.orderDetail.itemId}`)
                                return;
                            }
                            this.$router.push(`/common?type=flowPath&aliURL=${encodeURIComponent(elemeURL.content)}`)
                            if(!flage)this.$refs.itemPop.open();
                            return
                        }
                    })
                    break;
                case "DianPing":
                    var address = {}
                    if (['novartis'].includes(this.event.tenant_code)) {
                        address = JSON.parse(this.orderDetail.itemData).Hospital
                    }
                    var pramas = {
                        itemId: this.orderDetail.itemId,
                        Longitude: address.longitude || '',// 104.062742
                        Latitude: address.latitude || '',//30.578242
                        address: address.name || address.hospitalName || '', //新中泰国际大厦A座
                    };
                    // 获取美团地址
                    if (SmartStorage.get("isH5") && !this.isWx()) {// 协办会在浏览器新开页面打开
                        this.open = window.open("", "_blank")
                    }
                    if(this.event.tenant_code != 'roche'){
                        await this.execute(this.orderDetail)
                    }
                    var meituanRes = await this.getDianPingUrl(pramas)
                    if (meituanRes && meituanRes.success) {
                        if(window.__wxjs_environment === "miniprogram" && this.event.tenant_code == 'roche'){
                            this.$refs.browserLoader.openPop(meituanRes.content)
                            return
                        }
                        // 协办会处理微信和浏览器情况
                        if (SmartStorage.get("isH5") && !this.isWx()) {
                            setTimeout(() => {
                                this.open.location = meituanRes.content
                            }, 100)
                            return
                        }
                        if(SmartStorage.get("isH5") && this.isWx()){
                            window.open(meituanRes.content, "_self")
                            return
                        }
                        this.openNewWeb(meituanRes.content)
                        if(!flage)this.$refs.itemPop.open();
                    }
                    break
                case "xiaomishu":
                    // 协办会单独跳转新窗口，兼容iOS浏览器安全检擦不能直接打开新窗口（打开新窗口必须在所有请求之前）
                    if (SmartStorage.get("isH5") && !this.isWx()) {// 协办会在浏览器新开页面打开
                        this.open = window.open("", "_blank")
                    }
                    if(this.event.tenant_code != 'roche'){
                        await this.execute(this.orderDetail)
                    }
                    // 保存数据
                    var iwaimaiUrl = '';
                    var sessionRes = await this.saveCacheData(this.orderDetail)
                    iwaimaiUrl = `${baseUrl.iWaimaiUrl}/ele/shopList?appType=iMeal&rnd=${new Date().getTime()}&sessionId=${sessionRes.content}&qty1=${this.orderDetail.qty1}&noBack=false&isQuery=false`
                    // 协办会处理
                    if(SmartStorage.get("isH5") && !this.isWx()){
                        setTimeout(() => {
                            this.open.location = iwaimaiUrl
                        }, 100)
                        return
                    }
                    if(SmartStorage.get("isH5") && this.isWx()){
                        window.open(iwaimaiUrl, "_self")
                        return
                    }
                    var options = {
                        method: "SMGeneral.openNewWebSiteForResult",
                        postData: {
                            loadUrl: iwaimaiUrl,
                        },
                        callback: (viewData) => {
                            that['FRESH_LIST'](true)
                        }
                    };
                    if(!flage)this.$refs.itemPop.open();
                    this.iJsBridge.call(options);
                    break
                default:
                    break;
            }
        },
        execute(item) {
            let params = {
                NodeCode: "CheckCanOrder",
                ContextDataKeys: {
                    ProposalId: item.proposalId,
                    ItemId: item.itemId
                }
            }
            return new Promise((resolve, reject) => {
                miceService.execute(params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        isWx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        },
        remove() {
            var params = {
                itemId: this.orderDetail.itemId,
                isdeleted: true
            }
            miceService.deleteTakeoutItem(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        async createOrder(item, btn) {
            // if (['会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)) {
            //     this.$refs.createOrder.openPop();
            //     return
            // }
            //下单前提示
            if (this.mealRules.takeOutMsgf) {
                this.$refs.createOrder2.openPop();
                return
            }
            if (['dscl', 'pfizer', 'crossevent', 'novartis', "roche"].includes(this.event.tenant_code)) {
                this.goWaimai()
            } else {
                this.$emit('openWaimaiPop')
            }
        },
        takeOutCheck(item, btn) {
            return new Promise((resolve, reject) => {
                let params = {
                    qty1: item.qty1,
                    Amount: item.amount,
                    ProposalId: item.proposalId,
                    ItemType: item.itemType,
                    ItemId: item.itemId,
                }
                miceService.common(btn.checkurl, params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                })
            })
        },
        goWaimai() {
            if (this.orderDetail.soucreCode) {
                this.handlechannel(this.orderDetail.soucreCode,true)
                return
            }
            if (this.mealRules.takeOutNew) {
                let i = []
                this.mealRules.takeOutList.map((item)=> {
                    if(item.show) i.push(item)
                })
                if(i.length == 1){
                    this.handlechannel(i[0].itemType,true)
                    return
                }
                this.$refs.itemPop.open();
                return
            }
            this.goWaimai2()
        },
        async goWaimai2() {
            let item = this.orderDetail
            let open = null
            if (SmartStorage.get("isH5") || SmartStorage.get("container") == "h5") {
                open = window.open("", "_blank")
            }
            let _this = this;
            let mealCombineData = []
            // 饿了么api
            if (this.orderDetail.sourceCode == 'ELE') {
                let params = {
                    "ItemId": this.orderDetail.itemId,
                    "ProposalId": this.orderDetail.proposalId,
                    "Amount": this.orderDetail.amount,
                    "Sid": this.$store.state.meal.mealRules.sid,
                    "ItemType": 7,
                    phoneNumber: SmartStorage.get("authMobile")
                }
                let elemeURL = await miceService.common('/rpc/pay/Alipay/GenerateAliTakeoutUrl', params)
                if (this.isWx()) {
                    this.$router.push(`/common?type=flowPath&aliURL=${encodeURIComponent(elemeURL.content)}&placeOrder=true&sessionId=${SmartStorage.get("sessionId")}`)
                    return;
                }
                this.$router.push(`/common?type=flowPath&aliURL=${encodeURIComponent(elemeURL.content)}`)
                return
            }
            if (this.event.tenant_code == 'pfizer') {
                this.optionalType = await this.getWaimaiType()
            }
            // 美团
            if (this.optionalType.includes('美团')) {
                let meituanUrl = '';
                let address = {}
                if (['novartis'].includes(this.event.tenant_code)) {
                    address = JSON.parse(item.itemData).Hospital
                }
                let pramas = {
                    itemId: item.itemId,
                    Longitude: address.longitude || '',// 104.062742
                    Latitude: address.latitude || '',//30.578242
                    address: address.name || address.hospitalName || '', //新中泰国际大厦A座
                };
                // 获取美团地址
                let meituanRes = await this.getDianPingUrl(pramas)
                if (meituanRes && meituanRes.success) {
                    meituanUrl = meituanRes.content
                }
                if (SmartStorage.get("isH5") || SmartStorage.get("container") == "h5") {
                    setTimeout(() => {
                        open.location = meituanUrl
                    }, 100)
                    return
                }
                mealCombineData.push(
                    {
                        selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                        normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                        url: meituanUrl
                    })
            }
            if (mealCombineData.length == '1' && this.optionalType.includes('饿了么')) {
                const options = {
                    loadUrl: mealCombineData[0].url,
                    callback: function () {
                        _this['FRESH_LIST'](true)
                    }
                }
                this.iJsBridge.openWebapp(options)
                return
            }
            var u = navigator.userAgent;
            if (this.optionalType.length == '1' && /MicroMessenger/gi.test(u)) {
                if(window.__wxjs_environment === "miniprogram" && this.event.tenant_code == 'roche'){
                    this.$refs.browserLoader.open(mealCombineData[0].url)
                    return
                }
                window.open(mealCombineData[0].url, "_self")
                return
            }
            if (mealCombineData.length == '1') {
                let tenant_code = this.event.tenant_code || 'eventcool'
                const options = {
                    method: "SMGeneral.openNewWebSiteForResult",
                    postData: {
                        loadUrl: mealCombineData[0].url,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    },
                    callback: (viewData) => {
                        _this['FRESH_LIST'](true)
                    }
                };
                this.iJsBridge.call(options);
                return
            }
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData: {
                    loadUrl: 'mealCombineData',
                    cookieData: getAllCookies(),
                    mealCombineData: mealCombineData,
                },
                callback: (viewData) => {
                    _this['FRESH_LIST'](true)
                }
            }
            this.iJsBridge.call(options)
        },
        openNewWeb(webUrl) {
            if (SmartStorage.get("isH5")) {
                setTimeout(() => {
                    this.open.location = webUrl
                }, 100)
                return
            }
            let tenant_code = this.event.tenant_code || 'eventcool'
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData: {
                    loadUrl: webUrl,
                    browserMode: {
                        navBarVisibilty: true,
                        titleTextColor: "#ffffff",
                        navBackgroundColor: this.navBackgroundColor[tenant_code]
                    }
                },
                callback: (viewData) => {
                    this['FRESH_LIST'](true)
                }
            };
            this.iJsBridge.call(options);
        },
        // 地图定位
        getCurrentPosition() {
            indicator.open()
            let _this = this
            return new Promise((resolve, reject) => {
                var map = new AMap.Map("container", {});
                AMap.plugin("AMap.Geolocation", () => {
                    /* global AMap */
                    var geolocation = new AMap.Geolocation({
                        timeout: 3000, //超过n秒后停止定位，默认：5s
                        useNative: true,
                        enableHighAccuracy: true, //是否使用高精度定位，默认:true
                        extensions: 'all'
                    });
                    map.addControl(geolocation);
                    geolocation.getCurrentPosition((status, result) => {
                        if (status == "complete") {
                            console.log('定位成功', status, result)
                            let city = result.addressComponent.city || result.addressComponent.province;
                            let address = {
                                latitude: result.position.lat,
                                longitude: result.position.lng,
                                name: (result.pois || [{ name: '' }])[0].name || result.formattedAddress,
                                city: city
                            }
                            resolve(address)
                        } else {
                            console.log('定位失败', status, result)
                            resolve(result)
                        }
                        indicator.close()
                    })
                })
            })
        },
        // 根据城市查询是否有美团
        getSort() {
            let tenant_code = this.event.tenant_code || "ipsen";
            let params = {
                collection: "cfg-iwaimai-sort-options",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: { $in: [tenant_code || "*", "*"] },
                    eventType: { $in: [this.event.typeDictTxt || "*", "*"] },
                    eventStatus: { $in: [this.event.statusTxt || "*", "*"] },
                    eventPlace: { $in: ["*", "*"] },
                    "cities.city": {
                        $in: [this.event.cityDictVal || "*", "*"],
                    },
                },
                projection: {
                    name: 1,
                    handle: 1,
                    icon: 1,
                    order: 1,
                    id: 1,
                },
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success) {
                        resolve(res.content)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取外卖类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: getCookie('tenant_code') || this.event.tenant_code || "crossevent",
                    mealType: "外卖",
                    tenant_id: getCookie('tenant_id')
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || { optionalType: [] }).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 刷新美团订单
        refreshDianPing(data) {
            return new Promise((resolve, reject) => {
                miceService.refreshDianPing(data).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取美团地址
        getDianPingUrl(data) {
            return new Promise((resolve, reject) => {
                miceService.getDianPingUrl(data).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        saveCacheData(item) {
            SmartStorage.set("eventData", JSON.stringify(this.event), {
                domain: window.idomain
            });
            SmartStorage.set("eventData", JSON.stringify(this.event));
            // 数据持久化保存数据
            let eventDataParams = {
                Data: {
                    eventData: this.event || {},
                    token: getCookie('token'),
                    proposalId: this.event.proposalId,
                    tenant_id: getCookie('tenant_id'),
                    tenant_code: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    tenant: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    itemId: item.itemId,
                    noBack: false,
                    container: SmartStorage.get("container") || '',
                    paramCode: false,
                }
            }
            if (SmartStorage.get('isH5')) {
                eventDataParams.Data.isH5 = SmartStorage.get('isH5')
                eventDataParams.Data.type = SmartStorage.get('type')
            }
            if (item.hospitalGPS) {
                eventDataParams.Data.eventData.hospitalGPS = item.hospitalGPS
            }
            return new Promise((resolve, reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if (sessionRes && sessionRes.success) {
                        resolve(sessionRes)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        addEmployee() {
            return new Promise((resolve, reject) => {
                miceService.addEmployee({ SourceCode: "ELE" }).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        confirmReceipt() {
            this.$router.push({
                path: '/uploadInvoice'
            })
        },
        async cancelOrder() {
            let params = {
                itemId: this.orderDetail.itemId,
                proposalId: this.event.proposalId
            }
            let elemeData = {}
            try {
                elemeData = JSON.parse(this.orderDetail.itemData)
            } catch (error) {
                console.log("解析订单itemData JSON 报错====", this.orderDetail.itemData)
            }
            //饿了吗需要跳中间页
            if (elemeData.orderId && elemeData.standardId && this.orderDetail.soucreCode == 'ELE' && this.orderDetail.extOrderId) {
                let params = {
                    "Sid": this.$store.state.meal.mealRules.sid,
                    "StandardId": elemeData.standardId,
                    "OrderNo": this.orderDetail.extOrderId
                }
                let cancelELEData = await miceService.common('/rpc/pay/Alipay/GenerateAliTakeoutOrderUrl', params)
                if (this.isWx()) {
                    this.$router.push(`/common?type=cancelEle&aliURL=${encodeURIComponent(cancelELEData.content)}&placeOrder=true&sessionId=${SmartStorage.get("sessionId")}`)
                    return;
                }
                this.$router.push(`/common?type=cancelEle&aliURL=${encodeURIComponent(cancelELEData.content)}`)
                return
            }
            if (this.orderDetail.status > 0 && this.orderDetail.sourceCode == 'DianPing') {
                let refreshParams = {
                    sourceCode: 'DianPing',
                    ItemId: this.orderDetail.itemId,
                    proposalId: this.event.proposalId,
                }
                await this.refreshDianPing(refreshParams)
                if(window.__wxjs_environment === "miniprogram"){
                    let meituanRes = await this.getDianPingUrl({itemId: this.orderDetail.itemId})
                    this.$refs.browserLoader.openPop(meituanRes.content)
                    return
                }
                this.openDdianPing()
                return;
            } else {
                miceService.cancelTakeoutItem(params).then(res => {
                    if (res && res.success) {
                        this['FRESH_LIST'](true);
                    }
                })
            }
        },
        openDdianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            }
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        this['FRESH_LIST'](true)
                    }
                })
            })
        },
        reToPay() {
            let params = {
                orderId: this.orderDetail.extOrderId
            }
            miceService.reToPay(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        meiTuanCancel() {
            this['FRESH_LIST'](true);
            return
        }
    },
    filters: {
        qty1: value => {
            if (value && value >= 0) {
                return value + "人";
            } else {
                return "0人";
            }
        },
        amount: value => {
            if (value && value >= 0) {
                return parseFloat(value).toFixed(2);
            } else {
                return 0;
            }
        }
    }
};