const signalR = require("@microsoft/signalr");
class iSignalr {
  constructor({ hubName, queryParams, listeners, host }) {
    this.hubName = hubName;
    this.queryParams = queryParams;
    this.listeners = listeners;
    this.connection = null;
    this.proxy = null;
    this.host = host;
  }

  async install(options) {
    let url = `${this.host}/${this.hubName}?${this.queryParams}`;
    options = options || {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.listeners.forEach((item) => {
      this.connection.on(item.methodName, (data) => item.method(data));
    });
    this.connection
      .start()
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(true);
      });
  }

  async uninstall() {
    this.connection && this.connection.stop();
  }
  async invoke({ methodName, args, callback }) {
    this.connection.invoke(methodName, args).then((result) => {
      callback && callback(result);
    });
  }
}
export default iSignalr;
