
import 'regenerator-runtime/runtime';
import iJsBridge from "./ijsBridge";
import { Toast, Indicator } from 'mint-ui';
let h5Gps = null
let SMAmapGps = null
let AmapGps = null
async function getLocationPro() {
    return new Promise(async(resolve, reject) => {
        let locationMethods = [
            AMapGetLocation,
            h5Location, 
            // ...(isWeChatEnvironment() ? [] : [SMAmapGetLocation]), 
        ];
        let tryNum = 1
        // 尝试所有方法直到成功或全部失败
        const tryAllMethods = async (number) => {
            let position
            let num = 0
            let errorInfo = {
                success: false,
                errorList:[]
            }
            let IndicatorMsg = '定位中'
            if(tryNum > 1){
                IndicatorMsg = '当前信号弱，正在重新尝试定位'
            }
            Indicator.open({
                text: IndicatorMsg,
                spinnerType: 'fading-circle'
            });
            tryNum = tryNum + 1
            for (const method of locationMethods) {
                num ++
                position = await method();
                if(position.success){
                    Indicator.close()
                    console.log('=-==输出定位-=-=-',position);
                    resolve(position);
                    return;
                }else{
                    errorInfo.errorList.push(position)
                }
                if(num == locationMethods.length){
                    if(tryNum == 3){
                        console.log('=-==输出全部失败定位-=-=-',errorInfo);
                        Indicator.close()
                        resolve(errorInfo);
                        return
                    }else{
                        Indicator.close()
                        tryAllMethods(tryNum)
                    }
                }
            }
            // try {
            //     let squareNl = Math.pow((h5Gps.nl*100000 - SMAmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((h5Gps.el*100000 - SMAmapGps.el*100000), 2)
            //     console.log('------h5和原生经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
            // } catch (e) {
            //     console.log('------h5和原生经纬度计算失败------')
            // }
            // try {
            //     let squareNl = Math.pow((h5Gps.nl*100000 - AmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((h5Gps.el*100000 - AmapGps.el*100000), 2)
            //     console.log('------h5和高德经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
            // } catch (e) {
            //     console.log('------h5和高德经纬度计算失败------')
            // }
            // try {
            //     let squareNl = Math.pow((SMAmapGps.nl*100000 - AmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((SMAmapGps.el*100000 - AmapGps.el*100000), 2)
            //     console.log('------原生和高德经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
            // } catch (e) {
            //     console.log('------原生和高德经纬度计算失败------')
            // }
        };
        tryAllMethods().catch({
            success: false
        });
    });
}
function isWeChatEnvironment() {
    return /MicroMessenger/i.test(navigator.userAgent);
}
function h5Location() {
    return new Promise((resolve, reject) => {
        console.log('=-==进行h5定位-=-=-');
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 3000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position && position.coords) {
                        let positions = transformFromWGSToGCJ(position.coords.latitude,position.coords.longitude)
                        let positionInfo = {
                            nl: positions.latitude,
                            el: positions.longitude,
                            success: true
                        }
                        console.log('=-=h5Location-=-=-',position);
                        h5Gps = positionInfo
                        resolve(positionInfo)
                    } else {
                        console.log('=-=h5Location获取失败-=-=-',JSON.stringify(position));
                        resolve({
                            message: "h5Location获取失败",
                            success: false
                        });
                    }
                },
                (error) => {
                    let message = ""
                    if(error && error.message){
                        message = error.message
                    }else{
                        message = "h5Location获取失败"
                    }
                    console.log('=-=h5Locationerror获取失败-=-=-',JSON.stringify(error));
                    resolve({
                        message: message,
                        success: false
                    });
                },
                options
            );
        } else {
            resolve({
                message: "浏览器不支持地理位置服务",
                success: false
            });
        }
    })
}
/**
 *  将WGS-84(国际标准)转为GCJ-02(火星坐标):
 */
function transformFromWGSToGCJ(latitude, longitude) {
    var lat = "";
    var lon = "";
    var ee = 0.00669342162296594323;
    var a = 6378245.0;
    var pi = 3.14159265358979324;
  
    if (isLocationOutOfChina(latitude, longitude)) {
      lat = latitude;
      lon = longitude;
    }
    else {
      var adjustLat = transformLatWithXY(longitude - 105.0, latitude - 35.0);
      var adjustLon = transformLonWithXY(longitude - 105.0, latitude - 35.0);
      var radLat = latitude / 180.0 * pi;
      var magic = Math.sin(radLat);
      magic = 1 - ee * magic * magic;
      var sqrtMagic = Math.sqrt(magic);
      adjustLat = (adjustLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
      adjustLon = (adjustLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
      latitude = latitude + adjustLat;
      longitude = longitude + adjustLon;
    }
    return { latitude: latitude, longitude: longitude };
  }
  
function transformLatWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lat = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    lat += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lat += (20.0 * Math.sin(y * pi) + 40.0 * Math.sin(y / 3.0 * pi)) * 2.0 / 3.0;
    lat += (160.0 * Math.sin(y / 12.0 * pi) + 320 * Math.sin(y * pi / 30.0)) * 2.0 / 3.0;
    return lat;
  }
  
  function transformLonWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lon = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    lon += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lon += (20.0 * Math.sin(x * pi) + 40.0 * Math.sin(x / 3.0 * pi)) * 2.0 / 3.0;
    lon += (150.0 * Math.sin(x / 12.0 * pi) + 300.0 * Math.sin(x / 30.0 * pi)) * 2.0 / 3.0;
    return lon;
  }
/**
 *  判断经纬度是否超出中国境内
 */
function isLocationOutOfChina(latitude, longitude) {
    if (longitude < 72.004 || longitude > 137.8347 || latitude < 0.8293 || latitude > 55.8271)
      return true;
    return false;
  }

function SMAmapGetLocation() {
    return new Promise((resolve, reject) => {
        if(isWeChatEnvironment()){
            resolve({
                message: "定位失败",
                success: false
            })
            return
        }
        console.log('=-==进行原生定位-=-=-');
        var options = {
            method: "SMAmap.getLocation",
            postData: {
                loadUrl: window.location.href,
            },
            callback: (viewData) => {
                console.log('=-=SMAmapGetLocation返回-=-=-',viewData);
                let positionInfo = {}
                if (viewData && viewData.errorCode) {
                    console.log('=-=原生获取失败------');
                    resolve({
                        message: "原生获取失败:" + viewData.errorDescr,
                        success: false
                    });
                    return;
                }
                try {
                    positionInfo = {
                        nl: viewData.lat,
                        el: viewData.lon,
                        address: '',
                        success: true
                    }
                    SMAmapGps = positionInfo
                    console.log('=-=SMAmapGetLocation-=-=-',positionInfo);
                    resolve(positionInfo)
                } catch (e) {
                    console.log('=-=原生解析失败-=-=-');
                    resolve({
                        message: "原生解析失败",
                        success: false
                    });
                }
                console.log('=-==结束原生定位-=-=-');
            }
        };
        const IJsBridge = new iJsBridge({ alert: ()=>{} });
        try {
            IJsBridge.call(options);
        } catch (error) {
            resolve({
                message: "原生获取失败",
                success: false
            });
            
        }
    })
}

function AMapGetLocation() {
    return new Promise((resolve, reject) => {
        console.log('=-==进行高德定位-=-=-');
        try {
            AMap.plugin("AMap.Geolocation", function() {
                try {
                    const geolocation = new AMap.Geolocation({
                        enableHighAccuracy: true,
                        timeout: 3000,
                    });
                    geolocation.getCurrentPosition((status, result) => {
                        if (status === "complete" && result && result.position) {
                            console.log('=-=-AMapGetLocation=-=-',result);
                            AmapGps = {
                                nl: result.position.lat,
                                el: result.position.lng,
                            }
                            resolve({
                                nl: result.position.lat,
                                el: result.position.lng,
                                address: result.formattedAddress,
                                province: result.addressComponent.province,
                                city: result.addressComponent.city || result.addressComponent.province,
                                district: result.addressComponent.district,
                                street: result.addressComponent.street,
                                streetNumber: result.addressComponent.streetNumber,
                                success: true
                            });
                        } else {
                            let errorMessage = '高德定位获取失败';
                            if (result && result.message) {
                                errorMessage = `: ${result.message}`;
                            }
                            console.log('=-=高德位置获取失败-=-=-',JSON.stringify(result));
                            resolve({
                                message: errorMessage,
                                success: false
                            });
                        }
                    });
                } catch (error) {
                    console.log('=-=高德调用失败-=-=-');
                    resolve({
                        message: "高德调用失败",
                        success: false
                    });
                }
            });
        } catch (error) {
            console.log('=-=高德api调用失败-=-=-');
            resolve({
                message: "高德api调用失败",
                success: false
            });
            
        }
        
    });
}


// 监听定位
export function watchPosition() {
    if (navigator.geolocation) {
        const options = {
            enableHighAccuracy: true,
            timeout: 3000,
            maximumAge: 0,
        };
        navigator.geolocation.watchPosition(
            (position) => {
                if (position && position.coords) {
                    let positions = transformFromWGSToGCJ(position.coords.latitude,position.coords.longitude)
                    let positionInfo = {
                        nl: positions.latitude,
                        el: positions.longitude,
                        success: true
                    }
                    console.log('=-=h5Location-=-=-',position);
                    h5Gps = positionInfo
                } else {
                    console.log('=-=h5Location获取失败-=-=-',JSON.stringify(position));
                }
            },
            (error) => {
                let message = ""
                if(error && error.message){
                    message = error.message
                }else{
                    message = "h5Location获取失败"
                }
                console.log('=-=h5Locationerror获取失败-=-=-',JSON.stringify(error));
            },
            options
        );
    } else {
        console.log('=-=浏览器不支持地理位置服务-=-=-');
    }
}

export default getLocationPro
