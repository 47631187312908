<template>
    <div class="addMealDemand2" id="addMealDemandCrossevent">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText" style="font-size:.17rem;">添加外出用餐需求</span>
        </Header>
        <div class="addMealDemand-container">
            <AttentionTips v-if="tipsF" :tips="tips" :newUI="true" />
            <div>
                <div class="normalInputRow2" :class="classObject" @click="openComponent()" >
                    <MInput title="开始时间" :labelBlack="true" must="true" :disabled="true" pla="请选择开始时间" v-model="input.dtStart"></MInput>
                </div>
                <div class="normalInputRow2">
                    <MInput title="用餐类型" :disabled="true" :labelBlack="false" :borderBottom="true" must="true" type='tel' pla="系统根据时间自动填充" v-model="input.itemName"></MInput>
                </div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐形式<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in mealTypeList"
                            :key="index"
                            :btnClass="input.mealType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="handleRadio(item, 'mealType')"
                        >
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="normalInputRow2">
                    <MInput title="预计人数" :labelBlack="true" :borderBottom="true" must="true" type='tel' pla="请输入用餐人数" v-model="input.qty1"></MInput>
                </div>
                <div class="normalInputRow2">
                    <MInput title="预计金额" :labelBlack="true" must="true" pla="请输入预计金额" type='number' v-model="input.amount"></MInput>
                </div>
                <div class="normalInputRow2">
                    <MInput title="订餐人姓名" :labelBlack="true" must="true" pla="请输入订餐人姓名" v-model="input.bookingName"></MInput>
                </div>
                <div class="normalInputRow2">
                    <MInput title="订餐人手机" :labelBlack="true" type="tel" must="true" pla="请输入订餐人手机号" v-model="input.bookingPhoneNum"></MInput>
                </div>
                <div class="normalInputRow2">
                    <MInput title="备注" :labelBlack="true" pla="请输入备注信息" v-model="input.descr"></MInput>
                </div>
            </div>
            <div v-if="shopType == '平台到餐'" class="add_shop_box">
                <div class="choose-shop-title">选择餐厅<span>*</span></div>
                <div class="shop-item-btn" v-if="input.shopName">
                    <img :src="shop.imgThumbnail || input.shopImg" @error="setErrorImg" />
                    <div class="shop-name">{{input.shopName}}</div>
                    <span :style="['会引擎-灵北'].includes(this.event.tenant_name) && orderDetail.itemId?`display:none`:''" slot="btnContent" @click="delateShop">
                        <Svgs name="iconshanchu3"></Svgs>
                    </span>
                </div>
                <div v-else class="add-btn" @click="openMeal">
                    <Svgs color="#C0C4CD" name="iconhao"></Svgs>
                </div>
            </div>
            <Btn btnClass="submit-shop" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
        <ChangeShopPop ref="ChangeShopPop" :itemList="itemList" @handlechannel="handlechannel" />
        <BrowserLoader ref="browserLoader" @close="getShop" />
    </div>
</template>
<script>

import getPositionFunction from "@/utils/getPositionFunction.js";
import ChangeShopPop from "@/components/ChangeShopPop.vue";
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import { Indicator,MessageBox } from 'mint-ui';
import initTime from "@/utils/initTime.js"
import {SmartStorage} from 'smart-core-util'
import {
    mealAddDemand
} from "@/config/mealAddDemand.js";
import { getCookie,getAllCookies } from 'tiny-cookie'
import { formatDate } from '@/core';
import { Base64 } from "js-base64";
import { Dialog } from 'vant';
export default {
    mixins: [getPositionFunction],
    components:{ChangeShopPop},
    data() {
        return {
            budgetListShow: false,
            budgetkeyword: '',
            input: {
                itemName: "", // 用餐类型
                mealType: "", // 用餐形式
                qty1: "", // 用餐人数
                amount: "", // 预计金额
                dtStart: "", // 用餐开始时间
                dtEnd: "", // 用餐结束时间
                bookingName: "", // 订餐人姓名
                bookingPhoneNum: "", // 订餐人电话
                descr: "", // 备注
                isMeetingPlace: true, //会议场所
                isExtra: "true", //"true"为线上餐，"false"为线下餐
                shopName: "", //餐厅名字
                shopMobile: "", //餐厅电话
                shopAddress: "", //餐厅地址
                province: "", //餐厅省份
                city: "", //餐厅城市
                cityRegion: "", //餐厅城市区域
                hospitalId: "",
                isDrink: false
            },
            shopType: "",
            itemNameList: [{ txt: "午餐" }, { txt: "晚餐" }],
            delayClick: null, //连续点击300毫秒延迟
            start: '',
            end: '',
            tips: ["需求信息（*为必填项）"],
            tipsF: false,
            ItemId: '',
            getShops: 1,
            clientHeight: 0,
            originalHeight: 0,
            hospitalGPS: {},
            itemList: [],
            isRadio:false
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "memsg", "shop", "params", "currentPosition", "cityAddress",'address', "hospitalGPS1"]),
        classObject: function() {
            return {
                'black': this.event.tenant_code == 'pfizer' && ["跨院云会议",'其他会议'].includes(this.event.typeDictTxt)
            };
        },
        mealTypeList: {
            get: function() {
                let list = [
                    { txt: "商务套餐" },
                    { txt: "桌餐" }
                ]
                if(['novartis'].includes(this.event.tenant_code)){
                    list = [
                        { txt: "自助餐" },
                        { txt: "围餐" },
                        { txt: "商务用餐" }
                    ]
                }
                return list
            }
        },
    },
    watch: {
        'input.dtStart':{
            handler(newVal){
                if(newVal && (this.event.tenant_code == 'pfizer' || this.event.tenant_name == '会引擎-灵北')){
                    var date = new Date(newVal);
                    var hours = date.getHours();
                    let isDinner = Math.ceil(hours) >= 14
                    this.input.itemName = isDinner ? '晚餐' : '午餐'
                }
            },
            deep: true
        }
    },
    async created() {
        this.setDateRange();
        // if(['会引擎-灵北'].includes(this.event.tenant_name)){
        //     this.input.qty1 = this.event.extData.qty
        //     this.input.dtStart = this.event.dtStart.split('T')[0] + ' 00:00'
        //     this.input.dtEnd  = this.event.dtStart.split('T')[0] + ' 23:59'
        //     let initBudgetList = await miceService.common('/api/jeventorder/budget/budgetList',{proposalId:this.event.proposalId})
        //     if (initBudgetList && initBudgetList.content) {
        //         this.budgetListData = initBudgetList.content
        //     }
        // }
        this.tipsF = true
    },
    mounted() {
        document.querySelector('body').addEventListener('touchmove', function(e) {
            if (!document.querySelector('#addMealDemandCrossevent').contains(e.target)){
                e.preventDefault();
            }
        }, { passive: false })
        this.originalHeight = document.documentElement.clientHeight;
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('resize', this.resizeEvent);
        this.delayClick = new this.delay();
        if(!(this.orderDetail || {}).itemId) {
            this.input.bookingName = this.memsg.dspName
            this.input.bookingPhoneNum = this.memsg.phoneNumber
        }
        this.init();
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('touchmove',function(e) {
            if (!document.querySelector('#addMealDemandCrossevent').contains(e.target)){
                e.preventDefault();
            }
        })
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('resize', this.resizeEvent);
    },
    methods: {
        ...mapMutations(["ADDRESS", "PARAMS", "SHOP", "HOSPITAL_GPS", "FRESH_SHOP"]), 
        handleResize() {
            this.clientHeight = document.documentElement.clientHeight;
        },
        resizeEvent() {
            if (document.activeElement.tagName == 'INPUT') {
                window.setTimeout(() => {
                document.activeElement.scrollIntoViewIfNeeded();
                }, 100);
            }
        },
        async searchbudgetList(){
            this.input.budgetNum = null
            let res = await miceService.common('/api/jeventorder/budget/budgetList',{proposalId:this.event.proposalId,itemNum:this.budgetkeyword})
            if (res && res.success) {
                this.budgetListData = res.content
                this.$forceUpdate()
            }
        },
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/logo.png");
            img.οnerrοr = null;
        },
        setDateRange() {
            let results = initTime(this.event.dtStart, this.event.dtEnd, 0, 0, 0)
            this.start = this.fomatDateTime(results.minDate);
            this.end = this.fomatDateTime(results.maxDate);
        },
        handleRadio(item, type) {
            this.input[type] = item.txt;
        },
        openComponent() {
            let start = this.start;
            let end = this.end;
            this.$iDatePicker.render({
                format: 'YYYY-MM-DD hh:mm',
                startDate: start,
                endDate: end,
                value: this.input.dtStart,
                onConfirm: function(data) {
                        this.input.dtStart = data
                        this.input.dtEnd = ''
                }.bind(this)
            })
        },
        init() {
            this.shopType = this.$route.query.shopType || "平台到餐";
            let orderDetail = this.orderDetail;
            let itsItemData = orderDetail.itsItemData || {};
            if (orderDetail.itemId) {
                this.$nextTick(() => {
                    this.input = {
                        itemId: orderDetail.itemId,
                        itemName: orderDetail.itemName,
                        mealType: itsItemData.mealType,
                        isExtra: orderDetail.isExtra,
                        qty1: orderDetail.qty1,
                        amount: orderDetail.amount,
                        dtStart: this.fomatDateTime(orderDetail.dtStart),
                        dtEnd: this.fomatDateTime(orderDetail.dtEnd),
                        isMeetingPlace: true,
                        shopName: itsItemData.shopName,
                        bookingName: itsItemData.bookingName,
                        bookingPhoneNum: itsItemData.bookingPhoneNum,
                        descr: orderDetail.descr,
                        shopImg: itsItemData.shopImg,
                        isDrink: itsItemData.isDrink || '',
                        gps: itsItemData.gps,
                    };
                    if(this.event.tenant_code == 'novartis'){
                        this.hospitalGPS = {
                            longitude: JSON.parse(itsItemData.gps).longitude,
                            latitude: JSON.parse(itsItemData.gps).latitude,
                            hospitalName: itsItemData.hospitalName,
                            rowId: itsItemData.hospitalId
                        }
                    }
                    if(['会引擎-灵北'].includes(this.event.tenant_name)){
                        this.input.budgetRemark = orderDetail.itsItemData.budgetRemark
                        this.input.budgetNum = orderDetail.itsItemData.budgetNum
                    }
                    if (this.event.typeDictTxt == "科室会" ||
                        this.event.typeDictTxt == "院内会") {
                        this.input.shopDistance = orderDetail.itsItemData.shopDistance
                    }
                    if (orderDetail.isExtra) {
                        this.input.shopRowId = itsItemData.shopId;
                        this.input.sourceCode = orderDetail.sourceCode;
                        if(orderDetail.sourceCode == 'MeiTuan'){
                            this.shopType = "美团到餐";
                        }
                        // let temp = (orderDetail.itsItemData || { payProfile: "" })
                        //     .payProfile || { PayModes: [] };
                        // let payMode =
                        //     typeof temp.PayModes == "object"
                        //         ? temp.PayModes[0]
                        //         : temp.PayModes;
                        // if (payMode == 7) {
                        //     this.shopType = "美团到餐";
                        // } else if (payMode == 9) {
                        //     this.shopType = "商宴通";
                        // }
                    } else {
                        this.input.shopMobile = itsItemData.mobile;
                        this.input.shopAddress = itsItemData.address;
                        this.input.province = itsItemData.province;
                        this.input.city = itsItemData.city;
                        this.input.cityRegion = itsItemData.cityRegion;
                    }
                })
            }
            if(JSON.stringify(this.params) != "{}") {
                this.$nextTick(() => {
                    this.input = this.params
                    this.input.shopName = this.shop.fullName;
                    this.input.isExtra = this.shop.isExtra;
                    this.input.shopRowId = this.shop.rowId;
                    this.input.sourceCode = this.shop.sourceCode;
                    this.input.mobile = this.shop.tel || '';
                    this.input.gps = {
                        nl:this.shop.nl || '',
                        el:this.shop.el || ''

                    };
                    if(this.event.tenant_code == 'novartis'){
                        this.hospitalGPS = this.hospitalGPS1
                    }
                    
                    if (this.event.typeDictTxt == "科室会" ||
                        this.event.typeDictTxt == "院内会") {
                        this.input.shopDistance = this.shop.distance
                    }
                })
            }
        },
        fomatDateTime(value, type) {
            if (!value) return;
            if (value.replace) {
                value = value.replace(/T/g, " ");
                value = value.replace(/-/g, "/");
            }
            var date = new Date(value);
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var _date =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            if(type) {
                let dateTime =
                    year + "-" + month + "-" + _date;
                return dateTime;
            } else {
                let dateTime =
                    year + "-" + month + "-" + _date + " " + hour + ":" + minute;
                return dateTime;
            }
        },
        // 选择餐厅
        async openMeal() {
            if(!this.input.dtStart || !this.input.dtStart){
                this.toast("请先选择用餐时间");
                return
            }
            await this.getPosition()
            this["PARAMS"](this.input)
            this["FRESH_SHOP"](true)
            this.$router.push('/shopList?refresh=true&changeShop=false')
        },
        // 跳转餐厅
        async openShopArr(item){
			let mealCombineData = [];
			const sessionIdData = await this.saveCacheData()
            let shopUrl = SmartStorage.get('Uris').Uris['webapp-imeal'] + '/shopList?refresh=true&type=selectShop&changeShop=false&sessionId=' +  sessionIdData
            if (["pfizer"].includes(this.event.tenant_code)) {
                mealCombineData.push({
                selected:
                    "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/5ecf8dc0-0f07-4bb8-942d-562c7ebd7ab2",
                normal:
                    "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/97909875-c918-4a9a-a010-e4f8fa467c9b",
                url: shopUrl,
                txt: '聚合餐厅'
                });
            } else {
                mealCombineData.push({
                    selected: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_focus.png',
                    normal: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_blur.png',
                    url: shopUrl,
                    icon:'icon-cantingku',
                    txt: '聚合餐厅'
                })
            }
			//获取美团地址`
            let meituanUrl = ''
            this.ItemId = this.generateUUID()
			let meituanParams ={ 
				ItemId: this.ItemId,
				longitude: this.address.longitude,
				latitude: this.address.latitude,
			}
            console.log('获取美团地址参数meituanParams',meituanParams)
			const meituanRes = await this.MeituanLogin(meituanParams)
			if (meituanRes) {
				meituanUrl = meituanRes;
			}else{
				return
			}
            mealCombineData.push({
                selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                url: meituanUrl,
                icon:'icon-meituanwaimai',
                txt: '美团'
            })
            this.itemList = mealCombineData
            var u = navigator.userAgent;
            if(window.__wxjs_environment === "miniprogram" || /MicroMessenger/gi.test(u)){
                this.$refs.ChangeShopPop.open()
                return
            }
            let _this = this;
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData:{
                    loadUrl: 'mealCombineData',
                    cookieData: getAllCookies(),
                    mealCombineData: mealCombineData,
                },
                callback: async (viewData) => {
                    console.log('======选择餐厅返回值',viewData);
                    if (!viewData || viewData == '' || viewData == {} || viewData == "{}") {
                        console.log('======美团返回',viewData);
                        Indicator.open({
                            text: '加载中...',
                            spinnerType: 'fading-circle'
                        });
                        setTimeout(() => {
                            _this.getShops = 1
                            _this.getShop()
                            Indicator.close()
                        }, 800)
						return
					}
                    var postData
                    
                    try {
                        postData = JSON.parse(viewData)
                    } catch (error) {
                        postData = viewData
                        console.log('====JS转换失败====',viewData)
                    }
					if (['roche'].includes(_this.event.tenant_code)) {
                        postData = await this.queryCacheData(viewData)
					}
                    if(viewData != 'refresh'){
                        _this["SHOP"](postData);
                        _this.input.shopName = postData.fullName;
                        _this.input.isExtra = postData.isExtra;
                        _this.input.shopRowId = postData.rowId;
                        _this.input.sourceCode = postData.sourceCode;
                        _this.input.mobile = postData.tel || '';
                        _this.input.gps = {
                            nl:_this.shop.nl || '',
                            el:_this.shop.el || ''
                        }
                        if (_this.event.typeDictTxt == "科室会" ||
                            _this.event.typeDictTxt == "院内会") {
                            _this.input.shopDistance = postData.distance
                        }
                    }
                    Indicator.close()
                }
            };
            this.iJsBridge.call(options);
        },
        queryCacheData(sessionId) {
            let sessionIdParams = {
                sessionId: sessionId,
            };
            return new Promise((resolve, reject) => {
                miceService
                    .queryCacheData(sessionIdParams)
                    .then((sessionRes) => {
                        if (sessionRes && sessionRes.success) {
                            resolve(sessionRes.content);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        handlechannel(item){
            if (item.txt == '美团') {
                this.$refs.browserLoader.openPop(item.url)
                return;
            }else{
                this.$router.push('/shopList?refresh=true&changeShop=false')
            }
        },
		// 获取餐厅信息
		async getShop() {	
			let params = {
				ItemId: this.ItemId
			}
			if (this.getShops == 4) {
				this.toast('选择餐厅失败，请重新选择')
				return
			}
			// const res = await this.QuerySelectShop(params)
            miceService.QueryShop(params).then(res => {
                if (res && res.success) {
                    if (res.content == null) {
                        setTimeout(() => {
                            this.getShops++
                            this.getShop()
                        }, 800)
                        return
                    }
                    this.input.shopName = res.content.shopName || '';
                    this.input.shopAddress = res.content.shopAddress || '';
                    this.input.shopMobile = res.content.shopMobile || '';
                    this.input.restaurantId = res.content.shopId || '';
                    this.input.sourceCode = res.content.sourceCode || '';
                    this.input.shopRowId = res.content.shopRowId || '';
                    this.input.itemId = res.content.itemId || '';
                    this.input.ExtOrderId = res.content.orderId || '';
                    this.input.extOrderData = res.content.extOrderData || '';
                    this.input.shopImg = res.content.shopImg || '';
                    this.input.IsGenerateItemId = true;
                }
            })
			
		},
		generateUUID() {
            if(this.input.itemId){
                return this.input.itemId
            }
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		},
        saveCacheData(){
			// 数据持久化保存数据
            let event = {
                ...this.event || {},
                mealCombineData: true
            }
			let eventDataParams = {
                Data: {
                    eventData: event || {},
                    token: getCookie('token'),
                    proposalId: this.event.proposalId,
                    tenant_id: getCookie('tenant_id'),
                    tenant_code: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    tenant: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    noBack: true,
                    paramCode: SmartStorage.get("paramCode"),
                }
            }
            return new Promise((resolve,reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        MeituanLogin(meituanParams){
            return new Promise((resolve,reject) => {
                miceService.meituanLogin(meituanParams).then(res => {
                    if(res && res.success){
                        resolve(res.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        // 获取外出用餐类型
        getMealType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: this.event.tenant_code || "crossevent",
                    mealType: "外出用餐"
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {   
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || {optionalType:[]}).optionalType.includes('美团'))
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        back() {
            this.reset()
            this.$router.push('/orderList');
        },
        reset() {
            this["ADDRESS"]({})
            this["PARAMS"]({})
            this["SHOP"]({});
            this["HOSPITAL_GPS"]({})
        },
        delateShop() {
            this.input.shopName = "";
            this.input.isExtra = "true";
            delete this.input.shopRowId;
            delete this.input.sourceCode;
            this.input.shopAddress = "";
            this.input.province = "";
            this.input.city = "";
            this.input.cityRegion = "";
            this["SHOP"]({});
        },
        async isExtra() {
            let tenant = this.event.tenant_code
            let params = {
                collection: "cfg-imeal-wiki",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: tenant,
                    entryCode: "add-demand",
                    "cities.city": {
                        $in: [this.event.cityDictVal || "*", "*"],
                    },
                },
                projection: {
                    content: 1,
                    isShow: 1,
                    contentList:1
                },
            };
            return new Promise((resolve, reject) => {
                miceService.getsettings(params).then(res => {
                    if (res && res.success) {
                        resolve(res.content.contentList || res.content.content)
                    }else{
                        reject()
                    }
                })
            })
        },
        async submit() {
            this.input.isExtra = true
            if (this.delayClick.get()) return;
            this.delayClick.init();
            if (this.shopType == "美团到餐") {
                this.input.sourceCode = "MeiTuan";
                this.input.shopRowId = "1912055";
            } else if (this.shopType == "商宴通") {
                this.input.sourceCode = "SYT";
                this.input.shopRowId = "1927962";
            }
            let validateResult = this.validate(this.input, mealAddDemand.employeeBase);
            if (!validateResult) return;
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(this.input.dtStart).getTime()
            var dtEndTime = new Date(this.input.dtEnd).getTime()
            this.input.proposalId = this.event.proposalId;
            this.input.ItemType = '5';
            miceService.saveMealItem(this.input).then(res => {
                if (res && res.success) {
                    this.back()
                }
            });
        },
        timeIndate() {
            var event = this.event;
            var eventStartDate = new Date(event.dtStart).getTime(); //会议时间
            var eventEndDate = new Date(event.dtEnd).getTime();
            var minDate = eventStartDate; //开始时间
            var maxDate = eventEndDate + 86400000 - 60 * 1000; //会议时间
            let dtStart = new Date(this.input.dtStart.replace(/-/g, "/")).getTime();
            let dtEnd = new Date(this.input.dtEnd.replace(/-/g, "/")).getTime();
            if ((event.dtTypeTxt || event.extData.dtTypeTxt) === "上午会") {
                return maxDate < dtEnd;
            } else if ((event.dtTypeTxt || event.extData.dtTypeTxt) === "下午会") {
                return minDate > dtStart;
            } else {
                return false;
            }
        },
    }
};
</script>
<style lang="scss" scoped>
@import "addMealDemand2.scss";
.addMealDemand2 {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: .1rem;
    }
    .colorBlack{
        color: #90939A!important;
    }
}   
</style>