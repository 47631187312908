<template>
    <div class="item7Detail2">
        <CheckboxGroup v-model="result" @change="checkChange" ref="checkboxGroup">
            <div 
                class="eatingContent" 
                :class="result.includes(item.taskId) && 'chose'" 
                v-for="(item, index) in takeoutList" 
                :key="'takeout'+index">
                <div class="itemList flex-90">
                    <span class="approvalIcon" v-if="['novartis'].includes(event.tenant_code) && item.itemData">
                        <svg class="icon " aria-hidden="true" >
                            <use  :xlink:href="JSON.parse(item.itemData).OwnerConfirm==1?'#icon-yiqueren':JSON.parse(item.itemData).OwnerConfirm==2?'#icon-yijujue':''"/>
                        </svg>
                    </span>
                    <div class="eatingHead">
                        <div class="eatingImg">
                            <div>
                                <svg class="icon" aria-hidden="true">
                                    <use fill="#fff" xlink:href="#iconshop" />
                                </svg>
                            </div>
                            <!-- <img :src="item.shopImg || require('../../assets/img/logo.png')"> -->
                        </div>
                        <div class="eatingShop">
                            <div class="title">
                                <p class="eatingShopName">{{item.shopName || '未选择商户'}}</p>
                                <div class="status" :class="computer_status(item.status)">{{item.statusTxt}}</div>
                            </div>
                            <div  v-if="item.status != 0"
                                class="eatingShopDate"
                            >您选择了 {{item.oneDish}} 等{{item.dishCount}}件商品</div>
                            <div v-if="item.status == 0"
                                class="bookingName"
                            >
                            <div>
                                预计人数&emsp;
                                <span>{{item.qty1}}</span>
                            </div>
                            <div>
                                预计金额&emsp;
                                <span>￥{{item.amount | amount}}</span>
                            </div>
                            </div>
                            <div
                                class="bookingName"
                            >
                                <div>
                                订单创建人&emsp;
                                    <span>{{item.creatorDspName}}</span>
                                </div>
                            </div>
                            <div
                                v-if="event.tenant_code == 'pfizer' && event.typeDictTxt == '患者教育会'"
                                class="bookingName"
                            >
                                <div>
                                    是否为饮品费&emsp;
                                        <span>{{item.isDrink? '是' : '否'}}</span>
                                </div>
                            </div>
                            <div v-if="item.status != 0"
                                class="bookingName"
                            >
                                <div>
                                    实际金额&emsp;
                                    <span>￥{{item.settle | amount}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="['会引擎-灵北'].includes(event.tenant_name) && JSON.parse(item.itemData).budgetNum"
                            >
                                会议用餐编号&nbsp;
                                <span>{{JSON.parse(item.itemData).budgetNum}}</span>
                                &nbsp;
                                申请金额&nbsp;
                                <span>¥{{parseFloat(JSON.parse(item.itemData).budgetAmount).toFixed(2)}}</span>
                            </div>
                            <div
                                class="bookingName"
                                v-if="['会引擎-灵北'].includes(event.tenant_name) && JSON.parse(item.itemData).budgetRemark"
                            >
                                <div>
                                    描述&nbsp;
                                    <span>{{JSON.parse(item.itemData).budgetRemark}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mealBtnRow" v-if="!batchApproval && (item.itsButtons.filter(item => item.processor != 'refreshOrder')).length >= 1">
                        <div class="btn-more" v-if="(item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder')).length>4" @mouseover="show(index)" @mouseout="hide(index)">
                            <!-- <Svgs name="icongengduo1"></Svgs> -->
                            <span style="color: #90939A">更多</span>
                            <ul class="more-container" :class="`btn-more-out${index}`">
                                <template v-for="(but, i) in (item.itsButtons.filter(item => item.processor != 'refreshOrder'))">
                                    <li v-if="i>2" @click="handleClick(but, item)" :key="i">{{but.txt}}</li>
                                </template>
                            </ul>
                        </div>
                        <template v-for="(but, i) in (item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder'))">
                            <div
                                :class="'mealOperation '"
                                :key="i"
                                v-if="i < 4" 
                                @click="handleClick(but, item)"
                            >
                                <span slot="btnContent">
                                    {{but.txt}}
                                </span>
                            </div>
                        </template>
                    </div>

                </div>
                <Checkbox shape="square" v-if="batchApproval" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" :name="item.taskId"> 
                </Checkbox>
            </div>

        </CheckboxGroup>
        <BrowserLoader ref="browserLoader" @close="meiTuanCancel" />
        <van-dialog
            v-model="topayDialog"
            title="合规文件是否已具备"
            :showCancelButton='true'
            confirmButtonText="会议已结束"
            :confirmButtonColor="topayResult.length == 2 ? 'var(--themeColor)' : '#ccc'"
            :beforeClose="topayDialogConfirm"
        >
            <div class='topayDialog'>
                <CheckboxGroup v-model="topayResult" @change="checkChange" ref="checkboxGroup">
                    <Checkbox shape="square" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" name="1"> 
                        会议照片
                    </Checkbox>
                    <Checkbox shape="square" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" name="2"> 
                        会议签到表
                    </Checkbox>
                </CheckboxGroup>
            </div>
        </van-dialog>
        <ItemPop ref="itemPop" @handlechannel="handlechannel" :itemList="mealRules.takeOutList" @chooseHos="chooseHos" />
        <MessageBox ref="remove" type="tips" messageTxt="确认删除?" @onOk="remove"></MessageBox>
        <MessageBox ref="cancel" type="tips" messageTxt="确认取消?" @onOk="cancelOrder"></MessageBox>
        <!-- <MessageBox ref="createOrder" type="tips" messageTxt="请注意外卖送餐地址只能选择会议城市" @onOk="goWaimai"></MessageBox> -->
        <MessageBox ref="createOrder2" type="tips" :messageTxt="mealRules.takeOutMsg" @onOk="goWaimai"></MessageBox>
        <MessageBox ref="meicanCancel" type="tips" :messageTxt="msg" @onOk="meicanCancel"></MessageBox>
    </div>
</template>
<script>
import Item7Detail from "./Item7Detail2.js";
export default Item7Detail;
</script>
<style lang="scss">

.item7Detail2 {
    width: 100%;
    .eatingContent + .eatingContent {
        margin-top: 0.14rem;
    }
    .chose{
        box-shadow: 0px 1px 4px 0px var(--themeColor)!important
    }
    .topayDialog{
        padding: .2rem .3rem;
        .van-checkbox{
            margin-bottom: .1rem;
        }

    }
    .eatingContent {
        width: 100%;
        border-radius: .1rem;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
        display: flex;
        padding-bottom: .12rem;
        .flex-90{
            flex-basis: 90%;
        }
        .van-checkbox{
            padding: 0 .1rem 0 0!important;
        }
        .itemList{
            width: calc(100% - .24rem)!important;
            padding: .12rem .12rem 0 .12rem;
            flex-grow: 1;
            position: relative;
            .approvalIcon{
                position: absolute;
                right: .1rem;
                top: .5rem;
                .icon{
                    width: .4rem;
                    height: .4rem;
                }
            }
        }
        .eatingHead {
            padding-bottom: .05rem;
            display: flex;
            .approvalIcon{
                position: absolute;
                right: .1rem;
                bottom: .8rem;
                .icon{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .eatingImg {
                    width: 12%;
                    text-align: left;
                div{
                    width: .35rem;
                    height: .35rem;
                    border-radius: 50%;
                    background: var(--themeColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon{
                        width: .2rem;
                        height: .2rem;
                        color:#fff
                    }
                }
            }

            .eatingShop {
                width: 88%;
                padding-left: 1%;
                .eatingShopName {
                    width: 80%;
                    font-size: .16rem;
                    font-weight: bold;
                    color: #13161A;
                    text-align: left;
                    padding-bottom: .02rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
                .eatingShopDate {
                    font-size: .14rem;
                    color: #13161B;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #909399;
                    span{
                        color: #13161b;
                    }
                }
                .title{
                    display: flex;
                    justify-content: space-between;
                    .status{
                        font-size: .14rem;
                        white-space: nowrap;
                        text-align: right;
                    }
                }
                .bookingName{
                    display: flex;
                    color: #909399;
                    justify-content: space-between;
                    span{
                        color: #13161b;
                    }
                }
            }
        }
        .eatingHead:after,
        .mealBudget:after,
        .mealBtnRow:after {
            display: block;
            clear: both;
            content: "";
            visibility: hidden;
            height: 0;
        }

        .shopDetail,
        .mealBudget {
            border-bottom: 1px solid #E6E7E9;
            padding: .15rem 0;
            font-size: .12rem;
        }

        .shopDetail {
            .shopPhone {
                color:  var(--themeColor);
                font-weight: 500;
                text-align: left;
                font-size: .14rem;
            }

            .shopAddress {
                color: #909399;
                text-align: left;
                font-size: .12rem;
            }
        }

        .mealBudget {
            border-bottom: 1px solid #e6e7e9;
            padding: .1rem 0 .15rem 0;
            li {
                color: #909399;
                text-align: left;
                margin-top: .05rem;
            }

            .row-flex {
                display: flex;
                div {
                    width: 50%;
                    text-align: left;
                }
            }

            span {
                color: #13161a;
                margin-left: .02rem;
                font-size: 0.12rem;
            }

            .userPhone {
                color:  var(--themeColor);
                font-weight: 500;
            }
        }

        .mealBtnRow {
            width: 100%;
            padding-top: .12rem;
            display: flex;
            justify-content: flex-end;
            position: relative;
            border-top: 1px solid #E6E7E9;

            .btn-more {
                position: absolute;
                left: 0;
                bottom: 0.18rem;
            }
            .more-container {
                position: absolute;
                left: -0.1rem;
                top: 0.3rem;
                z-index: 1;
                background: #fff;
                box-shadow: 1px 1px 3px #e6e7e9;
                border-radius: 0.08rem;
                display: none;
                
                li {
                    height: 0.46rem;
                    line-height: 0.46rem;
                    width: 1.1rem;
                }
                li + li {
                    border-top: 1px solid #e6e7e9;
                }
            }
            .mealOperation {
                height: .25rem;
                line-height: .25rem;
                padding: 0 .05rem;
                min-width: .4rem;
                text-align: center;
                position: relative;
                margin-left: .06rem;
                border-radius: .05rem;
                font-size: 0.12rem;
                color: #90939A;
                border: 1px solid #E6E7E9;
                background: #fff;
                .mealOperationBtn {
                    width: .2rem !important;
                    height: .2rem !important;
                    position: absolute;
                    left: .15rem;
                    top: .15rem;
                }
            }
            div:last-child{
                background: var(--themeColor);
                color: #fff;
                border: 1px solid var(--themeColor);
            }
            .btnHighlight{
                background: var(--themeColor);
                color: #fff;
            }
            
        }
    }
}
</style>
