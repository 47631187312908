<template>
    <div>
        <div class="WXShadow" v-if="isShow && !isMiniPro">
            <img src="../assets/img/jiantou.png" />
            <div class="shadowtext">
                <div>温馨提示</div>
                <span>请在外部浏览器中打开</span>
                <a class="new_radius" @click="close">我知道了</a>
            </div>
        </div>

        <Popup v-model="isShow" v-if="isShow && isMiniPro" get-container="body">
            <div class="shadowtext2" v-if="isMiniPro">
                <div>温馨提示</div>
                <template >
                    <p> 请点击或长按下方链接，在浏览器中粘贴访问后继续完成操作 </p>
                    <input class="input" disabled type="text" :value="shortAlipayProto" />
                </template>
                <div class="btn">
                    <a class="new_radius" @click="close">我知道了</a>
                    <a class="new_radius" @click="copy()">复制链接</a>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Popup,Toast } from 'vant';
import { miceService } from "@/service/miceService.js";
export default {
    components:{Popup},
    name: "browserLoader",
    props:[
    ],
    data() {
        return {
            shortAlipayProto:'',
            isMiniPro: window.__wxjs_environment === "miniprogram",
            isShow: false,
            url: ''
        };
    },
    created() {

    },
    methods: {
        close(){
            this.isShow = false
            this.$emit('close')
        },
		copy(txt){
			let input = document.createElement('input')
			input.setAttribute('readonly','readonly')
			input.setAttribute('value',this.shortAlipayProto)
			input.style.top = '-999999999999px'
			input.style.left = '-999999999999px'
			input.style.opacity = 0
			input.style.position = 'fixed'
			document.body.appendChild(input)
			input.select()
			document.execCommand('Copy')
			Toast('复制成功')
			input.remove() 
		},
        openPop(url) {
            this.url = url
            let params = {
                url: url,
                generatePolicy: 0,
                context: { 
                    data: {}, 
                    cacheEntryOptions: { 
                        absoluteExpirationRelativeToNow: "0:20:0:0" 
                    } 
                }
            }
            console.log("====支付宝链接====", url);
			miceService.Shorten(params).then((res) => {
				if (res && res.success) {
					this.shortAlipayProto = res.content.substring(8);
                    this.isShow = true
				}
			});
        },
    },
};
</script>

<style lang="scss" scoped>

.WXShadow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 40px;
    z-index: 3;
    img {
        width: 36%;
        height: 25%;
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
    .shadowtext {
        font-size: 0.2rem;
        position: fixed;
        top: 2rem;
        width: 80%;
        background: #fff;
        left: 5%;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        border-radius: 0.1rem;
        box-shadow: 0 0 9px 0px #2a292966;
    div {
        font-weight: 600;
        margin-bottom: 0.2rem;
    }
    span {
        font-size: 0.16rem;
    }
    a {
        display: block;
        width: 60%;
        font-size: 0.16rem;
        background: var(--themeColor);
        color: #fff;
        padding: 0.12rem 0.48rem;
        border-radius: 50px;
        margin: 0 auto;
        margin-top: 0.5rem;
    }
    }
}
    .shadowtext2 {
        width: 80vw;
        font-size: 0.2rem;
        background: #fff;
        margin: 0 auto;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        box-shadow: 0 0 9px 0px #2a292966;
        div {
            font-weight: 600;
            margin-bottom: 0.2rem;
        }
        p {
            font-size: 0.14rem;
            text-align: left;
        }
        .input{
            text-align: left;
            width: calc(85vw - .3rem);
            border: 1px solid #e6e7e9; 
            border-radius: .04rem; 
            font-size: .14rem; 
            padding: 5px .06rem; 
            margin-top: 10px

        }
        .btn{
            display: flex;
            a {
                display: block;
                width: calc(45vw - .3rem);
                font-size: 0.14rem;
                background: var(--themeColor);
                color: #fff;
                padding: 0.12rem 0;
                margin: 0 auto;
                margin-top: 0.35rem;
                border-radius: 0.05rem;
            }
            a:first-child{
                border: 1px solid #e6e7e9;
                background: #fff;
                color: #90939a
            }
        }
    }
</style>
