import Vue from 'vue'
import VueRouter from 'vue-router'
const Index = () => import('../views/Index/Index.vue')
const OrderList = () => import('../views/OrderList/OrderList.vue')
const IframePage = () => import('../views/IframePage/IframePage.vue')
const AddMealDemand = () => import('../views/AddMealDemand/AddMealDemand.vue')
const AddTakeOutDemand = () => import('../views/AddTakeOutDemand/AddTakeOutDemand.vue')
const AddSuperMarket = () => import('../views/AddSuperMarket/Index.vue')
const OrderSettle = () => import('../views/OrderSettle/OrderSettle.vue')
const XMSPay = () => import('../views/XMSPay/XMSPay.vue')
const UploadInvoice = () => import('../views/UploadInvoice/UploadInvoice.vue')
const UploadQuotaInvocie = () => import('../views/UploadQuotaInvocie/UploadQuotaInvocie.vue')
const TakeOutOrderDetail = () => import('../views/TakeOutOrderDetail/TakeOutOrderDetail.vue')
const SuperMarketDetail = () => import('../views/SuperMarketDetail/SuperMarketDetail.vue')
const TakeOutEleDetail = () => import('../views/TakeOutEleDetail/index.vue')
const TakeoutGps = () => import('../views/TakeOutEleDetail/TakeoutGps.vue')
const MealOrderDetail = () => import('../views/MealOrderDetail/index.vue')
const UpdateFile = () => import('../views/UpdateFile/UpdateFile.vue')
const AddFile = () => import('../views/AddFile/AddFile.vue')
const PaySelect = () => import('../views/PaySelect/PaySelect.vue')
const PayQrcode = () => import('../views/PaySelect/PayQrcode.vue')
const ElePayDetail = () => import('../views/ElePay/ElePayDetail.vue')
const Common = () => import('../views/ElePay/Common.vue')
const ElePaySuccess = () => import('../views/ElePay/ElePaySuccess.vue')
const ElePayFail = () => import('../views/ElePay/ElePayFail.vue')
const EleProcess = () => import('../views/ElePay/EleProcess.vue')
const AlipayResult = () => import('../views/AlipayResult/AlipayResult.vue')
const ShopList = () => import('../views/Shop/ShopList.vue')
const gaoDeShopList = () => import('../views/Shop/gaoDeShopList.vue')
const ShopDetail = () => import('../views/Shop/ShopDetail.vue')
const ShopAddress = () => import('../views/Shop/ShopAddress.vue')
const SelectAddress = () => import('../views/Shop/SelectAddress.vue')
const Loading = () => import('../views/Loading/Loading.vue')
const Recommend = () => import('../views/Shop/recommend.vue')
const RecommendShop = () => import('../views/Shop/recommendShop.vue')
const AddRecommend = () => import('../views/Shop/addRecommend.vue')
const ChooseHospital = () => import('../views/Shop/chooseHospital.vue')
const AlipayAuthorize = () => import('../views/AliPay/alipayAuthorize.vue')
const GoAlipay = () => import('../views/AliPay/goAlipay.vue')
const Success = () => import('../views/UploadInvoice/success.vue')
const GetUserMedia = () => import('../views/getUserMedia.vue')
const BatchApproval = () => import('../views/batchApproval/batchApproval.vue')
const TestPage = () => import('../views/testPage.vue')

// 刷新页面报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'order',
    component: Index, // 列表
    backRouteName: 'ievent',
},
{
    path: '/orderList',
    name: 'orderList',
    component: OrderList, // 列表
    backRouteName: 'ievent'
},
{
    path: '/addMealDemand',
    name: 'addMealDemand',
    component: AddMealDemand, // 添加外出用餐需求
},
{
    path: '/iframePage',
    name: 'iframePage',
    component: IframePage, // 美团，饿了么H5外卖Iframe页面
},
{
    path: '/addTakeOutDemand',
    name: 'addTakeOutDemand',
    component: AddTakeOutDemand, // 添加外卖需求
},
{
    path: '/addSuperMarket',
    name: 'addSuperMarket',
    component: AddSuperMarket, // 添加外卖需求
},
{
    path: '/orderSettle',
    name: 'orderSettle',
    component: OrderSettle, // 支付信息
},
{
    path: '/xmsPay',
    name: 'xmsPay',
    component: XMSPay, // 小秘书支付码
},
{
    path: '/uploadInvoice',
    name: 'uploadInvoice',
    component: UploadInvoice, // 上传发票
},
{
    path: '/uploadQuotaInvocie',
    name: 'uploadQuotaInvocie',
    component: UploadQuotaInvocie, // 定额发票上传
},
{
    path: '/updateFile',
    name: 'updateFile',
    component: UpdateFile, // 更新文件
},
{
    path: '/addFile',
    name: 'addFile',
    component: AddFile, // 更新文件
},
{
    path: '/takeOutOrderDetail',
    name: 'takeOutOrderDetail',
    component: TakeOutOrderDetail, // 外卖详情
},
{
    path: '/superMarketDetail',
    name: 'superMarketDetail',
    component: SuperMarketDetail, // 商超详情
},
{
    path: '/takeOutEleDetail',
    name: 'takeOutEleDetail',
    component: TakeOutEleDetail, // 饿了么外卖详情
},
{
    path: '/takeoutGps',
    name: 'takeoutGps',
    component: TakeoutGps, // 饿了么外卖详情
},
{
    path: '/mealOrderDetail',
    name: 'mealOrderDetail',
    component: MealOrderDetail, // 外出用餐详情
},
{
    path: '/paySelect',
    name: 'paySelect',
    component: PaySelect, // 支付2.0 商户扫我 我扫商户
},
{
    path: '/payQrcode',
    name: 'payQrcode',
    component: PayQrcode, // 商户二维码
},
{
    path: '/elePayDetail',
    name: 'elePayDetail',
    component: ElePayDetail, // 饿了么到餐收银台
},
{
    path: '/elePaySuccess',
    name: 'elePaySuccess',
    component: ElePaySuccess, // 饿了么到餐收成功支付页
},
{
    path: '/elePayFail',
    name: 'elePayFail',
    component: ElePayFail, // 饿了么到餐收支付异常页
},
{
    path: '/eleProcess',
    name: 'eleProcess',
    component: EleProcess, // 饿了么到餐流程说明
},
{
    path: '/alipayResult',
    name: 'alipayResult',
    component: AlipayResult, // 饿了么到餐收成功支付页
},
{
    path: '/shopList',
    name: 'shopList',
    component: ShopList, // 餐厅库列表
},
{
    path: '/gaoDeShopList',
    name: 'gaoDeShopList',
    component: gaoDeShopList, // 高德餐厅库列表
},
{
    path: '/shopDetail',
    name: 'shopDetail',
    component: ShopDetail, // 餐厅详情
},
{
    path: '/shopAddress',
    name: 'shopAddress',
    component: ShopAddress, // 餐厅库地址
},
{
    path: '/selectAddress',
    name: 'selectAddress',
    component: SelectAddress, // 餐厅库选择地址
},
{
    path: '/loading',
    name: 'loading',
    component: Loading, // loading
},
{
    path: '/chooseHospital',
    name: 'chooseHospital',
    component: ChooseHospital, // 添加推荐餐厅
},
{
    path: '/recommend',
    name: 'recommend',
    component: Recommend, // 添加推荐餐厅
},
{
    path: '/recommendShop',
    name: 'recommendShop',
    component: RecommendShop, // 添加推荐餐厅
},
{
    path: '/addRecommend',
    name: 'addRecommend',
    component: AddRecommend, // 添加推荐餐厅
},
{
    path: '/goAlipay',
    name: 'goAlipay',
    component: GoAlipay, // 支付宝去支付
},
{
    path: '/alipayAuthorize',
    name: 'alipayAuthorize',
    component: AlipayAuthorize, // 支付宝授权页
},
{
    path: '/success',
    name: 'success',
    component: Success, // 协办会上传发票成功提示页
},
{
    path: '/common',
    name: 'common',
    component: Common, // 饿了么外卖支付流程
},
{
    path: '/getUserMedia',
    name: 'GetUserMedia',
    component: GetUserMedia, // 饿了么外卖支付流程
},
{
    path: '/testPage',
    name: 'testPage',
    component: TestPage, // 测试页面
},
{
    path: '/batchApproval',
    name: 'batchApproval',
    component: BatchApproval, // 饿了么外卖支付流程
},
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})


router.afterEach((to, from) => {
    if (process.env.VUE_APP_DEPLOY && process.env.VUE_APP_DEPLOY == "offline") {
        console.log(`%c====当前路由指向页面：${to.matched[0]?.components.default.__file}`, 'color: #3dd68c; font-size:18px;',"\n",to.matched[0].instances)
    }
    let route = {}
    routes.map((item) => {
        if (item.name == to.name) {
            route = item;
            return;
        }
    })
    /* global nativeJsBridge */
    nativeJsBridge.setRouter(router);
    const backRouteName = route.backRouteName ? route.backRouteName : 'root'
    nativeJsBridge.setbackRouteName(backRouteName)
})
export default router