import JiaoBiao from "@/components/OrderList/JiaoBiao.vue";
import { miceService } from "@/service/miceService.js";
import { baseUrl } from '@/service/baseUrl.js'
import mealSYT from '@/components/Meal/mealSYT.js'
import Reason from '@/components/Reason/index.vue'
import {
	SmartStorage
} from 'smart-core-util'
import { setCookie,getCookie,getAllCookies } from 'tiny-cookie'
import { formatDate } from '@/core'
import {
    mapGetters,
    mapMutations
} from 'vuex'
import { Checkbox, CheckboxGroup, Dialog   } from 'vant';
import ChangeShopPop from "@/components/ChangeShopPop.vue";
import getPositionFunction from "@/utils/getPositionFunction.js";
export default {
    name: "Item5Detail2",
    components: {
        JiaoBiao,
        Checkbox,
        CheckboxGroup,
        Reason,
        ChangeShopPop,
        [Dialog.Component.name]: Dialog.Component,
    },
    props: ["mealList","batchApproval"],
    mixins: [mealSYT,getPositionFunction],
    data() {
        return {
            result: [
            ],
            itemList:[],
            topayDialog: false,
            topayResult: []
        }
    },
    computed: {
        ...mapGetters([
            'event',
            'orderDetail',
            'memsg',
            'shop',
            'currentPosition',
            'cityAddress',
            'address'
        ])
    },
    created: function() {
    },
    mounted() {
        if(this.shop.fullName) {
            this.dochangeShop()
        }
    },
    methods: {
        ...mapMutations([
            'ORDER_DETAIL',
            'XMS_PAY_RESULT',
            'FRESH_LIST',
            'EXPENSE_ID',
            'ADDRESS',
            'SHOP',
            'FRESH_SHOP'
        ]),
        // 计算距离
        countDistance(distance) {
          let dis = parseInt(distance).toFixed(0);
          if (dis.length > 3) {
            return `${(dis / 1000).toFixed(1)}km`;
          } else {
            return dis + "m";
          }
        },
        checkAll(flage){
            this.$refs.checkboxGroup.toggleAll(flage)
        },
        checkChange(e){
           this.$emit('checkChange',{type:'item5',list:e})
        },
        computer_status(status){
            if([1,4,5,-1].includes(status)){
                return "colorStatus_" + `${JSON.stringify(status)}`
            }else{
                return "colorStatus_default"
            }
        },
        show(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "inline-block";
        },
        // 隐藏 remove-icon
        hide(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "none";
        },
        takeOutCheck(item, btn) {
            return new Promise((resolve, reject) => {
                let params = {
                    meta:{},
                    code:"meal_exception"
                }
                for(let i of btn.CheckData.Meta){
                    params.meta[i] = item[i]
                }
                miceService.common(btn.Checkurl, params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                })
            })
        },
        async handleClick(btn, item) {
            if (btn.forbidden) {
                this.toast(btn.msg);
                return;
            }
            this['EXPENSE_ID'](null);
            this['ORDER_DETAIL'](item);
            this.setXmsPayResultValue(item)
            if (item.expenseId) {
                this['EXPENSE_ID'](item.expenseId);
            }
            let buttonType = btn.processor;
            if (btn.Checkurl && process.env.VUE_APP_DEPLOY != 'offline') {
                await this.takeOutCheck(item, btn)
            }
            switch (buttonType) {
                case 'changeShop':
                    this.changeShop(item)
                    break
                //商宴通选择餐厅
                case 'selectShop':
                    this.selectSYT(item)
                    break;
                //编辑
                case "edit":
                    item.edit = true;
                    this['ORDER_DETAIL'](item);
                    this.$router.push({
                        path: "/addMealDemand"
                    });
                    break;
                    //删除
                case "remove":
                    this.$refs.remove.openPop();
                    break;
                    //去结账
                // 用餐反馈
                case "feedback":
                    this.reason = item.itsItemData.feedback_reason
                    this.$refs.reason.show(true);
                    break;
                case "toGoPay": //继续支付
                case "toPay":
                    if(['会引擎-huarun'].includes(this.event.tenant_name)){// update
                        this.topayDialog = true
                        return
                    }   
                    if(this.event.tenant_code != 'roche'){
                        await this.execute(item)
                    }
                    this.toPay()
                    break;
                    //上传发票
                case "uploadInvoice":
                    this.uploadInvoice(item);
                    break;
                    //支付进程 
                case "payProcess":
                    this.$router.push({ path: '/alipayResult'}) 
                    break;
                    //去付款
                case "openAlipay":
                    this.openAlipay(item);
                    break;
                    //查看详情
                case "viewDetails":
                    this.$router.push({
                        path: "/mealOrderDetail"
                    });
                    break;
                    //取消
                case "cancelOrder":
                    this.$refs.cancel.openPop();
                    break;
                    //确认费用
                case "confirmExpense":
                    this.$router.push({
                        path: "/orderSettle",
                        query: {
                            confirmExpense: true
                        }
                    });
                    break;
                    // 更新文件
                case "updateFile":
                case "uploadWater":
                    this.$router.push({
                        path: "/updateFile",
                        query: {
                            itemType: 5
                        }
                    });
                    break;
                //补充文件    
                case "addFile":
                    this.$router.push({
                        path: '/addFile'
                    })
                    break;
                    //饿了么退款
                case "refund":
                    this.$refs.refund.openPop();
                    break;
            }
        },
        topayDialogConfirm(action, done){
            if(action == 'cancel')done()
            if(this.topayResult.length == 2 && action == 'confirm'){
                done()
                this.toPay()
            }else{
                done(false)
            }
        },
        async closeWebview(reason){
            let params = {
                NodeCode: "SaveItemData",
                ContextDataKeys: {
                    jsondata: reason || '',
                    jsonpath: "$.feedback_reason",
                    ItemId: this.orderDetail.itemId,
                }
            }
            return new Promise((resolve, reject) => {
                miceService.ExecuteDeciderDapr(params).then(res => {
                    if (res && res.success) {
                        this.$refs.reason.show(false);
                        this['FRESH_LIST'](true)
                        resolve(params)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        execute(item) {
            let params = {
                NodeCode:"Meal_CheckCanOrder",
                ContextDataKeys:{
                    ProposalId: item.proposalId,
                    ItemId: item.itemId
                }
            }
            return new Promise((resolve, reject) => {
                miceService.execute(params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        remove() {
            var params = {
                itemId: this.orderDetail.itemId,
                isdelete: true
            }
            miceService.deleteMealItem(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        toPay() {
            this.$router.push({
                path: "/orderSettle"
            });
        },
        uploadInvoice(item) {
            let extOrderData = JSON.parse(item.extOrderData || "{}").eleOrderInfo || {};
            if(extOrderData.employeeFee > 10) {
                this.$refs.uploadInvoice.openPop();
            } else {
                this.$router.push({
                    path: "/uploadInvoice"
                });
            }
        },
        openAlipay(item) {
            let voucherInput = {
                shopId: item.itsItemData.shopId,
                shopName: item.itsItemData.shopName,
                expenseId: item.expenseId,
                proposalId: item.proposalId,
                settleAmount: item.originAmount,
                specificAmount: item.specificAmount,
                qty: item.qty1
            };
            this.$emit('open', voucherInput)
        },
        cancelOrder() {
            let item = this.orderDetail
            let temp = ((item.itsItemData || { payProfile:'' }).payProfile || {PayModes: []});
            let payMode = typeof(temp.PayModes) == 'object' ? temp.PayModes[0] : temp.PayModes;
            if(payMode==9 && item.extOrderId) {
                var formdata = new FormData();
                formdata.append("orderId",item.extOrderId);
                miceService.cancelorderSYT(formdata).then(res => {
                    this.cancelMeal(item)
                })
            } else {
                this.cancelMeal(item)
            }
        },
        cancelMeal(item) {
            var params = {
                itemId: item.itemId,
                proposalId: this.event.proposalId,
            }
            miceService.cancelMealItem(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        refund() {
            let params = {
                itemId: this.orderDetail.itemId
            }
            miceService.eleRefund(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        //更换餐厅
        async changeShop(item) {
            if(this.event.tenant_code == "roche" &&(this.event.typeDictTxt == "科室会" ||this.event.typeDictTxt == "院内会")){
                let address = {
                    latitude: this.event.nl || this.address.lat,
                    longitude: this.event.el || this.address.lng,
                    name: this.event.hospitalName || this.address.name,
                    city: this.address.city,
                }
                this['ADDRESS'](address)
            }
            item.edit = false
            item.changeShop = true
            this['ORDER_DETAIL'](item);
            let temp = ((item.itsItemData || {
                payProfile: ''
            }).payProfile || {
                PayModes: []
            });
            let payMode = typeof (temp.PayModes) == 'object' ? temp.PayModes[0] : temp.PayModes;
            if (payMode == 9) {
                // 商宴通
                this.selectSYT(item)
            } else {
                if(['novartis'].includes(this.event.tenant_code)) {
                    let itsItemData = item.itsItemData || {}
                    let address = {
                        name: itsItemData.hospitalName,
                        latitude: itsItemData.gps.latitude,
                        longitude: itsItemData.gps.longitude,
                        city: this.event.cityDictVal
                    }
                    // 其他类型餐厅
                    this["ADDRESS"](address)
                } else {
                    await this.getPosition()
                }
                this["FRESH_SHOP"](true)

                let optionalType = await this.getMealType()
                // 辉瑞会议期间不能使用美团
                let showMeituan = true
                if (
                    this.event.tenant_code == "pfizer"
                ) {
                    let nowDay =  new Date().getFullYear() + '-' +  (new Date().getMonth() + 1) + '-' +  new Date().getDate()
                    let itemDay =  new Date(item.dtStart).getFullYear() + '-' +  (new Date(item.dtStart).getMonth() + 1) + '-' +  new Date(item.dtStart).getDate()
                    if(nowDay == itemDay){
                        showMeituan = false;
                    }
                }
                // 罗氏更换餐厅不能选美团
                if(this.event.tenant_code == 'roche'){
                    showMeituan = false;
                }
                if(optionalType && showMeituan){
                    this.openShopArr(item)
                    return
                }
                this.$router.push('/shopList?refresh=true')
            }
        },
        async openShopArr(item){
			let mealCombineData = [];
			const sessionIdData = await this.saveCacheData()
            let shopUrl = SmartStorage.get('Uris').Uris['webapp-imeal'] + '/shopList?refresh=true&type=selectShop&changeShop=true&sessionId=' +  sessionIdData
            
            if (["pfizer"].includes(this.event.tenant_code)) {
                mealCombineData.push({
                selected:
                    "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/5ecf8dc0-0f07-4bb8-942d-562c7ebd7ab2",
                normal:
                    "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/97909875-c918-4a9a-a010-e4f8fa467c9b",
                url: shopUrl
                });
            } else {
                mealCombineData.push({
                    selected: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_focus.png',
                    normal: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_blur.png',
                    url: shopUrl,
                    icon:'icon-cantingku',
                    txt: '聚合餐厅'
                })
            }
			//获取美团地址`
            let meituanUrl = ''
			this.ItemId = item.itemId;
			let meituanParams ={
				ItemId: item.itemId,
				longitude: this.address.longitude,
				latitude: this.address.latitude,
			}
			const meituanRes = await this.MeituanLogin(meituanParams)
			if (meituanRes) {
				meituanUrl = meituanRes;
			}else{
				return
			}
            mealCombineData.push({
                selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                url: meituanUrl,
                icon:'icon-meituanwaimai',
                txt: '美团'
            })
            this.itemList = mealCombineData
            var u = navigator.userAgent;
            if(window.__wxjs_environment === "miniprogram" || /MicroMessenger/gi.test(u)){
                this.$refs.ChangeShopPop.open()
                return
            }
            let _this = this;
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData:{
                    loadUrl: 'mealCombineData',
                    cookieData: getAllCookies(),
                    mealCombineData: mealCombineData,
                },
                callback: (viewData) => {
                    if(!viewData || viewData =='' || viewData == {} || viewData == "{}"){
                        setTimeout(() =>{
                            _this.dochangeShop({fromIshop: false})
                        },800)
                        return
                    }
                    let postData = JSON.parse(viewData)
                    _this.dochangeShop(postData)
                }
            };
            this.iJsBridge.call(options);
        },
        handlechannel(item){
            if (item.txt == '美团') {
                this.$refs.browserLoader.openPop(item.url)
                return;
            }else{
                this.$router.push('/shopList?refresh=true')
            }
        },
        MeituanLogin(meituanParams){
            return new Promise((resolve,reject) => {
                miceService.meituanLogin(meituanParams).then(res => {
                    if(res && res.success){
                        resolve(res.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        // 获取外出用餐类型
        getMealType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: this.event.tenant_code || "crossevent",
                    mealType: "外出用餐"
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {   
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || {optionalType:[]}).optionalType.includes('美团'))
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        saveCacheData(){
			// 数据持久化保存数据
            let event = {
                ...this.event || {},
                mealCombineData: true
            }
			let eventDataParams = {
                Data: {
                    eventData: event || {},
                    token: getCookie('token'),
                    proposalId: this.event.proposalId,
                    tenant_id: getCookie('tenant_id'),
                    tenant_code: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    tenant: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    noBack: true,
                    paramCode: SmartStorage.get("paramCode")
                }
            }
            return new Promise((resolve,reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        selectSYT(item) {
            let time = item.dtStart
            time = time.replace(/T/g, " ")
            time = time.replace(/-/g, "/") 
            let date = new Date(time).getTime();
            let params = {
                orderType: item.itemName,
                reservationTime: date,
                predictCost: item.amount,
                a1OrderId: item.itemId,
                city: this.event.cityDictVal,
                jobnum: this.memsg.userId,
                name: this.memsg.dspName,
                phone: this.memsg.phoneNumber,
                dinerName: item.itsItemData.bookingName,
                dinerPhone: item.itsItemData.bookingPhoneNum,
                peopleNum: item.qty1 || 0,
            }
            let _this = this
            this.openMealSYT(params, () => {
                setTimeout(() => {
                    _this['FRESH_LIST'](true)
                }, 2000)
                // 更新列表
            })

        },
        async dochangeShop(data) {
            var orderDetail = this.orderDetail || {
                itsItemData: {}
            }
            var params = {
                itemId: orderDetail.itemId,
                bookingName: orderDetail.itsItemData.bookingName,
                bookingPhoneNum: orderDetail.itsItemData.bookingPhoneNum,
                oldShopRowId: orderDetail.itsItemData.shopId,
                gps: {
                    nl: this.shop.nl || '',
                    el: this.shop.el || ''
                }
            }
            let shopDetail = data || this.shop
            params.shopRowId = shopDetail.rowId;
            if(shopDetail.fromIshop){
                let shopParams = {
                    itemId: orderDetail.itemId,
                    ShopRowId: shopDetail.shopRowId,
                    bookingName: orderDetail.itsItemData.bookingName,
                    bookingPhoneNum: orderDetail.itsItemData.bookingPhoneNum,
                    SourceCode: shopDetail.sourceCode,
                    ShopName: shopDetail.fullName,
                    ShopImg: shopDetail.imgThumbnail,
                    ShopMobile: shopDetail.tel,
                    ShopAdress: shopDetail.address,
                    gps:{
                        nl:shopDetail.nl || '',
                        el:shopDetail.el || ''
                    }
                }
                params.sourceCode = shopDetail.sourceCode
                params.gps = {
                    nl:shopDetail.nl || '',
                    el:shopDetail.el || ''
                }
                await this.SaveChangeShop(shopParams)
            }else{
                params.SourceCode = "MeiTuan";
                params.ShopRowId = "1912055";
            }
            if(['crossevent'].includes(this.event.tenant_code)){
                params.isExtra = true
                
            }
            if(["pfizer"].includes(this.event.tenant_code)){
                await this.closeWebview(shopDetail.reason)
            }
            miceService.changeShop(params).then(res => {
                this['SHOP']({})
                if (res && res.success) {
                    // this.toast('餐厅更换成功')
                    this.$refs.chang.openPop();
                    this['FRESH_LIST'](true)
                }
            })
        },
        SaveChangeShop(eventDataParams){
            return new Promise((resolve,reject) => {
                miceService.SaveChangeShop(eventDataParams).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes)
                    }else{
                        this['SHOP']({})
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        //小秘书  
        setXmsPayResultValue(item) {
            this['XMS_PAY_RESULT']({})
            var itsItemData = item.itsItemData;
            var platform = itsItemData.platform;
            if (platform != 1) return;
            var status = item.status;
            var content = (itsItemData || { payResultInfo: {}, shopImg: '', shopName: '', })
            var payResultInfo = content.payResultInfo;
            var isCheckedPay = itsItemData.isCheckedPay;
            var showXiaoMiShuPayResult = false;
            if (status >= 4 && isCheckedPay === false) {
                showXiaoMiShuPayResult = true;
            }
            payResultInfo = {
                money: (payResultInfo || { money: '' }).money,
                payCheckCodeUrl: (payResultInfo || { payCheckCodeUrl: '' }).payCheckCodeUrl,
                payCode: (payResultInfo || { payCode: '' }).payCode
            }
            var shopInfo = {
                shopImg: content.shopImg,
                shopName: content.shopName
            }
            var xmsPayResult = {
                isNeedUploadInvoice: false, //查看详情进入小秘书支付结果不显示上传发票按钮
                payResultInfo: payResultInfo,
                shopInfo: shopInfo,
                showXiaoMiShuPayResult: showXiaoMiShuPayResult
            }
            this['XMS_PAY_RESULT'](xmsPayResult)
        }
    },

    filters: {
        qty1: value => {
            if (value && value >= 0) {
                return value + "人";
            } else {
                return "0人";
            }
        },
        amount: value => {
            if (value && value >= 0) {
                return parseFloat(value).toFixed(2);
            } else {
                return 0;
            }
        },
        dateTime: (value) => {
            if (value) {
                return value.substring(0, 10) + ' ' + value.substring(11, 16)
            }
        },
    }
};