import photoLogElement from './index.vue';
var instances = [],
	seed = 1;
import Vue from 'vue';
export default class photoLog {

	constructor(options) {
        this.photoLogConstructor = Vue.extend(photoLogElement);
    }

	render(options) {
		return this.createCustomElement(options);
	}

	createCustomElement(options) {
		const {
            onConfirm = () => {},
            onCancel = () => {}
		} = options;
		const instance = this.initphotoLog();
		document.body.appendChild(instance.$el);
		Vue.nextTick(() => {
			instance.render(options);
		});
		instances.push(instance);
		instance.$on("onConfirm", res => {
			this.destroyphotoLog(instance);
			if(typeof onConfirm === 'function') {
				onConfirm(res || '');
			}
        });
        instance.$on("onCancel", res => {
			this.destroyphotoLog(instance);
			if(typeof onCancel === 'function') {
				onCancel(res || '');
			}
		});
	}

	initphotoLog() {
		const id = `action${seed++}`;
		const instance = new this.photoLogConstructor({
			el: document.createElement('div')
		});
		instance.id = id;
		instance.zIndex += seed;
		return instance;
	}

	destroyphotoLog(instance) {
		if(!instance) {
			return;
		}
		setTimeout(() => {
            try {
				const index = instances.findIndex(inst => instance.id === inst.id);
				instances.splice(index, 1);
				instance.$destroy();
				document.body.removeChild(instance.$el);
            } catch (error) {
                console.log('remove style error');
            }
		}, 200)
	}

	close() {
		if(instances.length < 1) {
			return
		}
		const instance = instances[instances.length - 1];
		instance.isShow = false;
		this.destroyphotoLog(instance);
	}

}