import iDatePickerElement from './index.vue';
var instances = [],
	seed = 1;
export default class $iDatePicker {

	constructor(options) {
        const {
            Vue
        } = options || {};
        this.Vue = Vue;
        this.iDatePickerConstructor = this.Vue.extend(iDatePickerElement);
    }

	render(options) {
		return this.createCustomElement(Object.assign({
            visible: false, // 控制时间弹框打开、关闭，直接使用v-model
            closeModal: true, // 是否点击遮罩关闭
            zIndex: 30000 // 弹框层级
		}, options));
	}

	createCustomElement(options) {
		const {
            onConfirm = () => {},
            onCancel = () => {}
		} = options;
		const instance = this.initDatePicker();
		document.body.appendChild(instance.$el);
		this.Vue.nextTick(() => {
			instance.render(options);
		});
		instances.push(instance);
		instance.$on("onConfirm", res => {
			this.destroyDatePicker(instance);
			if(typeof onConfirm === 'function') {
				onConfirm(res || '');
			}
        });
        instance.$on("onCancel", res => {
			this.destroyDatePicker(instance);
			if(typeof onCancel === 'function') {
				onCancel(res || '');
			}
		});
	}

	initDatePicker() {
		const id = `action${seed++}`;
		const instance = new this.iDatePickerConstructor({
			el: document.createElement('div')
		});
		instance.id = id;
		instance.zIndex += seed;
		return instance;
	}

	destroyDatePicker(instance) {
		if(!instance) {
			return;
		}
		setTimeout(() => {
            try {
				const index = instances.findIndex(inst => instance.id === inst.id);
				instances.splice(index, 1);
				instance.$destroy();
				document.body.removeChild(instance.$el);
            } catch (error) {
                console.log('remove style error');
            }
		}, 200)
	}

	close() {
		if(instances.length < 1) {
			return
		}
		const instance = instances[instances.length - 1];
		instance.isShow = false;
		this.destroyDatePicker(instance);
	}

}