import { SmartStorage } from 'smart-core-util'
import { miceService } from '../service/miceService'
import { setCookie, getCookie } from 'tiny-cookie'

export default class CacheData {
    static install() {
        let url = window.location.search.substring(1);
        let urlParamsObj = {};
        if (url) {
            let urlParams = url.split('&');

            urlParams.forEach(item => {
                let temp = item.split('=');
                urlParamsObj[temp[0]] = temp[1];
            })
        }
        return new Promise(function (resolve) {
            // let sessionId = urlParamsObj.sessionId || SmartStorage.get('sessionId')
            let sessionId = urlParamsObj.sessionId

            if (sessionId) {
                SmartStorage.set("authMobile", null);//处理非协办会
                let params = {
                    "sessionId": sessionId
                }
                miceService.queryCacheData(params).then(response => {
                    CacheData.setData(response.content)
                    resolve()
                }).catch(error => {
                    resolve(error)
                })
            } else {
                console.log("====cache-data====会议信息|异步", SmartStorage.getAll())
                resolve()
            }
        })
    }
    // 设置缓存数据
    static setData(sessionData) {
        let expiresTimes = "31104000s";
        if (sessionData.container == "h5") {
            expiresTimes = "Session";
        }
        let config = { expires: expiresTimes, domain: window.idomain };
        setCookie("token", sessionData.token, config);
        setCookie("tenant_id", sessionData.tenant_id);
        setCookie("tenant", sessionData.tenant_code);
        SmartStorage.set("tenant_code", sessionData.tenant_code);
        SmartStorage.set("token", sessionData.token);

        if (sessionData.auth) {
            setCookie("token", sessionData.auth.token || sessionData.auth.access_token, config);
            setCookie("tenant_code", sessionData.auth.tenantCode || sessionData.auth.tenant_code);
            setCookie("tenant_id", sessionData.auth.tenantId || sessionData.auth.tenant_id);
            SmartStorage.set("tenant_code", sessionData.auth.tenantCode || sessionData.auth.tenant_code);
            SmartStorage.set("token", sessionData.auth.token || sessionData.auth.access_token);
        }

        if (sessionData.data) {
            let objKeyList = Object.keys(sessionData.data);
            objKeyList.map(item => {
                SmartStorage.set(item, sessionData.data[item]);
            });
            SmartStorage.set("proposalId", sessionData.data.eventData.proposalId);
        }

        let objKeyList = Object.keys(sessionData);
        objKeyList.map(item => {
            SmartStorage.set(item, sessionData[item]);
        });
    }
}