<template>
    <div class="shop-detail">
        <Header v-if="!mealRules.newUI" backType="blank" :backEvent="true" @backEvent="back" :svgColor="'#fff'" :back="true"></Header>
        <Header v-else backType="white" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText" style="font-size: 0.17rem">{{ shopDetail.fullName || "" }}</span>
        </Header>
        <div class="shop-detail-container">
            <div class="detail-img">
                <img :src="shopDetail.imgThumbnail || '@/assets/img/default.png'" @error="setErrorImg" />
            </div>
            <div class="detail-info">
                <div class="fullName">{{ shopDetail.fullName }}</div>
                <div class="start_price" v-if="mealRules.newUI">
                    <div class="star_item">
                        <span v-for="(start, index) in itemStart" :key="index">
                            <Svgs v-if="start == 'CLS_ON'" color="#FFCD30" name="iconxingji1"></Svgs>
                            <Svgs v-if="start == 'CLS_HALF'" name="iconpingjiahalf"></Svgs>
                            <Svgs v-if="start == 'CLS_OFF'" color="#F5F5F5" name="iconxingji1"></Svgs>
                        </span>
                    </div>

                    <div class="avgval_price">￥{{ shopDetail.priceAvgVal }}/人</div>
                </div>
                <div v-else class="avgval-price">￥{{ shopDetail.priceAvgVal }}/人</div>
                <div class="shop-score-content" v-if="!mealRules.newUI">
                    <div class="shop-score">
                        <div>{{ (shopDetail.starVal || 0).toFixed(1) }}</div>
                        <span>商家评分</span>
                    </div>
                    <div class="shop-score-detail">
                        <div class="score-row">
                            <span>环境</span>
                            <span style="color: #fecc04">{{ (shopDetail.scoreEnvirVal || 0).toFixed(1) }}</span>
                        </div>
                        <div class="score-row">
                            <span>服务</span>
                            <span style="color: #fecc04">{{ (shopDetail.scoreServiceVal || 0).toFixed(1) }}</span>
                        </div>
                        <div class="score-row">
                            <span>口味</span>
                            <span style="color: #fecc04">{{ (shopDetail.scoreTasteVal || 0).toFixed(1) }}</span>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <div class="card-title"><Svgs color="#90939A" name="icondianhua-"></Svgs>联系电话</div>
                        <p>{{ shopDetail.tel }}</p>
                    </div>
                    <a class="jiantou" :href="'tel:' + shopDetail.tel">
                        <Svgs color="#fff" name="iconjiantou2"></Svgs>
                    </a>
                </div>
                <div class="card" @click="goMap">
                    <div class="card-content">
                        <div class="card-title"><Svgs color="#90939A" name="icondizhi2"></Svgs>商家地址</div>
                        <p>{{ shopDetail.address || "暂无地址" }}</p>
                    </div>
                    <div class="jiantou" v-if="!mealRules.newUI">
                        <Svgs color="#fff" name="iconjiantou2"></Svgs>
                    </div>
                </div>
                <template v-if="isBusiness === 0">
                    <div class="sub-title">支付方式</div>
                    <div>支持支付宝【扫一扫】、【付款码】付款（请将支付宝版本更新至最新，否则企业支付可能无法成功）</div>
                </template>
                <div v-if="roomList">
                    <div class="sub-title">包房信息</div>
                    <RoomInfo :roomList='roomList.slice(0, 3)' :detailPage="true" @showMore="roomPage=true"></RoomInfo>
                </div>
                <div class="sub-title">商家信息</div>
                <div class="sub-text">营业时间</div>
                <div>{{ shopDetail.openingTime || "暂无营业时间" }}</div>
            </div>
        </div>
        <div v-if="!event.search">
            <Btn v-if="!mealRules.newUI" btnClass="normalBtn" @callBack="submit">
                <span slot="btnContent">确定</span>
            </Btn>
            <div v-else class="shopDetail_submit_btn" @click.stop="submit()">确定</div>
        </div>

        <MessageBox ref="confim" type="tips" :messageTxt="text" @onOk="ok(false)"></MessageBox>
        <Popup v-model="roomPage" v-if="roomPage" :round="true" :closeable="true" get-container="body" :lazy-render="true">
            <RoomInfo :roomList='roomList' :detailPage="false"></RoomInfo>
        </Popup>
        <Reason ref="reason" :changeShop="true" @closeWebview="closeWebview"></Reason>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import ChangeShopPop from "@/components/ChangeShopPop.vue";
import { mapGetters, mapMutations } from "vuex";
import { SmartStorage } from "smart-core-util";
import Reason from "@/components/Reason/index.vue";
import RoomInfo from "./RoomInfo.vue";
import { Base64 } from "js-base64";
import { Popup } from 'vant';
export default {
    components: {
        Reason,
        RoomInfo,
        Popup
    },
    data() {
        return {
            shopDetail: {},
            imgStyle: {},
            isBusiness: undefined,
            text: "请您自行联系餐厅预订餐位",
            fromGaoDeShop: false,
            reason: "",
            roomList: null,
            roomPage: false
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail", "mealRules"]),
        itemStart() {
            let result = [];
            let score = Math.floor(this.shopDetail.starVal * 2) / 2;
            let hasDecimal = score % 1 !== 0;
            let integer = Math.floor(score);
            for (let i = 0; i < integer; i++) {
                result.push("CLS_ON");
            }
            if (hasDecimal) {
                result.push("CLS_HALF");
            }
            while (result.length < 5) {
                result.push("CLS_OFF");
            }
            return result;
        },
    },
    mounted() {
        if (this.mealRules.shopSubmit) {
            this.text = this.mealRules.shopSubmit;
        }
        this.fromGaoDeShop = true;
        this.shopDetail = this.$store.state.meal.gaoDeShopDetail;
        this.merchantInfo()
        var img = new Image();
        img.src = this.shopDetail.imgThumbnail;
    },
    methods: {
        ...mapMutations(["SHOP"]),
        async merchantInfo(){
            let params = {
                merchantId: this.shopDetail.rowId
            };
            let api = '/api/jagent/merManage/merchantInfo'
            if(this.event.tenant_code == 'roche' || SmartStorage.get('tenant_code') == 'roche'){
                api = '/xchief/api/xprocess/ExecuteChain'
                params = {
                    code: "restaurant-box",
                    meta: {
                        merchantId: this.shopDetail.rowId
                    }
                }
            }
            let res = await miceService.common(api,params)
            console.log('===包间信息返回===',res);
            if (res && res.success &&res.content ) {
                try {
                    this.roomList = JSON.parse(res.content.roomInfo)
                } catch (error) {
                    console.log("解析餐厅包房失败", res.content.roomInfo)
                }
            }
        },
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/default.png");
            img.οnerrοr = null;
            this.imgStyle = {
                width: "100%",
            };
        },
        getDetail() {
            let params = {
                tenantId: this.event.tenantId,
                rowId: this.$route.query.rowId,
            };
            miceService.getShopDetail(params).then((res) => {
                this.shopDetail = res.data;
                if (this.$route.query.platform == 6) {
                    this.getEleTel();
                }
                var img = new Image();

                // 改变图片的src
                img.src = res.data.imgThumbnail;
                let _this = this;
                img.onload = function () {
                    if (img.width / img.height > 355 / 255) {
                        _this.imgStyle = {
                            height: "100%",
                            marginLeft: "-25%",
                        };
                    } else {
                        _this.imgStyle = {
                            width: "100%",
                            marginTop: "-12%",
                        };
                    }
                };
            });
        },
        getEleTel() {
            let params = {
                rowId: this.$route.query.rowId,
            };
            miceService.getEleDetail(params).then((res) => {
                if (res.success) {
                    this.shopDetail.tel = res.content.phones[0] || res.content.phones;
                    this.isBusiness = res.content.isBusiness;
                }
            });
        },
        back() {
            this.$router.back();
        },
        goMap() {
            this.$router.push({
                name: "shopAddress",
                query: {
                    N: this.shopDetail.fullName,
                    X: this.shopDetail.el,
                    Y: this.shopDetail.nl,
                },
            });
        },
        async submit() {
            if (SmartStorage.get("recommend")) {
                this["SHOP"](this.shopDetail);
                this.$router.push({
                    path: "/addRecommend",
                });
                return;
            }

            if (this.event.search) {
                if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite");
                } else {
                    this.iJsBridge.close();
                }
            } else {
                this.$refs.confim.openPop();
            }
        },
        closeWebview(reason) {
            this.reason = reason;
            this.ok(true);
        },

        async ok(flage) {
            if ((this.orderDetail.changeShop || SmartStorage.get("changeShop")) && !flage && this.event.tenant_code == "pfizer" && this.event.statusTxt == "会议执行") {
                this.$refs.reason.show(true);
                return;
            }
            if ((this.orderDetail.changeShop || SmartStorage.get("changeShop")) && !this.reason && this.event.tenant_code == "pfizer" && this.event.statusTxt == "会议执行") {
                return;
            }
            let sourceCode = "";
            let shopData = this.shopDetail;
            let payType = 0;
            let platform = shopData.platform.toString();
            switch (platform) {
                case "0":
                    sourceCode = "Dianping";
                    break;
                case "1":
                    sourceCode = "XiaoMiShu";
                    payType = 0;
                    break;
                case "2":
                    sourceCode = "KouBei";
                    break;
                case "6":
                    sourceCode = "ELE";
                    payType = 1;
                    break;
                case "7":
                    sourceCode = "ShangWuKa";
                    payType = 1;
                    break;
                case "11":
                    sourceCode = "Amap";
                    payType = 1;
                    break;
                case "20":
                    sourceCode = "SmartMice";
                    payType = 1;
                    break;
            }
            if (shopData.hasOwnProperty("payProfile")) {
                delete shopData.payProfile;
            }
            // 高德保存餐厅
            if (this.$route.query.from == "gaoDeShopList" || shopData.dataSourceCode == "GaoDeSource") {
                let resShop = await this.saveShop();
                shopData = resShop.data;
                shopData.isExtra = true;
            }
            Object.assign(shopData, {
                isExtra: true,
                shopRowId: shopData.rowId,
                shopId: shopData.id,
                sourceCode: sourceCode,
                payType: payType,
                rowId: shopData.rowId,
                imgThumbnail: shopData.imgThumbnail,
                shopName: shopData.fullName,
                fromIshop: true,
                reason: this.reason,
                nl: shopData.nl,
                el: shopData.el,
            });
            this.$refs.reason.show(false);
            if (this.$route.query.type == "selectShop") {
                if (
                    ['roche'].includes(this.event.tenant_code)
                ) {
                    shopData = await this.saveCacheData(shopData)
                }
                if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", shopData);
                } else {
                    this.iJsBridge.call({
                        method: "SMGeneral.setResultToNative",
                        postData: shopData,
                    });
                    this.iJsBridge.close();
                }
                return;
            }

            this["SHOP"](shopData);
            if (this.orderDetail.itemId && !this.orderDetail.edit) {
                this.$router.push("/orderList");
            } else {
                this.$router.push("/addMealDemand?shopType=平台到餐");
            }
        },
        saveCacheData(shopData){
			// 数据持久化保存数据
			let params = {
                Data: shopData
            }
            return new Promise((resolve,reject) => {
                miceService.saveCacheData(params).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        // 高德保存餐厅
        saveShop() {
            let params = this.shopDetail;
            if (["pfizer"].includes(SmartStorage.get("tenant_code"))) {
                params = {
                    ...this.shopDetail,
                    AutoTenantMap: false,
                };
            }
            return new Promise((resolve) =>
                miceService.saveGaoDeshop(params).then((res) => {
                    if (res.success) {
                        resolve(res);
                    }
                })
            );
        },
    },
};
</script>
<style lang="scss">
.shop-detail {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .shop-detail-container {
        div {
            text-align: left;
        }
    }
    .shop-detail-container {
        position: absolute;
        width: 100%;
        overflow: scroll;
        top: 0;
        bottom: 0;
    }
    .backBtn {
        height: 0.3rem;
        width: 0.3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main {
        text-align: center;
    }
    .normalBtn {
        margin: 0.3rem 0.25rem 0.3rem 0.25rem;
        position: absolute;
        bottom: 0;
    }
    .detail-img {
        width: 100%;
        overflow: hidden;
        margin-top: 0.4rem;
        height: 2rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .detail-info {
        padding: 0.25rem 2.5%;
        padding-bottom: 1rem;
        box-sizing: border-box;
        .fullName {
            text-align: left;
            font-size: 0.16rem;
            font-weight: bold;
        }
        .avgval-price {
            color: var(--themeColor);
            text-align: left;
        }
    }
    .shop-score-content {
        display: flex;
        margin: 0.26rem 0;
        color: #90939a;
        text-align: left;

        span {
            font-size: 0.12rem;
            text-align: left;
        }

        .shop-score {
            width: 30%;
            div {
                font-size: 0.26rem;
                color: #fecc04;
            }
        }
        .shop-score-detail {
            width: 70%;

            span {
                margin-right: 0.1rem;
            }
        }
    }
    .card {
        padding: 0.15rem 0.15rem;
        box-sizing: border-box;
        border-radius: 0.13rem;
        box-shadow: 1px 1px 0.15rem #f5f5f5;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .card-content {
            width: calc(100% - 0.6rem);
        }

        .card-title {
            height: 0.24rem;
            line-height: 0.24rem;
            position: relative;
            padding-left: 0.3rem;
            color: #90939a;
            text-align: left;
            .icon {
                position: absolute;
                left: 0;
            }
        }

        .jiantou {
            position: relative;
            height: 0.44rem;
            width: 0.44rem;
            border-radius: 50%;
            background: var(--themeColor);

            .icon {
                position: absolute;
                top: 0.11rem;
                right: 0.12rem;
            }
        }

        p {
            margin-top: 0.05rem;
            text-align: left;
        }
    }
    .card + .card {
        margin-top: 0.18rem;
    }
    .sub-title {
        margin: 0.28rem 0 0.15rem 0;
        font-size: 0.16rem;
        text-align: left;
    }
    .sub-text {
        color: #909399;
        margin-bottom: 0.1rem;
        text-align: left;
    }
    .shopDetail_submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
        background: var(--themeColor);
        color: #ffffff;
        text-align: center;
        font-size: 0.16rem;
    }
    .start_price {
        display: flex;
        justify-content: space-between;
        .avgval_price {
            font-weight: bold;
        }
    }
}
</style>
