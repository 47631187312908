<template>
    <div style="background: #ffffff">
        <div class="noalipay_content blueColor">
            <Header backType="white" @backEvent="closePay()" :back="showClose" :backEvent="true">
                <span slot="headText">支付流程注意事项</span>
            </Header>
            <div class="txt">
                <template v-if="event.tenant_code == 'roche'">
                    <p class="alipay_tip">请使用支付宝开通授权：员工确认<span class="fontWeight">因公消费</span>，由&lt;<span class="fontWeight">智会云</span>&gt;<span class="fontWeight">实时扣款</span>支付，<span class="fontWeight">无需</span>个人垫支。</p>
                </template>

                <template v-else>
                    <p><span v-if="!['会引擎-灵北'].includes(event.tenant_name)">1、</span>请使用支付宝<span class="fontWeight">【因公扫码付】</span>或者<span class="fontWeight">【支付码】</span>进行支付。</p>
                </template>

                <div class="imgDiv">
                    <img class="img" src="../../assets/img/aliapyTips1.png" alt="" />
                </div>
                <p>
                    <span v-if="!['会引擎-灵北'].includes(event.tenant_name)">2、</span>支付渠道<span class="fontWeight">“找单位代付{{ mealRules.tenant_name == "会引擎-协办会-辉瑞" ? "（智慧科技北京）" : "（智会云）" }}”</span>
                </p>
                <div class="imgDiv">
                    <img class="img" src="../../assets/img/aliapyTips2.png" alt="" />
                </div>

                <div class="button_all">
                    <div class="type whiteColor" v-if="showClose" @click="closePay()">关闭</div>
                    <div class="type blueColor colorFFF" @click="submint()">去支付</div>
                </div>
            </div>
        </div>
        <MessageBox ref="mesg" type="tips" cancelTxt="联系客服" okTxt="已支付完成" showCloseBtn="true" @onCancel="ihtml.telCall('01056304858')" @onOk="mesgOk" messageTxt="是否支付完成？如遇支付问题,请致电中智客服"></MessageBox>
        <BrowserLoader ref="browserLoader" @close="know" />
        <div class="WXShadow" v-if="wxShow">
            <img v-if="!isMiniPro" src="../../assets/img/jiantou.png" />
            <div class="shadowtext">
                <div>温馨提示</div>

                <template v-if="isMiniPro">
                    <span> 复制下方链接，前往浏览器粘贴访问 </span>
                    <input type="text" :value="shortAlipayProto" style="border: 1px solid gray; background: #c4c4c4; border-radius: 4px; font-size: 14px; width: 220px; padding: 5px 0; margin-top: 10px" />
                </template>
                <template v-else>
                    <span>请在外部浏览器中打开</span>
                </template>

                <a @click="wxShow = false">我知道了</a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { miceService } from "@/service/miceService.js";
import electrinicFence from "@/utils/electrinicFence.js";
import { SmartStorage } from "smart-core-util";
import { Indicator } from "mint-ui";
export default {
    mixins: [electrinicFence],
    name: "goAlipay",
    data() {
        return {
            openUrl: null,
            wxShow: false,
            isWx: false,
            isMiniPro: window.__wxjs_environment === "miniprogram",
            shortAlipayProto: "",
            params: null,
        };
    },
    computed: {
        ...mapGetters(["orderDetail", "mealRules", "event"]),
        showClose(){
            if(SmartStorage.get("goAlipay") && !this.isWx){
                return false
            }else{
                return true
            }
        }
    },
    async mounted() {
        console.log('=-=-window.__wxjs_environment=-=-',window.__wxjs_environment);
        var u = navigator.userAgent;
        if (/MicroMessenger/gi.test(u)) {
            this.isWx = true;
        }
        if(SmartStorage.get("goAlipay")){
            let aliPayParams = await this.queryCacheData()
            this.openUrl = aliPayParams.aliPayUrl
        }else{
            this.openUrl = this.orderDetail.aliPayUrl
        }
    },
    methods: {
        know(){
            this.$refs.mesg.openPop();
        },
        closePay() {
            if(SmartStorage.get("isH5")){
                this.$router.go(-1);
                return
            }
            if(SmartStorage.get('authorize')){
                this.iJsBridge.close();
                return
            }else{
                this.$router.push({ path: "/orderList" });
            }
        },
        mesgOk() {
            let that = this;
            Indicator.open({
                text: "查询中...",
                spinnerType: "fading-circle",
            });
            let params = {
                businessId: this.orderDetail.itemId,
            };
            setTimeout(function () {
                Indicator.close();
                miceService.CheckPayInfo(params).then((res) => {
                    console.log("res", res);
                    if (res && res.success) {
                        if (res.code != 1001) {
                            that.$router.push({ path: "/elePaySuccess" });
                            return;
                        }
                        that.$refs.mesg.openPop();
                    }
                });
            }, 2000);
        },
        queryCacheData() {
            let sessionIdParams = {
                sessionId: this.$route.query.alipaySessionId || SmartStorage.get("alipaySessionId"),
            };
            return new Promise((resolve, reject) => {
                miceService
                    .queryCacheData(sessionIdParams)
                    .then((sessionRes) => {
                        if (sessionRes && sessionRes.success) {
                            resolve(sessionRes.content);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async submint() {
            if (!SmartStorage.get("goAlipay")) {
                await this.getLocationFailed();
            }
            if (this.isWx) {
                let aliPayParams = await this.queryCacheData();
                this.openUrl = aliPayParams.aliPayUrl || this.orderDetail.aliPayUrl;
                this.$refs.browserLoader.openPop(this.openUrl)
                return;
            }
            console.log('=-=-=openUrl-=-=-=-',this.openUrl);
            window.location.href = this.openUrl;
            this.$refs.mesg.openPop();
            if (SmartStorage.get("goAlipay")) {
                return;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.noalipay_content {
    height: 100%;
    overflow: scroll;
    position: fixed;
    .txt {
        padding: 20px 10px;
        font-size: 16px;
        z-index: 10;
        border-radius: 10px;
        .imgDiv {
            width: 50%;
            margin: 15px auto;
            height: auto;
            .img {
                width: 100%;
            }
        }
        .grayColor {
            background: gray;
        }
        .blueColor {
            background: var(--themeColor);
        }
        .whiteColor {
            background: #fff;
        }
        .colorFFF {
            color: #fff;
        }
        .wid70 {
            width: 70% !important;
        }
        .button_all {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            position: fixed;
            bottom: 0.2rem;
            .type {
                width: 45%;
                height: 0.4rem;
                border: 0.5px solid #c1c1c1;
                margin: 0 auto;
                border-radius: 0.05rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                .logo {
                    width: 2em;
                    height: 2em;
                }
            }
        }
    }
    .fontWeight {
        font-weight: 700;
    }
}
.WXShadow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 40px;
    z-index: 2100;
    img {
        width: 36%;
        height: 25%;
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
    .shadowtext {
        font-size: 0.2rem;
        position: fixed;
        top: 2rem;
        width: 80%;
        background: #fff;
        left: 5%;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        border-radius: 0.1rem;
        box-shadow: 0 0 9px 0px #2a292966;
        div {
            font-weight: 600;
            margin-bottom: 0.2rem;
        }
        span {
            font-size: 0.16rem;
        }
        a {
            display: block;
            width: 60%;
            font-size: 0.16rem;
            background: var(--themeColor);
            color: #fff;
            padding: 0.12rem 0.48rem;
            border-radius: 50px;
            margin: 0 auto;
            margin-top: 0.5rem;
        }
    }
}
</style>
