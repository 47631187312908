export const mealAddDemandXianXia = {
    'shopAddress': {
        required: true,
        description: '餐厅地址不能为空'        
    }
}

const mealAddDemandEventcoolBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1': {
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'        
    },
    'amount': {
        required: true,
        description: '请填写正确预计金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额'        
    },    
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'        
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        expression: function(m){
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(m.dtStart).getTime()
            var dtEndTime = new Date(m.dtEnd).getTime()
            if(dtStartTime>= date && dtEndTime<= date+86400000 && currentHours>20)
            return true
        },
        expressionMsg:'当天用餐20:00以后不能添加'                 
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空'
    },
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    } 
}

const mealAddDemandNovartisBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'hospitalId':{
        required: true,
        description: '医院不能为空'
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'        
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        expression: function(m){
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(m.dtStart).getTime()
            var dtEndTime = new Date(m.dtEnd).getTime()
            if(dtStartTime>= date && dtEndTime<= date+86400000 && currentHours>20)
            return true
        },
        expressionMsg:'当天用餐20:00以后不能添加'                 
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空'
    },
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}

const mealAddDemandCrosseventBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1':{
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确用餐人数',
    },
    'amount':{
        required: true,
        description: '预计金额不能为空',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
        // expression: function(m) {
        //     return m.amount > 300*m.qty1
        // },
        // expressionMsg: '外出用餐人均不能超300元'
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'        
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        // expression: function(m){
        //     var currentDate = new Date();
        //     var currentYear  = currentDate.getFullYear()
        //     var currentMonth = currentDate.getMonth()
        //     var currentDay = currentDate.getDate()
        //     var currentHours = currentDate.getHours()
        //     var date = new Date(currentYear, currentMonth, currentDay).getTime()
        //     var dtStartTime = new Date(m.dtStart.replace(/-/g, "/")).getTime()
        //     var flag = new Date().toDateString() === new Date(m.dtStart.replace(/-/g, "/")).toDateString()
        //     if(flag && dtStartTime>= date && currentHours>19)
        //     return true
        // },
        // expressionMsg:'当天用餐20:00以后不能添加'                 
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空',
    }, 
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}
const mealAddDemandlbBase = {
    'budgetNum': {
        required: true,
        description: '用餐编号不能为空'
    },
    'qty1':{
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确用餐人数',
    },
    'amount':{
        required: true,
        description: '预计金额不能为空',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'        
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',             
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空',
    }, 
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}
//拜访用餐
const mealAddDemandEmployeeBase = {
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空'    
    },
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1':{
        required: true,
        description: '请填写正确预计人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确预计人数',
    },
    'amount':{
        required: true,
        description: '预计金额不能为空',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空',
    }, 
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}
const mealAddDemandDsclBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1':{
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确用餐人数',
    },
    'amount':{
        required: true,
        description: '预计金额不能为空',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
        expression: function(m) {
            return m.amount == 0
        },
        expressionMsg: '预计金额不能为0'
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'        
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        expression: function(m){
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(m.dtStart.replace(/-/g, "/")).getTime()
            var flag = new Date().toDateString() === new Date(m.dtStart.replace(/-/g, "/")).toDateString()
            if(flag && dtStartTime>= date && currentHours>19)
            return true
        },
        expressionMsg:'当天用餐20:00以后不能添加'                 
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空',
        expression: function(m){
            if (m.bookingName.replace(/^\s+|\s+$/g, "").length == 0) {
                return true
            }
        },
        expressionMsg:'订餐人不能为空'      
    }, 
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}

const mealAddDemandPfizerBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1': {
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'
    },
    'amount': {
        required: true,
        description: '请填写正确预计金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
        expression: function(m) {
            return m.amount > 300*m.qty1
        },
        expressionMsg: '外出用餐人均不能超300元'
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        expression: function(m){
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(m.dtStart.replace(/-/g, "/")).getTime()
            var dtEndTime = new Date(m.dtEnd.replace(/-/g, "/")).getTime()
            let flag = new Date().toDateString() === new Date(m.dtStart.replace(/-/g, "/")).toDateString()
            if(['同院会'].includes(m.typeDictTxt)) {
                if(flag && dtStartTime>= date && currentHours>19)
                return true
            } else {
                if(dtStartTime>= date && dtEndTime<= date+86400000 && currentHours>20)
                return true
            }
            
        },
        expressionMsg:'当天用餐20:00以后不能添加'
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空'
    },
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}
const mealAddDemandRocheBase = {
    'itemName': {
        required: true,
        description: '用餐类型不能为空'
    },
    'mealType': {
        required: true,
        description: '用餐形式不能为空'
    },
    'qty1': {
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^\+?[1-9][0-9]*$/,
        regExpMsg:'请填写正确人数'
    },
    'amount': {
        required: true,
        description: '请填写正确预计金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额',
        expression: function(m) {
            return m.amount > 300*m.qty1
        },
        expressionMsg: '外出用餐人均不能超300元'
    },
    'dtStart': {
        required: true,
        description: '用餐开始时间不能为空',
        expression: function(m){
            let dtStart = m.dtStart.replace(/-/g, "/"),
            dtEnd = m.dtEnd.replace(/-/g, "/");
            return new Date(dtStart) >= new Date(dtEnd)
        },
        expressionMsg:'用餐结束时间不能小于或等于用餐开始时间'
    },
    'dtEnd': {
        required: true,
        description: '用餐结束时间不能为空',
        expression: function(m){
            var currentDate = new Date();
            var currentYear  = currentDate.getFullYear()
            var currentMonth = currentDate.getMonth()
            var currentDay = currentDate.getDate()
            var currentHours = currentDate.getHours()
            var date = new Date(currentYear, currentMonth, currentDay).getTime()
            var dtStartTime = new Date(m.dtStart.replace(/-/g, "/")).getTime()
            var dtEndTime = new Date(m.dtEnd.replace(/-/g, "/")).getTime()
            let flag = new Date().toDateString() === new Date(m.dtStart.replace(/-/g, "/")).toDateString()
            if(['同院会'].includes(m.typeDictTxt)) {
                if(flag && dtStartTime>= date && currentHours>19)
                return true
            } else {
                if(dtStartTime>= date && dtEndTime<= date+86400000 && currentHours>20)
                return true
            }
            
        },
        expressionMsg:'当天用餐20:00以后不能添加'
    },
    'bookingName': {
        required: true,
        description: '订餐人不能为空'
    },
    'bookingPhoneNum': {
        required: true,
        description: '请填写正确的手机号码',
        regExp: /^[1][0-9]{10}$/,
        regExpMsg:'请填写正确的手机号码'
    },
    'shopName': {
        required: true,
        description: '餐厅名称不能为空'
    }
}

export const mealAddDemand = {
    novartisBase: mealAddDemandNovartisBase,
    eventcoolBase: mealAddDemandEventcoolBase,
    dsclBase: mealAddDemandDsclBase,
    crosseventBase: mealAddDemandCrosseventBase,
    pfizerBase: mealAddDemandPfizerBase,
    rocheBase: mealAddDemandRocheBase,
    lbBase: mealAddDemandlbBase,
    employeeBase: mealAddDemandEmployeeBase
}