<template>
    <div>
        <Header backType="white" :backEvent="true" @backEvent="backPath" :back="true">
            <span slot="headText">收银台</span>
        </Header>
        <div v-if="!mealRules.newUI">
            <div class="elePayCard">
                <div class="eatingHead">
                    <div class="eatingImg">
                        <img :src="detail.shopImg || '../../assets/img/logo.png'">
                    </div>
                    <div class="eatingShop">
                        <p class="eatingShopName">{{detail.shopName}}</p>
                        <p class="eatingShopDate">{{(detail.dtStart || '').replace('T', ' ')}} - {{(detail.dtEnd || '').replace('T', ' ')}}</p>
                    </div>
                    <JiaoBiao v-if="orderDetail.itemId" :item="orderDetail" />
                </div>
                <div class="shopDetail">
                    <div class="shopPhone" @click="ihtml.telCall(detail.shopMobile)">{{detail.shopMobile}}</div>
                    <div class="shopAddress">{{detail.shopAddress}}</div>
                </div>
                <ul class="mealBudget">
                    <li>
                        实际人数
                        <span>{{orderDetail.qty}}人</span>
                    </li>
                    <li>
                        订餐人姓名
                        <span>{{detail.bookingName}}</span>
                    </li>
                    <li>
                        订餐人电话
                        <span class="userPhone" @click="ihtml.telCall(detail.bookingMobile)">{{detail.bookingMobile}}</span>
                    </li>
                </ul>
                <div class="mealBtnRow">
                    实际消费金额
                    <span>￥ {{parseFloat(orderDetail.orderAmount || 0).toFixed(2)}}</span>
                </div>
            </div>
            <div>
                <div class="ele-tip">
                    <span>* </span>
                    如遇支付问题,请致电
                    <span @click="ihtml.telCall('01056304858')" v-html="phoneText"></span>
                </div>
            </div>
            <div v-if="!submitFlag && orderDetail.isPlusPay" class="elePayGroup">
                <Btn btnClass="elePayBtn" @callBack="backPath">
                    <span slot="btnContent">修改金额</span>
                </Btn>
                <Btn btnClass="elePayBtn" @callBack="goPay">
                    <span slot="btnContent">立即支付</span>
                </Btn>
            </div>
            <div v-if="!submitFlag && !orderDetail.isPlusPay" class="elePayGroup">
                <Btn btnClass="elePayBtn" @callBack="backPath">
                    <span slot="btnContent">修改金额</span>
                </Btn>
                <Btn btnClass="elePayBtn" @callBack="goPay">
                    <span slot="btnContent">支付宝付款</span>
                </Btn>
            </div>
            <div v-if="submitFlag" class="elePayGroup">
                <Btn btnClass="elePayBtn" @callBack="refresh">
                    <span slot="btnContent">刷新</span>
                </Btn>
                <Btn btnClass="elePayBtn" @callBack="resetSubmint">
                    <span slot="btnContent">重新支付</span>
                </Btn>
            </div>
        </div>
        <div v-else class="new_elePay">
            <div class="pay_amount">
                ￥ <span>{{parseFloat(orderDetail.orderAmount || 0).toFixed(2)}}</span>
            </div>
            <div class="pay_shopName">
                {{ detail.shopName }}
            </div>
            <div class="bg_gray"></div>
            <div class="pay_type">
                <svg class="icon" aria-hidden="true">
                    <use fill="#027AFF"  :xlink:href="'#iconzhifubaofang'"></use>
                </svg>
                支付宝
            </div>
            <div class="bg_gray"></div>
            <div class="pat_phone">
                如遇支付问题,请致电
                <span @click="ihtml.telCall('01056304858')" v-html="phoneText"></span>
            </div>
            <div v-if="!submitFlag && orderDetail.isPlusPay" class="elePayGroup">
                <div class="elePayBtn btn_gray" @click="backPath">
                    修改金额
                </div>
                <div class="elePayBtn" @click="goPay">
                    支付宝付款
                </div>
            </div>
            <div v-if="!submitFlag && !orderDetail.isPlusPay" class="elePayGroup">
                <div class="elePayBtn btn_gray" @click="backPath">
                    修改金额
                </div>
                <div class="elePayBtn" @click="goPay">
                    支付宝付款
                </div>
            </div>
            <div v-if="submitFlag" class="elePayGroup">
                <div class="elePayBtn btn_gray" @click="refresh">
                    刷新
                </div>
                <div class="elePayBtn" @click="resetSubmint">
                    重新支付
                </div>
            </div>
        </div>
        <cover @close='coverShow = false' v-if="coverShow"></cover>  
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import JiaoBiao from "@/components/OrderList/JiaoBiao.vue";
import { miceService } from "@/service/miceService.js";
import { Colors } from '@/utils/colors.js'
import { phoneList } from '@/config/phone/index.js'
import electrinicFence from "@/utils/electrinicFence.js";
import {
	SmartStorage
} from 'smart-core-util';
import { Toast } from 'mint-ui';
import cover from "@/components/cover"
export default {
    mixins: [electrinicFence],
    components: {
        JiaoBiao
    },
    data() {
        return {
            detail: {},
            submitFlag: false,
            tip: false,
            navBackgroundColor: {},
            coverShow: false
        }
    },
    computed: {
        ...mapGetters(["orderDetail", "memsg", "event", "mealRules"]),
        phoneText: {
            get: function() {
                let tenant = this.event.tenant_code || 'pfizer';
                return phoneList[tenant];  
            }    
        }
    },
    mounted() {
        this.navBackgroundColor = Colors
    },
    async created() {
        this.submitFlag = this.$route.query.submitFlag == "true" ? true : false
        await this.nativeGet(this.setSubmitFlag);
        let _this = this
        setTimeout(() => {
            _this.getDetail();
        }, 1)
    },
    methods: {
        ...mapMutations(["ORDER_DETAIL", "MEMSG", "EVENT"]),
        backPath() {
            this.$router.push({ path: '/orderSettle' });
        },
        async setSubmitFlag(item) {
            this.tip = item == "true" ? true : false
            if(this.$route.query.tip) {
                this.tip = true
            }
            if(this.submitFlag) {
                this.refresh(true)
            }
        },
        nativeGet: async function(callBack) {
            if(SmartStorage.get('isH5')){
                return
            }
            if (window.flutter_inappwebview) {
                let aaa = await window.flutter_inappwebview.callHandler(
                    "SmStorage.getBool",
                    "tip"
                );
                callBack(JSON.parse(aaa||'[]'));
                } else {
                    this.iJsBridge.call({
                        method: "SMDataStorage.getItem",
                        postData: {
                            key: ["tip"]
                        },
                        callback: function(datas) {
                            callBack(datas)
                        }
                    });
                }
        },
        getDetail() {
            let params = {
                itemId: this.orderDetail.itemId || this.$route.query.orderNo
            }
            miceService.getMealItemDetail(params).then(res => {
                if(res && res.success) {
                    this.detail = res.content
                }
                if(this.submitFlag) {
                    this.checkIsPaySuccess()
                }
            })
        },
        async saveCacheData(aliPayUrl){
			// 保存支付信息
			let eventDataParams = {
                Data: {
                    aliPayUrl: aliPayUrl
                }
            }
            return new Promise((resolve,reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes.content)
                    }
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        async goPay(){
            await this.getLocationFailed();
            this.submint();
        },
        async submint() {
            if(this.mealRules && this.mealRules.alipay){
                let aliPayUrl = await this.getAlipayUrl()
                let path = await this.isWx(aliPayUrl)
                this.$router.push({
                    path: path,
                });
                return
            }
            var u = navigator.userAgent;
            let isWeixin = false
            if(window.__wxjs_environment === "miniprogram" || /MicroMessenger/gi.test(u)){
                isWeixin = true
            }
            if(this.orderDetail.isPlusPay) {
                this.elePay()
            } else {
                if(SmartStorage.get('isH5') && isWeixin){
                    this.coverShow = true
                    return
                }
                let url = '/eleProcess';
                // 协办会
                if(SmartStorage.get('isH5')){
                    url = '/eleProcess?orderNo=' + this.$route.query.orderNo
                }
                if(!this.tip) {
                    this.$router.push({path: url})
                } else {
                    this.elePlusPay()
                }
            }
        },
        async getAlipayUrl(){
            let aliPayParams = {
                params: {
                    sid: this.mealRules.sid,
                    ItemId: this.orderDetail.itemId,
                    ProposalId: this.orderDetail.proposalId || SmartStorage.get('eventData').proposalId,
                    Amount: this.orderDetail.orderAmount,
                    phoneNumber: SmartStorage.get("authMobile"),
                    userId: this.memsg.userId,
                    tenantId: this.event.tenantId
                },
                headers: this.orderDetail.alipayCertificate
            }
            return new Promise((resolve, reject) => {   
                 miceService.GenerateAlipayUrl(aliPayParams).then(res => {
                    if(res && res.content){
                        resolve(res.content)
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
        async isWx(aliPayUrl){
            let path = '/goAlipay'
            return new Promise(async (resolve,reject) => {
                var ua = navigator.userAgent.toLowerCase();
                var isWeixin = ua.indexOf('micromessenger') != -1;
                if (isWeixin) {
                    let sessionIdData = await this.saveCacheData(aliPayUrl)
                    path = '/goAlipay?goAlipay=true&sessionId=' + SmartStorage.get('sessionId') + '&alipaySessionId=' + sessionIdData + '&itemId=' + this.orderDetail.itemId + '&rnd=' + new Date().getTime()
                }else{
                    let detail = {
                        ...this.orderDetail,
                        aliPayUrl: aliPayUrl
                    };
                    this["ORDER_DETAIL"](detail)
                }
                resolve(path) ;
            })
            
        },
        elePay() {
            let params = {
                userId: this.memsg.userId,
                itemId: this.orderDetail.itemId,
                payTime: new Date().getTime(), //支付时间
                orderAmount: this.orderDetail.orderAmount, //支付金额 （元）
                actualQty: this.orderDetail.qty,
                phone: this.memsg.phoneNumber,
                uNo: this.memsg.userId,
            };
            miceService.elepay(params).then(res => {
                if (res && res.success) {
                    let detail = {
                        ...this.orderDetail,
                        extOrderId: res.content.orderNo
                    };
                    this["ORDER_DETAIL"](detail);
                    this.$router.push({ path: "/elePaySuccess" });
                }
            });
        },
        elePlusPay() {

            let _this = this
            let orderNo= '';
            // 协办会
            if(SmartStorage.get('isH5')){
                orderNo = this.$route.query.orderNo
            }else{
                orderNo = this.orderDetail.orderNo
            }
            let params = {
                uNo: this.memsg.userId,
                orderNo: orderNo,
                callBackUrl: 'https://ipage.smartmice.cn/externalCallback?rnd='+ new Date().getTime(),
                phone: this.memsg.phoneNumber
            }
            if(SmartStorage.get('isH5') || this.event.tenant_name == '会引擎-huarun'){
                params.callBackUrl = SmartStorage.get('Uris').Uris['webapp-imeal'] + `/elepaydetail?tip=true&submitFlag=true&orderNo=${orderNo}&sessionId=${SmartStorage.get('sessionId')}`
                // params.callBackUrl = 'http://192.168.1.171:8080' + "/elepaydetail?tip=true&submitFlag=true&orderNo=" + this.$route.query.orderNo
            }
            miceService.getElePayPage(params).then(res => {
                if(res && res.success) {
                    const tenant_code = this.event.tenant_code || 'roche';
                    if(SmartStorage.get('isH5')){
                        window.location.href = res.content.url
                        return
                    }
                    const options = {
                        method: "SMGeneral.openNewWebSiteForResult",
                        postData: {
                            loadUrl: res.content.url,
                            browserMode: {
                                navBarVisibilty: true,
                                titleTextColor: this.navBackgroundColor[tenant_code],
                                navBackgroundColor: this.navBackgroundColor[tenant_code]
                            }
                        },
                        callback: _this.openEleWebSiteCallback
                    };
                    _this.iJsBridge.call(options);
                }
            })
        },
        async openEleWebSiteCallback() {
            this.submitFlag = true
            this.checkIsPaySuccess()
        },
        async checkIsPaySuccess() {
            let params = {
                orderNo: this.orderDetail.orderNo,
            }
            if(SmartStorage.get('isH5')){
                params.orderNo = this.$route.query.orderNo
            }
            miceService.isPaySuccess(params).then(res => {
                if(res && res.success && res.content.IsPaySuccess) {
                    if(res.content.employeeFee > 10) {
                        this.$router.push({path: '/elePayFail'})
                    } else {
                        this.$router.push({path: '/elePaySuccess'})
                    }
                }
            })
        },
        goProcess() {
            this.$router.push({path: '/eleProcess?see=true'})
        },
        refresh(flag) {
            let params = {
                orderNo: this.orderDetail.orderNo,
            }
            if(SmartStorage.get('isH5')){
                params.orderNo = this.$route.query.orderNo
            }
            miceService.isPaySuccess(params).then(res => {
                if(res && res.success && res.content.IsPaySuccess) {
                    if(res.content.employeeFee > 10) {
                        this.$router.push({path: '/elePayFail'})
                    } else {
                        this.$router.push({path: '/elePaySuccess'})
                    }
                } else if(!flag) {
                    this.toast('刷新成功')
                }
            })
        },
        resetSubmint() {
            let params = {
                orderNo: this.orderDetail.orderNo,
            }
            if(SmartStorage.get('isH5')){
                params.orderNo = this.$route.query.orderNo
            }
            miceService.isPaySuccess(params).then(res => {
                if(res && res.success){
                    if(res.content.IsPaySuccess) {
                        if(res.content.employeeFee > 10) {
                            this.$router.push({path: '/elePayFail'})
                        } else {
                            this.$router.push({path: '/elePaySuccess'})
                        }
                    } else {
                        this.elePlusPay()
                    }
                }
            })
        }
    }
};
</script>
<style lang="scss">
.new_elePay{
    .pay_amount{
        font-size: .25rem;
        margin-top: .6rem;
        span{
            font-size: .4rem;
            font-weight: 700;
        }
    }
    .pay_shopName{
        color: #909399;
        margin-top: .1rem;
        margin-bottom: .3rem;

    }
    .bg_gray{
        width: 100%;
        height: .1rem;
        background: #FAFAFA;
    }
    .pay_type{
        width: 95%;
        margin: 0 auto;
        font-size: .16rem;
        display: flex;
        align-items: center;
        height: .56rem;
        font-weight: 500;
        svg{
            width: .2rem;
            height: .2rem;
            margin-right: .1rem;
        }
    }
    .pat_phone{
        font-size: .12rem;
        font-weight: 500;
        color: #909399;
        margin-top: .3rem;
        span{
            color:  var(--themeColor);
        }
    }
    .elePayGroup {
        width: 95%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0.3rem;
        right: 0;
        left: 0;
        .elePayBtn {
            width: 48.5%;
            background: var(--themeColor);
            height: 0.45rem;
            line-height: 0.45rem;
            font-size: 0.15rem;
            border-radius: 0.05rem;
            color: #fff;
        }
        .btn_gray{
            background: #fff;
            color: #90939A;
            border: 1px solid #E6E7E9;
            box-sizing: border-box;
        }
    }
}
.elePayCard {
    background: #f8f8f8;
    border-radius: 0.13rem;
    box-shadow: 5px 5px 0.15rem #dedede;
    padding: 0.15rem 0.15rem 0 0.15rem;
    margin: 0.5rem 0.25rem 0 0.25rem;
    .eatingHead {
        border-bottom: 1px solid #e6e7e9;
        padding-bottom: 0.05rem;
        position: relative;

        .eatingImg,
        .eatingShop {
            float: left;
        }

        .eatingImg {
            width: 22%;
            text-align: left;
            img {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
            }
        }

        .eatingShop {
            width: 65%;
            .eatingShopName {
                font-size: 0.14rem;
                font-weight: 500;
                color: #13161a;
                text-align: left;
            }
            .eatingShopDate {
                font-size: 0.1rem;
                color: #c0c4cc;
                text-align: left;
            }
        }
    }

    .eatingHead:after,
    .mealBudget:after,
    .mealBtnRow:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0;
    }

    .shopDetail,
    .mealBudget {
        border-bottom: 1px solid #e6e7e9;
        padding: 0.15rem 0;
        font-size: 0.12rem;
    }

    .shopDetail {
        .shopPhone {
            color: car(--gradualColor);
            font-weight: 500;
            text-align: left;
            font-size: 0.14rem;
        }

        .shopAddress {
            color: #909399;
            text-align: left;
            font-size: 0.12rem;
        }
    }

    .mealBudget {
        border-bottom: 1px solid #e6e7e9;
        padding: .05rem 0 .15rem 0;
        li {
            float: left;
            width: 50%;
            color: #909399;
            text-align: left;
            margin-top: .05rem;
        }
        li:first-child {
            width: 100%;
        }

        span {
            color: #13161a;
            margin-left: .02rem;
        }

        .userPhone {
            color: car(--gradualColor);
            font-weight: 500;
        }
    }

    .mealBtnRow {
        padding: .2rem 0;
        text-align: right;
        color: #909399;

        span {
            color:  var(--themeColor);
            font-size: 0.16rem;
        }
    }
}

.elePayGroup {
    margin: 0 0.25rem 0 0.25rem;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0.3rem;
    right: 0;
    left: 0;

    .elePayBtn {
        width: 1.5rem;
        float: left;
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.14rem;
        border-radius: 0.25rem;
    }
}
.ele-tip {
    text-align: left;
    margin-top: 0.26rem;
    font-size: 0.12rem;
    color: #909399;
    padding: 0 0.25rem;

    span:first-child{
        color: red;
    }
    span:last-child{
        color:  var(--themeColor);
    }
}
.ele-tip + .ele-tip {
    margin-top: 0.1rem !important;
}
</style>
