<template>
    <div class="shop-list">
        <Header backType="white" :back="searchFlag ? true : !event.mealCombineData" :backEvent="true" @backEvent="back">
            <span slot="headText" style="font-size:.17rem;">商家列表</span>
        </Header>

        <!-- <div class="shop-list-head">
            <div class="shop-list-filter">
                <div @click="back">
                    <Svgs v-if="$route.query.type != 'selectShop'" color="#000" name="iconfanhui"></Svgs>
                </div>
                <div @click="goAddress">
                    <span class="text">{{this.address.name || '请选择用餐地址'}}</span>
                    <Svgs v-if="event.tenant_code != 'pfizer'" class="down" color="#E1E1E1" name="iconfontdown"></Svgs>
                </div>
                <div v-if="!showFilter" class="shop-list-head-icon" @click="openFilter">
                    <Svgs color="#000" name="iconfilter"></Svgs>
                </div>
            </div>
            <div class="shop-list-search">
                <searchRow placeholder="请输入餐厅名称" v-model="keyword"></searchRow>
            </div>
            <div class="xian-xia" v-if="showBottom" @click="goXianxia">若您未找到需要的餐厅，可尝试
                <span>新增非平台餐厅</span>
            </div>
            <div class="cover" v-if="sortShow" @click.prevent="closeFilter"></div>
            <div class="sort" v-if="sortShow">
                <template v-for="(item, index) in filterOpt">
                    <div :key="index">
                        <p>{{item.txt}}</p>
                        <template v-for="(btn, index1) in item.options">
                            <Btn
                                :btnClass="btn.isSelected ? 'filter-btn active' : 'filter-btn'"
                                @callBack="setSort(btn, item, index, index1)"
                                :key="index1"
                            >
                                <span slot="btnContent">{{btn.txt}}</span>
                            </Btn>
                        </template>
                    </div>
                </template>
                <div class="foot-btn">
                    <Btn btnClass="btn-default" @callBack="reset">
                        <span slot="btnContent">重置</span>
                    </Btn>
                    <Btn btnClass="btn-default active" @callBack="submit">
                        <span slot="btnContent">完成</span>
                    </Btn>
                </div>
            </div>
        </div> -->
        <!-- <div class="list-content"> -->
        <div class="shop_search" v-if="!searchFlag">
            <div @click="goAddress" class="shop_address">
                <Svgs class="down" color="#13161B" name="icondizhi2"></Svgs>
                <span class="text">{{this.address.name || '请选择用餐地址'}}</span>
                <!-- <Svgs class="down" color="#E1E1E1" name="iconfontdown"></Svgs> -->
            </div>
            <div class="search_img" @click="goSearch">
                <Svgs class="down" color="#13161B" name="iconsousuo"></Svgs>
            </div>
        </div>
        <div class="shop-list-search" v-else>
                <searchRow placeholder="请输入餐厅名称" v-model="keyword"></searchRow>
            </div>
        <ScrollBar class="list-content" :class="{'list-content1':showBottom}" @onTop="handleTop" :style="listStyle" @onBottom="scrollBottom" :loadType="loadType">
            <div slot="scrollBar">
                <template v-if="shopList.length">
                    <div
                        v-for="(item, index) in shopList"
                        :key="index"
                        @click="shopDetail(item)"
                        class="shop-list-row"
                    >
                        <div class="item-img">
                            <img :src="item.imgThumbnail || '@/assets/img/default.png'" class="shop-img" @error="setErrorImg" />
                        </div>
                        <div class="item-info">
                            <div class="fullName">
                               <Svgs classes="isTopSvg" v-if="item.itsExtData && item.itsExtData.isTop && event.tenant_code !='pfizer'" name="icon-renqi"></Svgs>{{item.fullName}}
                            </div>
                            <div class="distance">
                                <span>
                                    <Svgs color="#FFCD30" name="iconxingxing"></Svgs>
                                    {{(item.starVal).toFixed(1)}}
                                </span>
                                <div>
                                    <p>￥{{item.priceAvgVal}}/人</p>
                                    <p>距离：{{countDistance(item.distance)}}</p>
                                </div>
                            </div>
                            <div class="address">
                                <p>{{item.regionVal}}</p>
                                <span class="vertical" v-if="item.categoryVal">|&nbsp;&nbsp;</span>
                                <p>{{item.categoryVal}}</p>
                            </div>
                        </div>
                    </div>
                </template>
                <NoData v-if="!shopList.length"></NoData>
            </div>
        </ScrollBar>
        <!-- </div> -->
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
        <SearchCity :cityList="cityList" @onOk="cityOk" ref="eventCity"></SearchCity>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import {
    SmartStorage
} from 'smart-core-util'
import { getCookie } from 'tiny-cookie'
import getPositionFunction from "@/utils/getPositionFunction.js";
export default {
    mixins: [getPositionFunction],
    data() {
        return {
            keyword: "",
            shopList: [],
            filterOpt: [],
            sortShow: false,
            orgFilterOpt: [],
            filters: "",
            temp: [],
            loadType: "",
            pageindex: 1,
            finish: false,
            listStyle: {},
            cityList: [],
            showBottom: false,
            scrollBottomF: true,
            showFilter: true,
            searchShopList:[],
            searchFlag: false
        };
    },
    async mounted() {
        this.getSetting();
        this.showFilter = await this.showFilterSetting()
        this["FRESH_SHOP"](false);
        if(!this.address.city){
            await this.getPosition()
        }
        this.getShopList(true);
        if(!this.orderDetail.itemId) {
            this.getXianXia();
        }
    },
    watch: {
        refreshShop: function(val, oldVal) {
            if (val) {
                this.filterOpt = _.cloneDeep(this.orgFilterOpt);
                this.filters = "";
                this.keyword = ''
                this.getShopList(true);
                this["FRESH_SHOP"](false);
                this.showBottom = false
                if(!this.orderDetail.itemId) {
                    this.getXianXia();
                }
            }
        },
        'keyword': function (val) {
            if(val){
                this.getShopList(true);
            }
        }
    },
    computed: {
        ...mapGetters(["event", "address", "refreshShop", "orderDetail","cityAddress"])
    },
    methods: {
        ...mapMutations(["FRESH_SHOP", "ADDRESS", "HOSPITAL_GPS","GAODE_SHOP_DETAIL"]),
        goSearch(){
            this.shopList = []
            this.searchFlag = true
        },
        showFilterSetting(){
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    'txt': '餐厅筛选不可用租户id',
                    'tenantCodes': {
                        tenant_id: getCookie('tenant_id')
                    },
                },
                projection: {
                    restaurantType: 1
                }
            };
            return new Promise((resolve, reject) => {   
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve(res.content.length)
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
        /** 图片加载失败时调用方法 */
        setErrorImg(e) {
            var img = e.srcElement;
            img.src = require("@/assets/img/default.png");
            img.οnerrοr = null;
        },
        urlConversion(path) {
            let imgpath = path
            if(!imgpath){
                return ''
            }
            if(path[4] != 's' && imgpath){
                imgpath = 'https:' + imgpath.substring(5, imgpath.length);
            }
            return imgpath
        },
        getStyle() {
            let height = document.querySelector(".list-content").clientHeight
            this.listStyle = {
                height: height + 'px'
            }
        },
        getShopList(fresh) {
            if (fresh) {
                this.index = 1;
                this.finish = false;
                this.pageindex = 1;
                if (document.querySelector(".list-content")) {
                    document.querySelector(".list-content").scrollTop = 0;
                }
            }
            let params = {
                pageindex: this.pageindex,
                pageSize: 10,
                CityName: this.address.city || this.event.cityDictVal,
                NL: this.address.latitude || '0',
                EL: this.address.longitude || '0',
                filters: this.filters,
                Keyword: this.keyword
            };
            if(['novartis'].includes(this.event.tenant_code)) {
                if(params.filters) {
                    params.filters += ' AND '
                }
                params.filters = params.filters + '(distance  <= 5000)'
            }
            miceService.getShopList(params).then(res => {
                if(res && res.success){
                    for(let item of res.data){
                        item.imgThumbnail = this.urlConversion(item.imgThumbnail)
                    }
                    this.shopList = fresh ? res.data : this.shopList.concat(res.data);
                    this.finish = res.data.length < 10 ? true : false;
                    this.scrollBottomF = true
                    setTimeout(() => {
                        this.loadType = "";
                    }, 1000);
                }
            });
        },
        countDistance(distance) {
            let dis = distance.toFixed(0);
            if (dis.length > 3) {
                return `${(dis / 1000).toFixed(1)}km`;
            } else {
                return dis + "m";
            }
        },
        scrollBottom() {
            if (this.finish) {
                return;
            }
            if(!this.scrollBottomF){
                return;
            }
            this.scrollBottomF = false
            this.loadType = "bottom";
            this.pageindex += 1;
            this.getShopList();
        },
        handleTop() {
            this.loadType = "top";
            this.getShopList(true);
        },
        setSort(btn, item, index, index1) {
            /* global _ */
            let btno = _.cloneDeep(btn);
            let itemo = _.cloneDeep(item);
            if (item.multiple) {
                btno.isSelected = !btn.isSelected;
                itemo.options[index1] = btno;
                this.$set(this.filterOpt, index, itemo);
            } else {
                itemo.options.map(i => {
                    i.isSelected = false;
                });
                btno.isSelected = !btn.isSelected;
                itemo.options[index1] = btno;
                this.$set(this.filterOpt, index, itemo);
            }
        },
        reset() {
            this.filterOpt = _.cloneDeep(this.orgFilterOpt);
            this.filters = "";
            this.getShopList(true);
            this.sortShow = false;
        },
        submit() {
            let arr = [];
            this.filterOpt.forEach((item, index) => {
                if (item.multiple) {
                    let multipleItems = [];
                    item.options.forEach(option => {
                        if (option.isSelected) {
                            const itemStr = `${item.val} ${option.sql}`;
                            multipleItems.push(itemStr);
                        }
                    });
                    let multipleStr = multipleItems.join(" OR ");
                    if (multipleStr) {
                        arr.push(`(${multipleStr})`);
                    }
                } else {
                    let itemStr = "";
                    item.options.forEach(option => {
                        if (option.isSelected) {
                            itemStr = `(${item.val} ${option.sql})`;
                        }
                    });
                    if (itemStr) {
                        arr.push(itemStr);
                    }
                }
            });
            this.filters = arr.join(" AND ");
            this.getShopList(true);
            this.sortShow = false;
        },
        openFilter() {
            this.temp = _.cloneDeep(this.filterOpt);
            this.sortShow = true;
        },
        closeFilter() {
            this.filterOpt = _.cloneDeep(this.temp);
            this.filters = "";
            this.sortShow = false;
        },
        getType(payProfile) {
            if (!payProfile) return false;
            let tmpPayMode = JSON.parse(payProfile).PayModes; // 可能返回数字或数组
            let modes =
                typeof tmpPayMode == "number" ? tmpPayMode : tmpPayMode[0];

            let result = {};
            switch (modes) {
                case 2:
                    result = {
                        icon: "iconzhifubao",
                        color: "#00aaee"
                    };
                    break;
                case 3:
                    result = {
                        icon: "iconweixin1",
                        color: "#FF821D"
                    };
                    break;
                case 4:
                    result = {
                        icon: "iconmeican",
                        color: "#11d350"
                    };
                    break;
                case 5:
                    result = {
                        icon: "icondingcanxiaomishu2",
                        color: ""
                    };
                    break;
                case 6:
                    result = {
                        icon: "iconkoubei",
                        color: "#e8470b"
                    };
                    break;
                case 8:
                case 15:
                    result = {
                        icon: "iconelemo",
                        color: "#1e89e0"
                    };
                    break;
                case 11:
                    result = {
                        icon: "icona-gaodeloukong",
                        color: "#00aaee"
                    };
                    break;
            }
            return result;
        },
        getSetting() {
            const tenant_code = this.event.tenant_code || SmartStorage.get('tenant_code') || getCookie('tenant_code') || "pfizer";
            let params = {
                collection: "cfg-ishop-filter-options",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: {
                        $in: [tenant_code, "*"]
                    }
                },
                projection: {
                    toolBar: 1,
                    filters: 1
                }
            };
            miceService.getsettings(params).then(res => {
                this.filterOpt = _.cloneDeep((res.content || {}).filters);
                this.orgFilterOpt = _.cloneDeep((res.content || {}).filters);
            });
        },
        getXianXia() {
            let params = {
                collection: "cfg-ishop-platform-options",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: this.event.tenant_code || SmartStorage.get('tenant_code') || getCookie('tenant_code') || "roche",
                    eventType: { $in: [this.event.typeDictTxt || "*", "*"] },
                    eventStatus: { $in: [this.event.statusTxt || "*", "*"] },
                    "cities.city": {
                        $in: [
                            this.event.cityDictVal || "*", "*",
                        ],
                    },
                },
                // projection: {
                //     btnTxt: 1,
                //     callbackFn: 1,
                // },
            };
            miceService.getsettings(params).then(res => {
                if(res.success) {
                    this.showBottom = !!res.content;
                }
            })
        },
        back() {
            if(this.searchFlag){
                this.searchFlag = false
                this.keyword = ''
                this.getShopList(true)
                return
            }
            if(this.event.search) {
                if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler(
                        "SmGeneral.closeWebSite",
                    );
                }else{
                    this.iJsBridge.close();
                }
            } else {
                this.sortShow = false;
                if(this.orderDetail.itemId) {
                    this.$router.push('/orderList')
                } else {
                    this.$router.push('/addMealDemand?shopType=平台到餐');
                }
            }
        },
        goAddress() {
            // if(['pfizer'].includes(this.event.tenant_code)){
            //     // let params = {
            //     //     type: 2
            //     // }
            //     // miceService.queryRegion(params).then(res => {
            //     //     this.cityList = res.content || res;
            //     //     this.$refs.eventCity.openPop()
            //     // })
            //     return
            // }
            this.sortShow = false;
            if(['novartis'].includes(this.event.tenant_code) && !this.event.search) {
                this.$refs.hospitalPop.openPop()
            } else {
                this.$router.push("/selectAddress");
            }
        },
        goXianxia() {
            this.$router.push("/selectAddress?other=true");
        }, 
        cityOk(item) {
            let address = {
                longitude: item.longitude,
                latitude: item.latitude,
                name: item.txt,
                city: item.txt
            }
            this["ADDRESS"](address)
            this.getShopList(true);
        },
        chooseHosRes(item) {
            let address = {
                longitude: item.el,
                latitude: item.nl,
                name: item.name,
                city: this.event.cityDictVal
            }
            let hospitalGPS = {
                longitude: item.el,
                latitude: item.nl,
                hospitalName: item.name,
                rowId: item.rowId
            }
            this["ADDRESS"](address)
            // this["FRESH_SHOP"](true)
            this["HOSPITAL_GPS"](hospitalGPS)
            this.getShopList(true);
        },
        shopDetail(item) {
            this["GAODE_SHOP_DETAIL"](item)
            this.$router.push(
                `/shopDetail?rowId=${item.rowId}&platform=${item.platform}&type=${this.$route.query.type || false}`
            );
        },
    },
    // 进入商家详情后返回  列表位置不变动
    beforeRouteLeave(to, from, next) {
        if (document.querySelector(".list-content")) {
            this.scrollTop = document.querySelector(".list-content").scrollTop;
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (to.query.refresh) {
            next(vm => {
                if (document.querySelector(".list-content")) {
                    document.querySelector(".list-content").scrollTop =
                        vm.scrollTop;
                }
            });
        } else {
            next(vm => {
                if (document.querySelector(".list-content")) {
                    document.querySelector(".list-content").scrollTop =
                        vm.scrollTop;
                }
            });
        }
    }
};
</script>
<style lang="scss">
.other_shop {
    width: 100%;
    height: .25rem;
    line-height: .25rem;
    text-align: center !important;
    span{
        color: red
    }
}
.shop_search{
    width: 95%;
    height: .2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: .1rem;
    .shop_address{
        justify-content: center;
        font-size: .16rem;
        .icon{
            width: .2rem;
            height: .12rem;
        }
    }
    .search_img{
        .icon{
            width: .2rem;
            height: .2rem;
        }
    }
}
.shop-list {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .shop-list-search {
        height: .4rem;
    }
    .shop-list-head {
        box-sizing: border-box;
        width: 100%;
        height: 1rem;
        background-color: #fff;

        .shop-list-filter {
            position: absolute;
            background-color: #fff;
            height: 0.4rem;
            box-sizing: border-box;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            padding: 0 0.25rem 0 0.25rem;
            z-index: 4;
            width: 100%;

            div:nth-child(2) {
                padding-left: 0.2rem;
                margin-right: 0.2rem;
                width: 68%;
                font-size: 0.14rem;
                height: 0.32rem;
                line-height: 0.32rem;
                position: relative;
                box-sizing: border-box;
                text-align: left;

                .text {
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    margin-right: 5px;
                }
                .location,
                .down {
                    position: absolute;
                    top: 2px;
                }
                .location {
                    left: 10px;
                }
                .down {
                    top: 5px;
                }
            }

            .shop-list-head-icon {
                position: absolute;
                right: 0.25rem;
                top: 0.08rem;
            }
        }


        .xian-xia {
            margin: 0 0.25rem;
            position: absolute;
            box-sizing: border-box;
            text-align: center;
            top: 1rem;
            width: calc(100% - 0.5rem);
            border-bottom: 1px solid #F5F5F5;
            height: 0.28rem;
            line-height: 0.28rem;
            color: #909399;

            span {
                color:  var(--themeColor);
            }
        }

        .sort {
            padding: 0.1rem 0.25rem 0 0.25rem;
            position: absolute;
            right: 0;
            left: 0;
            z-index: 2;
            background: #fff;
            top: 0.4rem;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;

            p {
                height: 0.3rem;
                line-height: 0.3rem;
                font-size: 0.16rem;
            }

            .foot-btn {
                margin: 0.2rem 0;
                display: flex;
                justify-content: space-between;

                .btn-default {
                    width: 1.5rem;
                    text-align: center;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    border-radius: 0.25rem;
                    background: #f5f5f5;
                    color: #13161b;
                }
            }
            .active {
                background: var(--themeColor)!important;
                color: #fff !important;
            }
        }

        .filter-btn {
            height: 0.36rem;
            line-height: 0.36rem;
            width: 1rem;
            text-align: center;
            border-radius: 0.18rem;
            display: inline-block;
            margin: 0.03rem 0.08rem 0.06rem 0;
            background: #f5f5f5;
            color: #13161b;
        }
    }
    .list-content {
        top: 1.15rem;
        height: calc(100% - .8rem)!important;
        bottom: 0;
        overflow: scroll;
        width: 95%!important;
        margin: 0 auto!important;
    }
    .list-content1 {
        top: 1.3rem;
    }
    .shop-list-row {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin: 0.15rem 0;
        .item-img {
            width: .8rem;
            height: .8rem;
            border-radius: 0.1rem;
            margin-right: 0.1rem;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
            .icon {
                position: absolute;
                bottom: 0.1rem;
                right: 0.1rem;
                width: 0.24rem;
                height: 0.24rem;
            }
        }
        .item-info {
            width: calc(100% - .9rem);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            margin-top: .1rem;
            .fullName {
                text-align: left;
                color: #13161b;
                font-size: 0.16rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 600;
                .isTopSvg{
                    width: .25rem !important;
                    height: .25rem !important;
                }
            }
            .start {
                .icon {
                    width: 0.12rem;
                    height: 0.12rem;
                }
                margin-bottom: 0.05rem;
                color: #90939a;
            }
            .distance {
                color: #FFCD30;
                .icon {
                    width: 0.13rem;
                    height: 0.13rem;
                }
                display: flex;
                justify-content: space-between;
                div{
                    display: flex;
                    color: #909399;
                    p{
                        font-size: .12rem;
                    }
                    :first-child{
                        margin-right: .1rem;
                    }
                }
            }
            .address {
                color: #90939a;
                font-size: 0.12rem;
                line-height: .2rem;
                height: .2rem;
                margin-bottom: 0.05rem;
                display: flex;
                overflow: hidden;
                p{
                    text-align: left;
                    font-size: .12rem;
                }
                :last-child{
                    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .cover {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        opacity: 0.3;
        background: #333;
        z-index: 1;
    }
}
</style>