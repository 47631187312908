<template>
  <Popup
    v-model="isShow"
    v-if="isShow"
    :style="{ height: '100vh', width: '100vw' }"
    get-container="body"
    :lazy-render="true"
  >
    <div class="select-address" style="width: 100vw; height: 100vh">
      <div class="select-address-head">
        <div class="map-back-icon" @click="back">
          <span v-if="device"> 返回 </span>
          <Svgs v-else color="#000" name="iconfanhui"></Svgs>
        </div>
      </div>
      <div id="container" class="container"></div>
      <div class="formBox">
        <div class="shopInfo">
          <p>餐厅信息</p>
          <p>{{ pageData.orderDetail.itsItemData.shopName }}</p>
          <p>{{ pageData.orderDetail.itsItemData.address }}</p>
        </div>
        <div class="shopInfo">
          <p>我的位置</p>
          <p>{{ pageData.address || "当前详细位置获取失败" }}</p>
        </div>
        <div class="shopInfo">
          <p>拍照 <span style="color: red">*</span></p>
          <viewer :images="fileList" class="imgContainer_new">
            <div
              v-if="fileList.length == 0"
              class="add_div"
              @click="upLoadInvoice"
            >
              <Svgs classes="paymentAdd" color="#B9BABB" name="iconhao"></Svgs>
            </div>
            <template v-else>
              <div>
                <img :src="fileList[0].url" alt />
                <Svgs
                  color="#F37600"
                  classes="paymentDelete"
                  name="iconjiaobiaoshanchu"
                  @click.native="deleteInvoice(fileList[0])"
                ></Svgs>
              </div>
            </template>
          </viewer>
        </div>
        <div>
          <MInput
            title="备注"
            :labelBlack="true"
            pla="请填写说明文案"
            v-model="BudgetDescr"
          ></MInput>
        </div>
        <div class="photoLogSubmit" @click="submitForm">打卡签到</div>
      </div>
    </div>
  </Popup>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { UploadPlug } from "smart-filer-vue";
import { indicator } from "smart-indicator";
import { Popup, Toast } from "vant";

const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY,
});
export default {
  components: {
    Popup,
  },
  props: ["pageData"],
  data() {
    return {
      isShow: false,
      poiList: [],
      markers: [],
      BudgetDescr: "",
      fileList: [],
      orderDetail: {},
      event: {},
    };
  },
  computed: {
    device() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    render(options) {
      Object.assign(this.$props, options);
      this.isShow = true;
      let _this = this;
      setTimeout(() => {
        let formBox =
          document.getElementsByClassName("formBox")[0].offsetHeight;
        let mapBox = document.getElementById("container");
        mapBox.style.height = `${window.innerHeight - formBox}px`;
        _this.initMap();
        _this.getFiles();
      }, 10);
      console.log('=-=-this.pageData=-=-',this.pageData);
    },
    async submitForm() {
      if (this.fileList.length == 0) {
        Toast("请拍照");
        return;
      }
      let params = {
        code: "event_item_meal_punchin_save",
        meta: {
          processId: this.pageData.orderDetail.proposalId,
          ItemId: this.pageData.orderDetail.itemId,
          ShopId: this.pageData.orderDetail.itsItemData.shopId,
          Longitude: this.pageData.el,
          Latitude: this.pageData.nl,
          Category: "CheckIn",
          Remark: this.BudgetDescr,
        }
      };
      miceService.ExecuteChain(params).then((res) => {
        if (res && res.success) {
          this.$emit("onConfirm");
        }
      });
    },
    getFiles() {
      uploadInstance.queryFile({
        filterData: {
          filterData: {
            relatedId: this.pageData.orderDetail.itemId,
            catalog: "打卡签到",
          },
        },
        callback: (data) => {
          this.fileList = data.content;
        },
      });
    },
    mappingAsync(result) {
      let address1 = (this.pageData.city || '') + (this.pageData.district || '')
      let address2 = (this.pageData.street || '') + (this.pageData.streetNumber || '')
      let _this = this
        let mappingData = {
          processId: this.pageData.orderDetail.proposalId,
          relatedId: this.pageData.orderDetail.itemId,
          relatedType: "photoLog",
          catalog: "打卡签到",
          auditData: JSON.stringify({address1: address1,address2: address2,})
        };
        uploadInstance.mappingAsync({
          fileKeys: [result[0].content.fileKey],
          mappingData: mappingData,
          callback: () => {
            _this.getFiles();
          },
        });
    },
    upLoadInvoice() {
      this.graph();
    },
    deleteInvoice(list) {
      let _this = this;
      indicator.open();
      uploadInstance.deleteAsync({
        fileItem: {
          fileKey: list.fileKey,
        },
        bucketAlias: "SmartEvent",
        callback: () => {
          indicator.close();
          _this.fileList = [];
        },
      });
    },
    graph() {
      let geoLocation = {
        lat: this.pageData.nl,
        lng: this.pageData.el,
      };
      let proposalId = this.pageData.orderDetail.proposalId;
      let _this = this;
      uploadInstance.cameraFile({
        callback: (data) => {
          indicator.open();
          uploadInstance.addAsync({
            fileList: data,
            ossKey: "",
            bucketAlias: "SmartEvent",
            geoLocation: geoLocation,
            proposalId: proposalId,
            callback: (result) => {
              indicator.close();
              _this.mappingAsync(result);
            },
          });
        },
      });
    },
    back() {
      this.isShow = false;
    },
    initMap() {
      let gps =
        this.pageData.orderDetail.itsItemData.gps ||
        JSON.parse(this.pageData.orderDetail.itsItemData.gps);
      let position = null;
      position = [gps.el, gps.nl];
      this.map = new AMap.Map("container", {
        zoom: 14,
        mapStyle: "amap://styles/whitesmoke",
        center: position,
      });
      var circle = new AMap.Circle({
        center: position,
        radius: 500, //半径
        borderWeight: 3,
        strokeWeight: 2,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        strokeStyle: "solid",
        fillColor: "#1791fc",
        zIndex: 50,
      });
      if (this.pageData.el != this.pageData.nl) {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.pageData.el, this.pageData.nl),
        });
        this.map.add([marker, circle]);
      } else {
        this.map.add(circle);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-address {
  .select-address-head {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 0.45rem;
    .map-back-icon {
      width: 20%;
      text-align: center;
    }

    input {
      width: 2rem;
    }
  }
  .formBox {
    padding-top: 0.2rem;
    .shopInfo {
      border-bottom: 1px solid #f5f5f5;
      margin-bottom: 0.1rem;
      padding-bottom: 0.1rem;
      p:first-child {
        color: #13161b;
        font-size: 0.15rem;
      }
      p {
        color: #90939a;
        font-size: 0.13rem;
        text-align: left;
        padding: 0 0.25rem;
      }
      .imgContainer_new {
        padding: 0 0.25rem;
        display: flex;
        div {
          float: left;
          position: relative;
          margin: 0 0.2rem 0.2rem 0;
        }
        .paymentDelete {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .photoLogSubmit {
      width: 95vw;
      height: 0.5rem;
      line-height: 0.5rem;
      border-radius: 0.04rem;
      margin: 0.15rem 2.5vw 0.25rem 2.5vw;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
      background: var(--themeColor);
      color: white;
    }
  }
}
</style>
