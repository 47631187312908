<template>
    <div class="item5Detail2">
        <CheckboxGroup v-model="result" @change="checkChange" ref="checkboxGroup">
            <div 
                class="eatingContent" 
                :class="result.includes(item.taskId) && 'chose'" 
                v-for="(item, index) in mealList" 
                :key="'meal'+index">
                <div class="itemList flex-90">
                    <span class="approvalIcon" v-if="['novartis'].includes(event.tenant_code) && item.itsItemData">
                        <svg class="icon " aria-hidden="true" >
                            <use  :xlink:href="item.itsItemData.OwnerConfirm==1?'#icon-yiqueren':item.itsItemData.OwnerConfirm==2?'#icon-yijujue':''"/>
                        </svg>
                    </span>
                    <div class="eatingHead">
                        <div class="eatingImg">
                            <img :src="item.itsItemData.shopImg || require('../../assets/img/logo.png')">
                        </div>
                        <div class="eatingShop">
                            <div class="title">
                                <p class="eatingShopName">{{item.itsItemData.shopName}}</p>
                                <div class="status" :class="computer_status(item.status)">{{item.statusText}}</div>
                            </div>
                            
                            <div
                                class="eatingShopDate"
                            >
                                <div>
                                    <svg class="icon" style="width: .12rem;height: .12rem;" aria-hidden="true">
                                        <use :fill="'#13161B'"  xlink:href='#icon-shijian'></use>
                                    </svg>
                                    <span>
                                        {{item.dtStart | dateTime}}
                                    </span>
                                </div>
                                <div v-if="item.status >= 4">
                                    实际金额&nbsp;
                                    <span>¥{{parseFloat(item.realAmount).toFixed(2)}}</span>
                                </div>
                                <div v-else>
                                    预计金额&nbsp;
                                    <span>¥{{parseFloat(item.amount).toFixed(2)}}</span>
                                </div>
                        
                            </div>
                            <div
                                class="bookingName"
                            >
                                <div>
                                    订餐人姓名&nbsp;
                                    <span>{{item.itsItemData.bookingName}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="event.tenant_code == 'roche' && item.itsItemData.shopDistance"
                            >
                                <div>
                                    距离：&nbsp;
                                    <span>{{countDistance(item.itsItemData.shopDistance)}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="event.tenant_code == 'pfizer' && event.typeDictTxt == '患者教育会'"
                            >
                                <div>
                                    是否为饮品费&nbsp;
                                    <span>{{item.itsItemData.isDrink? '是' : '否'}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="['会引擎-石药'].includes(event.tenant_name)"
                            >
                                <div>
                                    订单创建人&nbsp;
                                    <span>{{item.creatorDspName}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="['会引擎-灵北'].includes(event.tenant_name) && item.itsItemData.budgetNum"
                            >
                            
                                <div>
                                    会议用餐编号&nbsp;
                                    <span>{{item.itsItemData.budgetNum}}</span>
                                </div>
                                <div>
                                    申请金额&nbsp;
                                    <span>¥{{parseFloat(item.itsItemData.budgetAmount).toFixed(2)}}</span>
                                </div>
                            </div>
                            <div
                                class="bookingName"
                                v-if="['会引擎-灵北'].includes(event.tenant_name) && item.itsItemData.budgetRemark"
                            >
                                <div>
                                    描述&nbsp;
                                    <span>{{item.itsItemData.budgetRemark}}</span>
                                </div>
                            </div>
                            <!-- <p
                                v-if='["pfizer"].includes(event.tenant_code)'
                                class="bookingName"
                            >
                                实际金额
                                <span>¥{{parseFloat(item.realAmount).toFixed(2)}}</span>
                            </p> -->
                        </div>
                    </div>
                    <div class="mealBtnRow" v-if="!batchApproval && item.itsButtons.length >=1">
                        <div v-if="item.itsButtons.length>4" class="btn-more" @mouseover="show(index)" @mouseout="hide(index)">
                            <!-- <Svgs name="icongengduo1"></Svgs> -->
                            <span style="color: #90939A">更多</span>
                            <ul class="more-container" :class="`btn-more-out${index}`">
                                <template v-for="(but, idx) in item.itsButtons.slice().reverse()">
                                    <li v-if="item.itsButtons.length <= 4 && idx < 2 || item.itsButtons.length > 4 && idx < 2" @click="handleClick(but, item)" :key="idx">{{but.txt}}</li>
                                </template>
                            </ul>
                        </div>
                        <template v-for="(but, idx) in item.itsButtons.slice().reverse()">
                            <div 
                                v-if="item.itsButtons.length <= 4 && idx < 5 || item.itsButtons.length > 4 && idx > 1"
                                :class="'mealOperation '"
                                :key="idx"
                                @click="handleClick(but, item)"
                            >
                                <span slot="btnContent">
                                    {{but.txt}}
                                </span>
                            </div>
                        </template>
                    </div>

                </div>
                <Checkbox shape="square" v-if="batchApproval" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" :name="item.taskId"> 
                </Checkbox>
            </div>
        </CheckboxGroup>
        <van-dialog
            v-model="topayDialog"
            title="合规文件是否已具备"
            :showCancelButton='true'
            confirmButtonText="会议已结束"
            :confirmButtonColor="topayResult.length == 2 ? 'var(--themeColor)' : '#ccc'"
            :beforeClose="topayDialogConfirm"
        >
            <div class='topayDialog'>
                <CheckboxGroup v-model="topayResult" @change="checkChange" ref="checkboxGroup">
                    <Checkbox shape="square" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" name="1"> 
                        会议照片
                    </Checkbox>
                    <Checkbox shape="square" ref="checkboxGroup" style="padding: 0 .02rem 0 0.1rem" name="2"> 
                        会议签到表
                    </Checkbox>
                </CheckboxGroup>
            </div>
        </van-dialog>
        <MessageBox ref="remove" type="tips" messageTxt="确认删除?" @onOk="remove"></MessageBox>
        <MessageBox ref="cancel" type="tips" messageTxt="确认取消?" @onOk="cancelOrder"></MessageBox>
        <MessageBox ref="refund" type="tips" messageTxt="请与商家协商后，致电客服，发起电话取消?" @onOk="refund"></MessageBox>
        <MessageBox ref="uploadInvoice" type="tips" okTxt="联系客服" messageTxt="订单支付异常，请联系客服" @onOk="ihtml.telCall('01056304858')"></MessageBox>
        <MessageBox ref="chang" type="tips" btnType="single" singleTxt="确定" messageTxt="餐厅更换成功，请拨打餐厅电话预约用餐，并请和餐厅确认是否能开具发票，如不能开具请更换餐厅！"></MessageBox>
        <Reason ref="reason" @closeWebview="closeWebview"></Reason>
        <ChangeShopPop ref="ChangeShopPop" :itemList="itemList" @handlechannel="handlechannel" />
        <BrowserLoader ref="browserLoader" @close="dochangeShop({fromIshop: false})" />
    </div>
</template>
<script>
import Item5Detail from "./Item5Detail2.js";
import ChangeShopPop from "@/components/ChangeShopPop.vue";
export default Item5Detail;
</script>
<style lang="scss">

.item5Detail2 {
    width: 100%;
    .eatingContent + .eatingContent {
        margin-top: 0.08rem;
    }
    .chose{
        box-shadow: 0px 1px 4px 0px var(--themeColor)!important
    }
    .topayDialog{
        padding: .2rem .3rem;
        .van-checkbox{
            margin-bottom: .1rem;
        }

    }
    .eatingContent {
        width: 100%;
        border-radius: .1rem;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
        display: flex;
        padding-bottom: .12rem;
        .flex-90{
            flex-basis: 90%;
        }
        .van-checkbox{
            padding: 0 .1rem 0 0!important;
        }
        .itemList{
            width: calc(100% - .24rem)!important;
            padding: .12rem .12rem 0 .12rem;
            flex-grow: 1;
            position: relative;
            .approvalIcon{
                position: absolute;
                right: .1rem;
                top: .5rem;
                .icon{
                    width: .4rem;
                    height: .4rem;
                }
            }
        }
        .eatingHead {
            padding-bottom: .05rem;
            display: flex;
            .approvalIcon{
                position: absolute;
                right: .1rem;
                bottom: .8rem;
                .icon{
                    width: .4rem;
                    height: .4rem;
                }
            }
            .eatingImg {
                    width: 12%;
                    text-align: left;
                img {
                    width: .36rem;
                    height: .36rem;
                    border-radius: 50%;
                }
            }

            .eatingShop {
                width: 88%;
                padding-left: 1%;
                .eatingShopName {
                    width: 80%;
                    font-size: .16rem;
                    font-weight: bold;
                    color: #13161A;
                    text-align: left;
                    padding-bottom: .02rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
                .eatingShopDate {
                    font-size: .14rem;
                    color: #13161B;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #909399;
                    span{
                        color: #13161b;
                    }
                }
                .title{
                    display: flex;
                    justify-content: space-between;
                    .status{
                        font-size: .14rem;
                        white-space: nowrap;
                        text-align: right;
                    }
                }
                .bookingName{
                    display: flex;
                    color: #909399;
                    justify-content: space-between;
                    span{
                        color: #13161b;
                    }
                }
            }
        }
        .eatingHead:after,
        .mealBudget:after,
        .mealBtnRow:after {
            display: block;
            clear: both;
            content: "";
            visibility: hidden;
            height: 0;
        }

        .shopDetail,
        .mealBudget {
            border-bottom: 1px solid #E6E7E9;
            padding: .15rem 0;
            font-size: .12rem;
        }

        .shopDetail {
            .shopPhone {
                color:  var(--themeColor);
                font-weight: 500;
                text-align: left;
                font-size: .14rem;
            }

            .shopAddress {
                color: #909399;
                text-align: left;
                font-size: .12rem;
            }
        }

        .mealBudget {
            border-bottom: 1px solid #e6e7e9;
            padding: .1rem 0 .15rem 0;
            li {
                color: #909399;
                text-align: left;
                margin-top: .05rem;
            }

            .row-flex {
                display: flex;
                div {
                    width: 50%;
                    text-align: left;
                }
            }

            span {
                color: #13161a;
                margin-left: .02rem;
                font-size: 0.12rem;
            }

            .userPhone {
                color:  var(--themeColor);
                font-weight: 500;
            }
        }

        .mealBtnRow {
            width: 100%;
            padding-top: .12rem;
            display: flex;
            justify-content: flex-end;
            position: relative;
            border-top: 1px solid #E6E7E9;

            .btn-more {
                position: absolute;
                left: 0;
                bottom: 0.18rem;
            }
            .more-container {
                position: absolute;
                left: -0.1rem;
                top: 0.3rem;
                z-index: 1;
                background: #fff;
                box-shadow: 1px 1px 3px #e6e7e9;
                border-radius: 0.08rem;
                display: none;
                
                li {
                    height: 0.46rem;
                    line-height: 0.46rem;
                    width: 1.1rem;
                }
                li + li {
                    border-top: 1px solid #e6e7e9;
                }
            }
            .mealOperation {
                height: .25rem;
                line-height: .25rem;
                padding: 0 .05rem;
                min-width: .4rem;
                text-align: center;
                position: relative;
                margin-left: .06rem;
                border-radius: .05rem;
                font-size: 0.12rem;
                color: #90939A;
                border: 1px solid #E6E7E9;
                background: #fff;
                .mealOperationBtn {
                    width: .2rem !important;
                    height: .2rem !important;
                    position: absolute;
                    left: .15rem;
                    top: .15rem;
                }
            }
            div:last-child{
                background: var(--themeColor);
                color: #fff;
                border: 1px solid var(--themeColor);
            }
            .btnHighlight{
                background: var(--themeColor);
                color: #fff;
            }
            
        }
    }
}
</style>
