<template>
    <div class="select-address">
        <div class="select-address-head">
            <div class="map-back-icon" @click="back">
                <span v-if="device">
                    返回
                </span>
                <Svgs v-else color="#000" name="iconfanhui"></Svgs>
            </div>
            <searchRow @click.native="opeSearch" :disabled="true" placeholder="小区/写字楼/学校等" v-model="keyword"></searchRow>
        </div>
        <div id="container" class="container"></div>
        <div class="poi-list">
            <div class="address-item" v-for="(item, index) in poiList" @click="choosePo(item)" :key="index">
                <div>
                    <div>
                        <Svgs
                            v-if="![0, 1, 2].includes(index)"
                            color="#C0C4CD"
                            name="iconzhiyuandidian4"
                        ></Svgs>
                        <span class="map-po-number" v-else>{{index + 1}}</span>
                    </div>
                </div>
                <div>
                    <p>{{item.name}}</p>
                    <p>{{item.address}}</p>
                </div>
            </div>
        </div>

        <MapSearch ref="mapSearch" />
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { SmartInvoker } from "@/core";
export default {
    data() {
        return {
            keyword: '',
            poiList: [],
            markers: []
        };
    },
    watch: {
        'keyword': function () {
            this.search();
        }
    },
    mounted() {
        this.initMap();
    },
    computed: {
        ...mapGetters(["event", "address", "orderDetail"]),
        device(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapMutations(["ADDRESS", "FRESH_SHOP", "SHOP"]),
        back() {
            this.$router.go(-1);
        },
        opeSearch() {
            this.$refs.mapSearch.openPop()
        },
        initMap() {
            /* global AMap */
            let position = null;
            // if(this.event.tenant_code == 'crossevent'){
                position = [this.address.longitude, this.address.latitude];
            // }
            this.map = new AMap.Map("container", {
                resizeEnable: true,
                zoom: 16,
                mapStyle: "amap://styles/whitesmoke",
                center: position
            });
            this.keyword = this.address.name
        },
        search() {
            let _this = this;
            AMap.plugin("AMap.PlaceSearch", function() {
                var autoOptions = {
                    citylimit: false,
                    extensions: "all" //all，返回基本+详细信息
                };
                var placeSearch = new AMap.PlaceSearch(autoOptions);
                placeSearch.search(_this.keyword, function(status, result) {
                    _this.poiList = _this.keyword
                        ? (result.poiList || {}).pois
                        : [];
                    _this.map.remove(_this.markers);
                    if(_this.poiList.length) {
                        let center = [_this.poiList[0].location.lng, _this.poiList[0].location.lat]
                        _this.map.setCenter(center); 
                        let marker = new AMap.Marker({
                            map: _this.map,
                            position: center
                        });
                        _this.markers.push(marker);
                    }
                });
            });
        },
        choosePo(item) {
            if(this.$route.query.other) {
                let shopData = {
                    isExtra: true,
                    fullName: item.name,
                    sourceCode: "MAP",
                    shopAddress: item.address,
                    province: item.pname,
                    city: item.cityname || item.pname,
                    cityRegion: item.adname
                };
                this["SHOP"](shopData);
                if(this.orderDetail.itemId) {
                    this.$router.push('/orderList');
                } else {
                    this.$router.push('/addMealDemand?shopType=平台到餐');
                }
            } else if(this.event.search) {
                 this.resetAddress(item)
            } else {
                if(['dscl'].includes(this.event.tenant_code) && ['区域会'].includes(this.event.typeDictTxt)) {
                    this.resetAddress(item)
                } else {
                    SmartInvoker.call("iShop-Select-Address-Done", this.event.tenant_code, param => {
                        const eventCity = this.event.cityDictVal;
                         //罗氏
                        if (['roche'].includes(this.event.tenant_code)) {
                            if([1, 2].includes(this.event.itsExtData?.vMettingType)){
                                this.resetAddress(item)
                                return
                            }
                        }
                        //灵北卡控
                        if ((item.cityname || item.pname) != eventCity && this.event.tenantId == 'c3861376-4eb6-11ed-9bb4-22cc57c8ec85' && this.event.typeDictTxt != '拜访用餐') {
                            if("否" == this.event.extData.isNetEvent){
                                this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                            }
														
                            if(!["圆桌会"].includes(this.event.typeDictTxt)){
                                this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                            }

                        }
                        if(param && (item.cityname || item.pname) != eventCity && !['bbs','crossevent'].includes(this.event.tenant_code)) {
                            this.toast("您选择的地址与会议所在城市不符，无法定位，请重新选择。");
                            return;
                        }
                    });
                    this.resetAddress(item)
                }
            }
        },
        resetAddress(item) {
            let address = {
                name: item.name,
                latitude: item.location.lat,
                longitude: item.location.lng,
                city: item.cityname || item.pname
            }
            this["ADDRESS"](address)
            this["FRESH_SHOP"](true)
            this.back()
        }
    }
};
</script>
<style lang="scss">
.select-address {
    .select-address-head {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        height: 0.45rem;

        .map-back-icon {
            width: 20%;
            text-align: center;
        }

        input {
            width: 2rem;
        }
    }
    .container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .poi-list {
        position: absolute;
        bottom: 0;
        max-height: 45%;
        overflow: scroll;
        width: 100%;
        background: #fff;
        z-index: 1;

        .address-item {
            display: flex;
            align-items: center;
            padding: 18px 25px;
            * {
                text-align: left;
            }

            div:first-child {
                width: 20%;
                text-align: center;

                div {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f5f5f5;
                }
            }

            div:last-child {
                width: 80%;

                p:first-child {
                    font-size: 16px;
                    margin: 2px 0 10px 0;
                }

                p:last-child {
                    color: #90939a;
                    margin: 0;
                }
            }
        }
    }
}
.amap-ui-poi-picker-sugg-list {
    li {
        text-align: left;
    }
}
</style>