<template>
  <div class="flc">
    <Header
      backType="white"
      :backEvent="true"
      @backEvent="back"
       :back="true"
    >
    </Header>
      <!-- <div class="video-Box" v-if="!imgSrc">
        <video id="videoCamera" muted webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" style="box-shadow: darkgrey 0 0  30px 5px ;"></video>
        <canvas style="display:none; " id="canvasCamera"></canvas>
      </div>
      <div class="img_bg_camera" v-if="imgSrc">
          <p style="color: #000000;">图片显示</p>
          <img :src="imgSrc" alt="" @click="imgSrc = null" class="tx_img" style="box-shadow: darkgrey 0 0  30px 5px ;" v-if="imgif">
      </div> -->
      <div v-if='positionAll.length'>
        <div class="position_item borderB">
            <div>
                方式
            </div>
            <div>
                经度
            </div>
            <div>
                纬度
            </div>
            <div>
                耗时
            </div>
            <div>
                时间
            </div>
        </div>
        <div class="position_item borderB" v-for="(item,index) in positionAll" :key="index">
            <div v-if="item.success">
                {{item.type}}
            </div>
            <div v-if="item.success">
                {{item.nl}}
            </div>
            <div v-if="item.success">
                {{item.el}}
            </div>
            <div v-if="item.success">
                {{item.time}}
            </div>
            <div v-if="item.success">
                {{item.timeStamp}}
            </div>
            <div v-if="!item.success">
                {{item.message}}
            </div>
        </div>
      </div>
      <div class="bommen" style="margin-top: 50px;">
        <!-- <button @click="getCompetence">打开摄像头</button>
        <button @click="closeCarama">关闭摄像头</button>
        <button @click="setImage">拍照</button> -->
        <button @click="h5LocationMethed">h5浏览器定位</button>
        <button @click="AMapGetLocationMethed">高德定位</button>
        <button @click="SMAmapGetLocationMethed">原生定位</button>

      </div>
  </div>
</template>

<script>

import iJsBridge from "../utils/ijsBridge";
export default {
  data() {
      return {
          imgif: false,
          videoWidth: document.documentElement.clientHeight,
          videoHeight: document.documentElement.clientWidth,
          imgSrc: '', //图片src
          canvasCamera: null,
          contextCamera: null,
          videoCamera: null,
          positionAll:[]
      }
  },
  mounted() {
  },
  methods: {
        back() {
            var sendData = JSON.stringify({ handle: "reload_event_detail" });
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
            } else {
                this.iJsBridge.call({
                    method: "SMGeneral.setResultToNative",
                    postData: sendData,
                });
                this.iJsBridge.close();
            }
        },


isWeChatEnvironment() {
    return /MicroMessenger/i.test(navigator.userAgent);
},
getTime(){
    var today = new Date();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);
    var hour = ("0" + today.getHours()).slice(-2);
    var min = ("0" + today.getMinutes()).slice(-2);
    var sec = ("0" + today.getSeconds()).slice(-2);
    return month + "-" + day + " " + hour + ":" + min + ":" + sec;
},
calculateTimeDifference(startTime, endTime) {
    // 计算时间差（以毫秒为单位）
    const diffMilliseconds = endTime - startTime;

    // 如果时间差小于1秒，返回毫秒数
    if (diffMilliseconds < 1000) {
        return `${diffMilliseconds} 毫秒`;
    }

    // 将时间差转换为秒数
    const diffSeconds = Math.floor(diffMilliseconds / 1000);

    // 如果时间差小于1分钟，返回秒数
    if (diffSeconds < 60) {
        return `${diffSeconds} 秒`;
    }

    // 将时间差转换为分钟数和秒数
    const diffMinutes = Math.floor(diffSeconds / 60);
    const remainingSeconds = diffSeconds % 60;

    // 返回格式化的字符串
    return `${diffMinutes} 分 ${remainingSeconds} 秒`;
},
AMapGetLocationMethed(){
    let start = new Date();
    this.AMapGetLocation().then(res => {
        let end = new Date();
        if(res && res.success){
            this.positionAll.push({
                type: '高德',
                ...res,
                info:'高德定位成功',
                time:this.calculateTimeDifference(start,end),
                timeStamp:this.getTime()
            })
        console.log('-0-0-0-',this.positionAll);
        }else{
            this.positionAll.push({
                ...res,
                info:'高德定位失败'
            })
        }
    })
},
h5LocationMethed(){
    let start = new Date();
    this.h5Location().then(res => {
        let end = new Date();
        if(res && res.success){
            this.positionAll.push({
                type: 'h5',
                ...res,
                info:'h5定位成功',
                time:this.calculateTimeDifference(start,end),
                timeStamp:this.getTime()
            })
        }else{
            this.positionAll.push({
                ...res,
                info:'h5定位失败'
            })
        }
    })
},
SMAmapGetLocationMethed(){
    let start = new Date();
    this.SMAmapGetLocation().then(res => {
        let end = new Date();
        if(res && res.success){
            this.positionAll.push({
                type: '原生',
                ...res,
                info:'原生定位成功',
                time:this.calculateTimeDifference(start,end),
                timeStamp:this.getTime()
            })
        }else{
            this.positionAll.push({
                ...res,
                info:'原生定位失败'
            })
        }
    })
},
positionAllMethed(){
    this.AMapGetLocation().then(res => {
        if(res && res.success){
            this.positionAll.push(res)
        }else{
            this.positionAll.push({
                info:'h5浏览器定位失败'
            })
        }
        //高德
        this.h5Location().then(res1 => {
            if(res1 && res1.success){
                this.positionAll.push(res1)
            }else{
                this.positionAll.push({
                    info:'高德定位失败'
                })
            }
            //原生
            this.SMAmapGetLocation().then(res2 => {
                if(res2 && res2.success){
                    this.positionAll.push(res2)
                }else{
                    this.positionAll.push({
                        info:'原生定位失败'
                    })
                }
                console.log('=-=-=positionAll-=-=',this.positionAll);
            })
        })
    })
},
h5Location() {
    return new Promise((resolve, reject) => {
        console.time('h5time');
        console.log('=-==进行h5定位-=-=-');
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.timeEnd('h5time');
                    console.log('=-=-=position-=-=',position);
                    if (position && position.coords) {
                        let positions = this.transformFromWGSToGCJ(position.coords.latitude,position.coords.longitude)
                        let positionInfo = {
                            nl: positions.latitude,
                            el: positions.longitude,
                            success: true
                        }
                        console.log('=-=h5Location-=-=-',positionInfo);
                        // h5Gps = positionInfo
                        resolve(positionInfo)
                    } else {
                        console.log('=-=h5Location获取失败-=-=-');
                        let meg = position
                        try {
                            meg = JSON.stringify(position)
                        } catch (error) {
                            meg = position
                        }
                        resolve({
                            message: "h5Location获取失败" + meg,
                            success: false
                        });
                    }
                    console.log('=-==结束h5定位-=-=-');
                },
                (error) => {
                    console.timeEnd('h5errortime');
                    console.log('=-=-error=-',error);
                    resolve({
                        message: "h5Location调用失败-" + error.code + '-' + error.message,
                        success: false
                    });
                },
                options
            );
        } else {
            resolve({
                message: "浏览器不支持地理位置服务",
                success: false
            });
        }
    })
},
/**
 *  将WGS-84(国际标准)转为GCJ-02(火星坐标):
 */
transformFromWGSToGCJ(latitude, longitude) {
    var lat = "";
    var lon = "";
    var ee = 0.00669342162296594323;
    var a = 6378245.0;
    var pi = 3.14159265358979324;
  
    if (this.isLocationOutOfChina(latitude, longitude)) {
      lat = latitude;
      lon = longitude;
    }
    else {
      var adjustLat = this.transformLatWithXY(longitude - 105.0, latitude - 35.0);
      var adjustLon = this.transformLonWithXY(longitude - 105.0, latitude - 35.0);
      var radLat = latitude / 180.0 * pi;
      var magic = Math.sin(radLat);
      magic = 1 - ee * magic * magic;
      var sqrtMagic = Math.sqrt(magic);
      adjustLat = (adjustLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
      adjustLon = (adjustLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
      latitude = latitude + adjustLat;
      longitude = longitude + adjustLon;
    }
    return { latitude: latitude, longitude: longitude };
  },
  
transformLatWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lat = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    lat += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lat += (20.0 * Math.sin(y * pi) + 40.0 * Math.sin(y / 3.0 * pi)) * 2.0 / 3.0;
    lat += (160.0 * Math.sin(y / 12.0 * pi) + 320 * Math.sin(y * pi / 30.0)) * 2.0 / 3.0;
    return lat;
  },
  
  transformLonWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lon = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    lon += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lon += (20.0 * Math.sin(x * pi) + 40.0 * Math.sin(x / 3.0 * pi)) * 2.0 / 3.0;
    lon += (150.0 * Math.sin(x / 12.0 * pi) + 300.0 * Math.sin(x / 30.0 * pi)) * 2.0 / 3.0;
    return lon;
  },
/**
 *  判断经纬度是否超出中国境内
 */
isLocationOutOfChina(latitude, longitude) {
    if (longitude < 72.004 || longitude > 137.8347 || latitude < 0.8293 || latitude > 55.8271)
      return true;
    return false;
  },

SMAmapGetLocation() {
    return new Promise((resolve, reject) => {
        console.time('SMAmaptime');
        console.log('=-==进行原生定位-=-=-');
        var options = {
            method: "SMAmap.getLocation",
            postData: {
                loadUrl: window.location.href,
            },
            callback: (viewData) => {
                console.timeEnd('SMAmaptime');
                console.log('=-=SMAmapGetLocation返回-=-=-',viewData);
                let positionInfo = {}
                if (viewData && viewData.errorCode) {
                    console.log('=-=原生获取失败------');
                    resolve({
                        message: "原生获取失败:" + viewData.errorDescr,
                        success: false
                    });
                }
                try {
                    positionInfo = {
                        nl: viewData.lat,
                        el: viewData.lon,
                        success: true
                    }
                    // SMAmapGps = positionInfo
                    console.log('=-=SMAmapGetLocation-=-=-',positionInfo);
                    resolve(positionInfo)
                } catch (e) {
                    console.log('=-=原生解析失败-=-=-');
                    resolve({
                        message: "原生解析失败",
                        success: false
                    });
                }
                console.log('=-==结束原生定位-=-=-');
            }
        };
        const IJsBridge = new iJsBridge({ alert: ()=>{} });
        IJsBridge.call(options);
    })
},

AMapGetLocation() {
    return new Promise((resolve, reject) => {
        console.time('Amaptime');
        console.log('=-==进行高德定位-=-=-');
        AMap.plugin("AMap.Geolocation", function() {
            try {
                const geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true,
                    timeout: 5000,
                });
                geolocation.getCurrentPosition((status, result) => {
                    if (status === "complete" && result && result.position) {
                        console.timeEnd('Amaptime');
                        console.log('=-=-AMapGetLocation=-=-',{
                            nl: result.position.lat,
                            el: result.position.lng,
                        });
                        // AmapGps = {
                        //     nl: result.position.lat,
                        //     el: result.position.lng,
                        // }
                        resolve({
                            nl: result.position.lat,
                            el: result.position.lng,
                            success: true
                        });
                    } else {
                        console.timeEnd('AmapErrortime');
                        let errorMessage = '高德定位获取失败';
                        if (result && result.message) {
                            errorMessage += `: ${result.message}`;
                        }
                        console.log('=-=位置获取失败-=-=-');
                        resolve({
                            message: errorMessage,
                            success: false
                        });
                    }
                    console.log('=-==结束高德定位-=-=-');
                });
            } catch (error) {
                console.timeEnd('AmapErrortime');
                console.log('=-=高德调用失败-=-=-');
                resolve({
                    message: "高德调用失败",
                    success: false
                });
            }
        });
    });
},





























    
      // 打开摄像头
      getCompetence() {
          this.canvasCamera = document.getElementById('canvasCamera')
          this.contextCamera = this.canvasCamera.getContext('2d')
          // 关掉抗锯齿
          this.contextCamera.mozImageSmoothingEnabled = false;
          this.contextCamera.webkitImageSmoothingEnabled = false;
          this.contextCamera.msImageSmoothingEnabled = false;
          this.contextCamera.imageSmoothingEnabled = false;
          this.videoCamera = document.getElementById('videoCamera')
          var _this = this
          // 旧浏览器可能不支持mediaDevices
          if (navigator.mediaDevices === undefined) {
              navigator.mediaDevices = {}
          }
          // 使用getUserMedia
          // 缺少getUserMedia
          if (navigator.mediaDevices.getUserMedia === undefined) {
              navigator.mediaDevices.getUserMedia = function (constraints) {
                  // 首先获取现存的getUserMedia
                  var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
                  // 有些浏览器不支持
                  // 保持接口一致
                  if (!getUserMedia) {
                      return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
                  }
                  // 使用Promise将调用包装到旧的navigator.getUserMedia
                  return new Promise(function (resolve, reject) {
                      getUserMedia.call(navigator, constraints, resolve, reject)
                  })
              }
          }
          var constraints = {
              audio: false,
              video: {
                width: { min: 2600, ideal: 1280, max: 2600 },
                height: { min: 1170, ideal: 720, max: 1170 },
                  facingMode: { exact: "environment" },
                  transform: 'scaleX(-1)'
              }
          }
          navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
              // 新的浏览器使用srcObject
              if ('srcObject' in _this.videoCamera) {
                  _this.videoCamera.srcObject = stream
              } else {
                  // 避免在新的浏览器中使用它，因为它正在被弃用。
                  _this.videoCamera.src = window.URL.createObjectURL(stream)
              }
              _this.videoCamera.onloadedmetadata = function (e) {
                  _this.videoCamera.play()
              }
          }).catch(err => {
              console.log('没有开启摄像头权限或浏览器版本不兼容',err)
          })
      },
      closeCarama() {
        this.imgSrc = ''
          this.videoCamera.srcObject.getTracks()[0].stop()
      },
      //  绘制图片
      setImage() {
        const ratio = getPixelRatio(this.contextCamera);
        function getPixelRatio(context) {
        const backingStore =
            context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio || 1;
            return (window.devicePixelRatio || 1) / backingStore;
        }
        this.canvasCamera.width = 300 * ratio/2;
        this.canvasCamera.height = 500 * ratio/2;
        this.imgif = true
        // 点击，canvas画图
        this.contextCamera.drawImage(this.videoCamera, 5* ratio, 5* ratio, 260* ratio/2, 460* ratio/2)
        
        var image = this.canvasCamera.toDataURL('image/png',2.0)
        this.imgSrc = image
      },

  }
}
</script>

<style scoped lang="scss">
.position_item{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div{
        flex: 1;
        padding: 0 5px;
        overflow: hidden
    }
}
.borderL{
    border-left: 1px solid rgb(173, 173, 173);
}
.borderR{
    border-right: 1px solid rgb(173, 173, 173);
}
.borderT{
    border-top: 1px solid rgb(173, 173, 173);
}
.borderB{
    border-bottom: 1px solid rgb(173, 173, 173);
}
.flc {
    //width: 100%;
    //height: 100%;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
.video-Box{
    width: 100%;
    height: 100%;
    video{
        width: 100%;
        height: 100%;
    }
    canvas{

    }
}
.bommen{
    width: 100%;
    //position: fixed;
    //bottom: .5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    button{
        width: 110px;
        height: 40px;
        background-color:#3875FE;
        border-radius: 10px;
    }
}

}

// .face {
// width: 400px;
// height: 450px;
// position: relative;
// }

// .face .face-img {
// width: 400px;
// height: 450px;
// position: absolute;
// left: 0px;
// top: 0px;
// }

// .face .face-content {
// width: 310px;
// height: 322px;
// position: absolute;
// top: 64px;
// left: 45px;
// }

// .face .face-content #video {
// object-fit: fill;
// }

// .step {
// text-align: center;
// margin-top: 30px;
// }

.step .step-ul .step-li .step-title {
font-size: 16px;
color: #3875FE;
padding-bottom: 10px;
}

.step .step-ul .step-li .step-title .step-titleimg {
height: 26px;
width: 26px;
margin-left: 20px;
cursor: pointer;
}

.step .step-ul .step-li .step-img {
height: 150px;
width: 150px;
border: 3px solid #eecfa1;
border-radius: 50%;
}

button {
    width: 80px;
    height: 40px;
    background-color:#3875FE;
    border-radius: 10px;
  }
.step .step-ul .step-li .step-img img {
height: 100%;
width: auto;
}
</style>
