import { render, staticRenderFns } from "./addRecommend.vue?vue&type=template&id=cfa5dc18&scoped=true"
import script from "./addRecommend.vue?vue&type=script&lang=js"
export * from "./addRecommend.vue?vue&type=script&lang=js"
import style0 from "./addRecommend.vue?vue&type=style&index=0&id=cfa5dc18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa5dc18",
  null
  
)

export default component.exports