<template>
  <div class="myShops">
    <div class="headTop">
      <Header
        backType="white"
        backEvent="true"
        @backEvent="backPage"
        :back="true"
      >
        <span slot="headText" style="font-size: 0.17rem">我的推荐餐厅</span>
        <span
          @click="queryMyShop(true)"
          slot="rightBtn"
          v-if="!showSubmit && isPfizer && !cityShow && !hospitalShow"
        >
          提交餐厅推荐
        </span>
      </Header>
      <div class="navSelect" v-if="isPfizer">
        <div @click="chooseMyCity">
          {{ myCity }}
          <Svgs color="#000" classes="downIcon" name="iconfontdown"></Svgs>
        </div>
        <div class="overflow" @click="chooseMyHospital">
          {{ myHospital }}
          <Svgs color="#000" classes="downIcon" name="iconfontdown"></Svgs>
        </div>
      </div>
    </div>
    <ScrollBar
      class="opOrderListScroll"
      v-if="!cityShow && !hospitalShow"
      :style="listStyle"
      @onTop="refresh"
      :loadType="loadType"
    >
      <div
        slot="scrollBar"
        :style="{ paddingBottom: showSubmit ? '1rem' : '0' }"
      >
        <CheckboxGroup v-model="recommendIds" :disabled="!showSubmit">
          <div
            v-for="(item, index) in eventList"
            :id="'opOrderList' + index"
            :key="index"
          >
            <div
              class="opOrderList"
              v-if="!showSubmit || (showSubmit && item.shopStatus == 0)"
            >
              <Checkbox
                :icon-size="showSubmit ? '15PX' : '0'"
                :name="item.recommendId"
              >
                <p class="opOrderListHead overflow">{{ item.name }}</p>
                <p class="opOrderListNum">
                  <Svgs color="#333" name="icondianhua-"></Svgs>
                  {{ item.tel }}
                </p>
                <div class="opOrderListNum">
                  <Svgs color="#333" name="iconaddress"></Svgs>
                  {{ item.address }}
                </div>
                <EventTag
                  :pfizer="true"
                  :status="item.shopStatusTxt | statusFilter"
                  :statusTxt="item.shopStatusTxt"
                ></EventTag>
                <div class="failResaonBox">
                  <span
                    v-if="item.shopStatusTxt == '商户拓展失败'"
                    style="font-weight: 500; color: #13161a"
                    >失败原因：</span
                  >
                  <span
                    v-if="
                      item.shopStatusTxt != '商户拓展失败' && item.failResaon
                    "
                    style="font-weight: 500; color: #13161a"
                    >说明：</span
                  >
                  {{ item.failResaon }}
                </div>
              </Checkbox>
            </div>
          </div>
        </CheckboxGroup>
        <div
          class="noData"
          v-if="!eventList.length && loadType == '' && !isFirst"
        >
          <Svgs color="#C0C4CC" classes="noDataSvg" name="iconwushuju"></Svgs>
          您还未添加任何餐厅 <br />
          请点击右下角的“添加”按钮去添加吧
        </div>
      </div>
    </ScrollBar>
    <div class="opOrderListAddC" v-if="!showSubmit">
      <Svgs
        @click.native="addShop"
        :color="colors[tenant_code]"
        classes="opOrderListAdd"
        name="icontianjia2"
      ></Svgs>
    </div>
    <div class="opCreateBtnRow" id="submitBtn" v-if="showSubmit">
      <Btn btnClass="opCreateBtn" @callBack="submitRecommend">
        <span slot="btnContent">提交</span>
      </Btn>
    </div>
    <div class="chooseCity" v-if="cityShow">
      <p>选择城市</p>
      <ul>
        <li @click="checkAllCity">全部</li>
        <li
          :class="ActiveCity == index ? 'active' : ''"
          v-for="(item, index) in citys"
          :key="index"
          @click="selectCity(index, item)"
        >
          {{ item.txt }}
        </li>
        <div style="clear: both"></div>
      </ul>
    </div>
    <div class="chooseHospital" v-if="hospitalShow">
      <p>选择医院</p>
      <ul>
        <li @click="checkAllHospital">全部</li>
        <li
          v-for="(item, index) in hospitals"
          :key="index"
          @click="selectHospital(index, item)"
        >
          {{ item.name }}
          <Svgs
            v-if="ActiveHospital == index"
            style="float: right"
            color="#4AC672"
            classes="selectedIcon"
            name="iconsuccess1"
          ></Svgs>
        </li>
      </ul>
    </div>
    <SearchCity
      :cityList="cityList"
      @onOk="cityOk"
      ref="eventCity"
    ></SearchCity>
    <MessageBox
      ref="successMsg"
      type="tips"
      :htmlMessage="true"
      messageTitle="餐厅上线基本规则"
      btnType="single"
      singleTxt="已知晓"
      @onSingle="successMsgBack"
    >
      <div slot="htmlSlot">
        <div v-html="wikiContent"></div>
      </div>
    </MessageBox>
    <MessageBox
      ref="successMsg2"
      type="tips"
      :htmlMessage="true"
      messageTitle="提示"
      btnType="single"
      singleTxt="确定"
    >
      <div slot="htmlSlot">
        <p>您推荐的餐厅已提交成功</p>
        <p>我们将会对您推荐的餐厅进行线上审核：</p>
        <div v-html="wikiContent"></div>
      </div>
    </MessageBox>
  </div>
</template>

<script>
import { SmartStorage } from "smart-core-util";
import EventTag from "../../components/EventTag/eventTag2";
import { miceService } from "@/service/miceService.js";
import { Colors } from "@/utils/colors.js";
import { mapMutations } from "vuex";
import { getCookie } from "tiny-cookie";
import { Checkbox, CheckboxGroup } from "vant";
import { indicator } from "smart-indicator";

export default {
  components: {
    EventTag,
    CheckboxGroup,
    Checkbox,
  },
  data() {
    return {
      isPfizer: false,
      loadType: "",
      isFirst: true,
      pageIndex: 1,
      pageSize: 10,
      listStyle: {},
      eventList: [],
      myCity: "全部",
      cityShow: false,
      cityId: "",
      hospitalId: "",
      citys: [],
      ActiveCity: "-1",
      myHospital: "全部",
      hospitalShow: false,
      hospitals: [],
      ActiveHospital: "-1",
      colors: {},
      tenant_code: "crossevent",
      scrollBottomF: true,
      wikiContent: null,
      cityList: [],
      recommendIds: [],
      showSubmit: false,
    };
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case "商户拓展中":
          return "0";
        case "商户拓展成功":
        case "审核通过":
          return "10";
        case "商户拓展失败":
          return "-100";
        case "审核中":
          return "30";
      }
    },
  },
  async mounted() {
    if (this.$route.query.searchCity) {
      this.$refs.eventCity.openPop();
    }
    let params = {
      type: 2,
    };
    this.queryOnlineRules();
    miceService.queryRegion(params).then((res) => {
      this.cityList = res.content || res;
    });
    this.isPfizer = ["pfizer"].includes(SmartStorage.get("tenant_code"));
    this.colors = Colors;
    this.tenant_code = SmartStorage.get("tenant_code");
    this.queryMyShop();
  },
  methods: {
    ...mapMutations(["ADDRESS"]),
    submitRecommend() {
      let params = {
        recommendIds: this.recommendIds,
      };
      miceService.SubmitMyShops(params).then((res) => {
        if (res.success) {
          this.showSubmit = false;
          this.recommendIds = [];
          this.queryMyShop();
          this.$refs["successMsg2"].openPop();
        }
      });
    },
    cityOk(item) {
      let address = {
        longitude: item.longitude,
        latitude: item.latitude,
        name: item.txt,
        city: item.txt,
      };
      this["ADDRESS"](address);
      SmartStorage.set("regionId", item.regionId);
      SmartStorage.set("cityId", item.pingRegionId);
      SmartStorage.set("itemType", 5);
      if (SmartStorage.get("tenant_code") == "roche" || SmartStorage.get("tenant_code") == "crossevent") {
        this.$router.push({ path: "/gaoDeShopList" });
        return;
      }
      this.$router.push({ path: "/chooseHospital" });
    },
    // 后退
    backPage() {
      if (this.cityShow || this.hospitalShow) {
        this.cityShow = false;
        this.hospitalShow = false;
        return;
      }
      if (this.showSubmit) {
        if (this.recommendIds == []) {
          return;
        }
        this.showSubmit = false;
        this.recommendIds = [];
        return;
      }
      if (["pfizer","crossevent","roche"].includes(SmartStorage.get("tenant_code"))) {
        this.$router.push({ path: "/recommend" });
        return;
      }
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite");
      } else {
        this.iJsBridge.close();
      }
    },
    refresh() {
      this.loadType = "top";
      this.queryMyShop();
    },
    // 查询我的餐厅
    queryMyShop(change) {
      let params = {
        channel: "",
        cityId: this.cityId, //所选城市id
        hospitalId: this.hospitalId, //所选医院id
        itemType: "5",
      };
      miceService.QueryMyShops(params).then((res) => {
        this.isFirst = false;
        if (res.success) {
          this.eventList = res.content.data.customed;
          this.eventList.push();
          this.scrollBottomF = true;
          if (change) {
            this.showSubmit = true;
          }
        }
        this.loadType = "";
      });
    },
    // 向上滚动
    scrollTop(filter) {
      this.loadType = "top";
      this.pageIndex = 1;
      if (filter) {
        this.eventList = [];
      }
      this.queryMyShop();
    },
    // 向下滚动
    scrollBottom() {
      if (!this.scrollBottomF) {
        return;
      }
      this.scrollBottomF = false;
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = "none";
      } else {
        this.loadType = "bottom";
        this.pageIndex += 1;
        this.queryMyShop();
      }
    },
    // 高度调整
    setListStyle() {
      let head = document.getElementsByClassName("headTop")[0].offsetHeight * 4;
      let _this = this;
      setTimeout(function () {
        _this.setListStyle();
      }, 100);
      let hei = window.innerHeight - head;
      this.listStyle = {
        height: hei + "px",
      };
    },
    // 添加餐厅
    addShop() {
      if (SmartStorage.get("tenant_code") == "pfizer") {
        this.$refs["successMsg"].openPop();
        // this.queryOnlineRules()
        return;
      }
      if (SmartStorage.get("tenant_code") == "roche" || SmartStorage.get("tenant_code") == "crossevent") {
        this.$refs.eventCity.openPop();
        return;
      }
      this.$router.push({ path: "/gaoDeShopList" });
    },
    async queryOnlineRules() {
      let params = {
        collection: "cfg-imeal-wiki",
        filter: {
          entryCode: "onlineRules",
          tenantCode: SmartStorage.get("tenant_code"),
        },
      };
      if (SmartStorage.get("gray") || getCookie("gray")) {
        params.filter.tenantCode = SmartStorage.get("tenant_code") + "-gray";
      }
      miceService.getsettings(params).then((res) => {
        if (res.success) {
          this.wikiContent = res.content.content;
        }
      });
    },
    successMsgBack() {
      // this.$refs.successMsg.cancel();
      this.$refs.eventCity.openPop();
    },
    // 选择城市
    selectCity(index, item) {
      this.ActiveCity = index;
      if (this.ActiveCity == index) {
        setTimeout(() => {
          this.myCity = item.txt;
          this.cityId = item.pingRegionId;
          this.cityShow = false;
          this.queryMyShop();
        }, 200);
      }
    },
    // 选择我的城市
    chooseMyCity() {
      this.cityShow = true;
      this.hospitalShow = false;
      let params = {
        itemType: 5,
      };
      miceService.QueryMyCity(params).then((res) => {
        if (res.success) {
          this.citys = res.content.data;
        }
      });
    },
    // 选择医院
    selectHospital(index, item) {
      this.ActiveHospital = index;
      if (this.ActiveHospital == index) {
        setTimeout(() => {
          this.myHospital = item.name;
          this.hospitalId = item.rowId;
          this.hospitalShow = false;
          this.queryMyShop();
        }, 200);
      }
    },
    // 选择我的医院
    chooseMyHospital() {
      this.cityShow = false;
      this.hospitalShow = true;
      let params = {
        cityId: this.cityId,
        itemType: 5,
      };
      miceService.QueryMyHospitals(params).then((res) => {
        if (res.success) {
          this.hospitals = res.content.data;
        }
      });
    },
    // 查询所有城市
    checkAllCity() {
      this.myCity = "全部";
      this.cityId = "";
      this.queryMyShop();
      this.cityShow = false;
    },
    // 查询所有医院
    checkAllHospital() {
      this.myHospital = "全部";
      this.hospitalId = "";
      this.queryMyShop();
      this.hospitalShow = false;
    },
  },
};
</script>

<style lang="scss">
.publicHead {
  margin-bottom: 0.2rem !important;
}
.headTop {
  padding-bottom: 0.05rem;
  background: #fff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 25px 55px 0px rgba(8, 37, 105, 0.05);
  position: fixed;
  width: 100%;
  top: 0;
}

.opOrderListScroll {
  top: 1rem !important;
  height: calc(100% - 1rem) !important;
  bottom: 0;
  overflow: scroll;
  position: absolute;
}
.navSelect {
  height: 0.3rem;
  background: #fff;
  display: flex;
  margin-left: 0.25rem;
}
.overflow {
  width: 55%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.navSelect > div {
  line-height: 0.3rem;
  margin-right: 0.2rem;
}
.downIcon {
  width: 0.1rem !important;
  height: 0.1rem !important;
}
.noData {
  margin-top: 0.8rem;
  color: #909398;
  font-size: 0.14rem;
}
.noDataSvg {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0 auto;
  display: block;
}
.shopList ul li {
  width: 80%;
  height: auto;
  background: #eee;
  margin: 0 auto;
  border-radius: 6px;
  padding: 0.1rem;
  box-shadow: 0px 0px 12px 0px rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 0.25rem;
}
.shopList ul li .status {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.1rem;
}
.shopList ul li .status > span:nth-child(1) {
  float: left;
}
.shopList ul li .status > span:nth-child(2) {
  float: right;
}
.shopList ul li .panel {
  display: flex;
  padding: 0.1rem 0 0 0;
}
.shopList ul li .panel > .imgBox {
  width: 80px;
  height: 80px;
  background: red;
}
.shopList ul li .panel > div:nth-child(2) {
  width: 70%;
  margin-left: 0.15rem;
}
.shopList ul li .panel > div:nth-child(2) > p {
  text-align: left;
  margin-bottom: 0.1rem;
}
.shopList ul li .panel > div:nth-child(2) > p:nth-child(2) {
  color: #0096ff;
}

.opOrderListAddC {
  background: #fff;
  width: 0.2rem !important;
  height: 0.2rem !important;
  position: fixed;
  right: 0.4rem;
  bottom: 1rem;
  box-shadow: 0.15rem 0.15rem 0.15rem #dedede;
}

.opOrderListAdd {
  width: 0.5rem !important;
  height: 0.5rem !important;
  position: absolute;
  left: -0.15rem;
  top: -0.15rem;
}

.opOrderList {
  padding: 0.1rem 0.1rem;
  text-align: left;
  background: linear-gradient(to left, #fff, #f8f8f8);
  margin: 0 0.15rem 0.15rem 0.15rem;
  border-radius: 0.1rem;
  box-shadow: 5px 5px 5px #dedede;
  position: relative;
}
.opOrderList .icon {
  vertical-align: -0.03rem;
}
.opOrderListHead {
  font-size: 0.15rem;
  color: #13161a;
  text-align: left;
  font-weight: 500;
  width: 2.4rem;
}

.opOrderListNum {
  //   color: #90939A;
  color: #333;
  font-size: 0.12rem;
  text-align: left;
  svg {
    width: 0.15rem !important;
    height: 0.15rem !important;
  }
}

.opOrderListAddress {
  color: #90939a;
  margin-top: 0.1rem;
  padding-left: 0.25rem;
  font-size: 0.12rem;
}

.opOrderListSvg {
  float: left;
  margin-right: 0.5rem;
  position: relative;
}

.opOrderListSvg .icon,
.opOrderListDate .icon {
  width: 0.15rem;
  height: 0.15rem;
  position: absolute;
  left: -0.25rem;
  top: -0rem;
}

.chooseCity {
  margin-top: 0.82rem;
}
.chooseCity > p {
  text-align: left;
  margin-top: 0.3rem;
  margin-left: 0.25rem;
  font-size: 0.16rem;
}
.chooseCity ul {
  padding: 0.2rem 0.25rem;
}
.chooseCity ul li {
  width: 1rem;
  height: 0.36rem;
  color: #000;
  background: #f5f5f5;
  text-align: center;
  line-height: 0.36rem;
  border-radius: 5px;
  float: left;
  margin-right: 0.083rem;
  margin-bottom: 0.1rem;
}
.chooseCity ul .active {
  color: #fff;
  // background: #0099FF;
  background: var(--themeColor);
  box-shadow: 0px 5px 25px 0px rgba(26, 109, 202, 0.4);
}
.chooseHospital {
  margin-top: 0.82rem;
}
.chooseHospital > p {
  text-align: left;
  margin-top: 0.1rem;
  margin-left: 0.25rem;
  font-size: 0.16rem;
}
.chooseHospital ul li {
  padding: 0.2rem 0;
  margin: 0 0.25rem;
  border-bottom: 1px solid #f5f5f5;
  text-align: left;
  font-size: 0.14rem;
}
.failResaonBox {
  font-size: 0.14rem;
  text-align: left;
  background: #f8f8f8;
  margin-top: 0.1rem;
}
.opCreateBtnRow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0.2rem 0 0.3rem 0;
  box-shadow: 1px -0.15rem 0.15rem #fff;
}
.opCreateBtn {
  width: 88%;
  height: 0.45rem;
  line-height: 0.45rem;
  border-radius: 0.3rem;
  margin: 0 auto;
  font-size: 0.15rem;
}
</style>
