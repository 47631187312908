<template>
    <Popup v-model="isShow"  get-container="body">
            <div class="messageContent" :class="messageName" :style="messageStyle">
                <div class="messageTitle">
                    {{messageTitle||'提示'}}
                </div>
                <div v-if="showCloseBtn" class="closebtn" @click.stop="closeMessage()"><Svgs color="#13161B" name="iconguanbi"></Svgs></div>
                <div v-if="messageTxt" :style="txtColor?`color:${txtColor}`:''" class="messageTxt">
                    <Svgs name="iconsuccess1" color="#3bcabe" v-if="type=='success'" classes="messageBoxSvg"></Svgs>

                    <Svgs name="iconshanchu1" color="red" v-if="type=='error'" classes="messageBoxSvg"></Svgs>

                    <Svgs name="iconwarn" color="#2D82F0" v-if="type=='tips' || type == 'textarea'" classes="messageBoxSvg"></Svgs>

                    <Svgs name="iconjinggao" color="#ffb54c" v-if="type=='warning'" classes="messageBoxSvg"></Svgs>

                    <Svgs :name="customIcon" :color="customColor" v-if="type=='custom'" classes="messageBoxSvg"></Svgs>
                    <div style="text-align: left;" v-html="messageTxt">
                    </div>
                </div>
                <div v-if="type == 'textarea'">
                    <MInput class="MInput" type="textarea" :pla="pal" v-model="val" />
                </div>
                <div v-if="!messageTxt && !htmlMessage" class="messageTxt">
                    <slot name="messageSlot"></slot>
                </div>
                <div v-if="htmlMessage" class="htmlMsg">
                    <slot name="htmlSlot"></slot>
                </div>

                <div class="messageBtnRow" v-if="!btnType||btnType=='double'">
                    <Btn btnClass="messageCancel" @callBack="cancel">
                        <template v-slot:btnContent>
                            {{cancelTxt||'取消'}}
                        </template>
                    </Btn>
                    <Btn btnClass="messageOk" v-if="backFun" @callBack="backOk">
                        <template v-slot:btnContent>
                            <span class="line"></span>
                            {{okTxt||'确定'}}
                        </template>
                    </Btn>
                    <Btn btnClass="messageOk" v-else @callBack="ok">
                        <template v-slot:btnContent>
                            <span class="line"></span>
                            {{okTxt||'确定'}}
                        </template>
                    </Btn>
                </div>
                <div class="messageBtnRow" v-if="btnType=='single'">
                    <Btn :btnClass="singleClass||''+' messageSingle'" @callBack="single">
                        <template v-slot:btnContent>
                            {{singleTxt}}
                        </template>
                    </Btn>
                </div>
                <div v-if="btnType=='slot'">
                    <slot name="btnSlot"></slot>
                </div>
            </div>
    </Popup>
</template>

<script>
import { Popup } from 'vant';
    export default {
        components:{Popup},
        name: "MessageBox",
        props: [
            'htmlMessage',
            'messageTitle',//提示标题
            'messageTxt',//提示内容
            'type',//提示类型  1.success(成功) 2.error(错误) 3.tips(提醒) 4.warning(警告) 5.custom(自定义图标)
            'okTxt',//确认按钮文字
            'cancelTxt',//取消按钮文字
            'btnType', //按钮类型 double(取消，确认两个按钮) single(单个确认按钮)
            'singleTxt', //单个按钮文字
            'singleClass',//单个按钮class
            'customIcon',//自定义图标
            'customColor',//自定义图标颜色
            'txtColor',//提示内容颜色
            'textarea',//带textarea输入框
            'pal',//textarea输入框的placeholder
            'backFun',//点击确定后通过回调关闭提示框
            'showCloseBtn' //显示取消按钮
        ],
        data() {
            return {
                alertContent: '',
                messageStyle: {},
                messageName: '',
                val: '',
                isShow: false
            }
        },
        created() {
            this.messageName = 'messageContent' + new Date().getTime();
        },
        methods: {
            cancel() {
                this.openPop();
                this.$emit('onCancel', {})
            },
            closeMessage(){
                this.openPop();
            },
            ok() {
                this.openPop();
                this.$emit('onOk', {})
            },
            backOk(){
                this.$emit('onOk', {value:this.val,})
            },
            single() {
                this.openPop();
                this.$emit('onSingle', {})
            },
            openPop() {
                this.isShow = !this.isShow
                // let _this = this;
                // let inter = setInterval(function () {
                //     let dom = document.getElementsByClassName(_this.messageName)[0];
                //     if (dom && dom.clientHeight) {
                //         clearInterval(inter);
                //         _this.messageStyle = {
                //             marginBottom: (window.innerHeight - dom.clientHeight) / 2 + 'px'
                //         }
                //     }
                // }, 10)
            },
        },
    }
</script>

<style lang="scss">
    

    .messageContent {
        background: #fff;
        color: #333;
        font-size: .1rem;
        border-radius: 5px;
        width: 80vw;
        margin: 0 auto;
        overflow: hidden;
        .MInput{
            border: none!important
        }
    }
    .messageTxt{
        font-size: .15rem;
        padding: .3rem .1rem .3rem .7rem;
        position: relative;
        text-align: left;
    }
    .htmlMsg{
        font-size: .15rem;
        position: relative;
        padding: .3rem!important;
        p{
            text-align: left!important;
        }

    }
    .messageBoxSvg{
        width: .3rem!important;
        height: .3rem!important;
        position: absolute;
        left: .2rem;
        top: .255rem;
    }
    .messageTitle{
        text-align: left;
        font-size: .2rem;
        font-weight: 600;
        padding: .125rem .25rem .125rem .25rem;
        border-bottom: 1px solid #E6E7E9;
    }

    .messageBtnRow {
        height: .5rem;
        line-height: .5rem;
        font-size: .15rem;
        border-top: 1px solid #E6E7E9;
    }

    .messageCancel, .messageOk {
        float: left;
        width: 50%;
        text-align: center;
        background: #fff !important;
    }

    .messageCancel {
        color: #666666!important;
    }

    .messageSingle {
        width: 100%;
        text-align: center;
        color:  var(--themeColor)!important;
        background: #fff !important;
    }

    .messageOk {
        color:  var(--themeColor)!important;
        position: relative;
    }

    .line {
        position: absolute;
        height: .25rem;
        border-left: 1px solid #E6E7E9;
        top: .13rem;
        left: 0;
    }
    .closebtn{
        float: right;
    position: absolute;
   right: 0rem;
    top: 0.02rem;
    padding: 0.12rem;
    }
</style>